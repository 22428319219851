import React, { useState, useEffect } from 'react';
import { api, axios } from 'helper/api/client';
import RouteHeader from 'components/partial/RouteHeader';
import Table from 'components/table/Table';
import Button from 'components/ui/Button';
import Form from 'components/form/Form';
import { getFilter, getFilterNoResultsString, setHistoryState } from 'helper/filter';
import Box from 'components/ui/Box';
import format from 'helper/format';
import EbillingSignupForm from './EbillingSignupForm';
import FaqsBox from 'components/partial/FaqsBox';
import moment from 'moment';
import NotificationMessage from 'components/ui/NotificationMessage';
import { Link } from 'react-router-dom';

const Ebilling = (props) => {
    const types = { invoice: 'Invoice', creditNote: 'Credit note', statement: 'Statement' };
    const sorts = { dateDesc: 'Newest to oldest', date: 'Oldest to newest', amountDesc: 'Amount' };
    const [ebills, setEbills] = useState([]);
    const [total, setTotal] = useState(null);
    const [downloadError, setDownloadError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState(getFilter({ sortBy: 'dateDesc', page: 1, from: '', to: '', documentType: '' }));
    const formFields = {
        from: { label: 'Date from', type: 'Date', value: filter.from, placeholder: moment().subtract(1, 'month').format('DD/MM/YYYY') },
        to: { label: 'Date to', type: 'Date', value: filter.to, placeholder: moment().format('DD/MM/YYYY') },
        documentType: { label: 'Type', type: 'Dropdown', options: types, value: filter.documentType },
        sortBy: { label: 'Sort', type: 'Dropdown', options: sorts, showAll: false, value: filter.sortBy }
    };
    let newWindow;
    useEffect(() => {
        let source = axios.CancelToken.source();
        setLoading(true);
        api.get('ebilling/list', filter, {
            cancelToken: source.token,
        }).then(response => {
            api.setPerformanceMetrics(response);
            setEbills(response.data.response.items.filter(ebill => {
                if (filter.documentType) {
                    return ebill.type === filter.documentType;
                }
                return true;
            }));
            setTotal(response.data.response.total);
            setLoading(false);
            api.setCache(response);
        }).catch(error => {
            api.error(error);
        });
        return () => source.cancel();
    }, [filter]);

    const getDocs = (page) => {
        setFilter({ ...filter, page: page });
    }
    const filterHandler = (form) => {
        const { fieldValues, enableForm } = form;
        const params = { ...fieldValues, page: 1 };
        setHistoryState(params);
        setFilter(params);
        enableForm();
    };
    const getDoc = (event) => {
        setDownloadError(false);
        newWindow = window.open('/loading.html', 'ebill' + event.currentTarget.dataset.id);
    }
    const getDocSuccess = (response, apiData) => {
        api.setCache(response);
        setEbills(ebills.map(ebill => {
            if (ebill.id === apiData.id) {
                ebill.viewed = true;
            }
            return ebill;
        }));
        newWindow.location = response.data.response.document;
    }
    const getDocFailure = (response, apiData) => {
        setDownloadError(true);
        newWindow.close();
    }
    const theadConfig = {
        date: { className: 'date', content: 'Date' },
        type: { className: 'label-left', content: 'Type' },
        reference: { className: 'label-left', content: 'Reference' },
        total: { className: 'amount', content: 'Total' },
        button: { className: 'button', content: '' },
    };
    return (
        <>
            <RouteHeader route={ props.route }/>
            <EbillingSignupForm />
            <Box padding="large">
                <h3>Statements</h3>
                { downloadError && <NotificationMessage type="warning">There was an error downloading your document, please try again</NotificationMessage> }
                <Form formFields={ formFields }
                      eventTitle="Ebilling filter"
                      filter={ true }
                      loading={ loading }
                      valid={ filterHandler }
                      showSubmit={ false }
                      submitName="Filter"
                />

                <p>All invoices and statements generated from 17th January 2022 onwards are now available below for you to download for your records. If you require a copy of your invoices and statements from before this, please <Link to="/form/historical-invoices">Contact us</Link>.</p>

                { (loading || ebills.length > 0) &&
                <Table
                    extraClass="table--ebills"
                    boxSize="large"
                    head={ theadConfig }
                    body={ ebills.map((ebill) => {
                        return {
                            date: ebill.date,
                            type: types[ebill.type],
                            reference: ebill.reference,
                            total: format.currency(ebill.total),
                            button: <Button onClick={ getDoc } fullClassName={ 'table-button' + (ebill.viewed ? ' table-button--viewed' : '') } apiAction={ 'ebilling/item/' + ebill.id } apiSuccess={ getDocSuccess } apiFailure={ getDocFailure } apiData={ { id: ebill.id } } data-id={ ebill.id }>View</Button>
                        }
                    }) }
                    page={ filter.page }
                    paginationAction={ getDocs }
                    numberPerPage={ 12 }
                    total={ total }
                    loading={ loading }
                /> }
                { !loading && ebills.length === 0 && <p>There are no invoices & statements { getFilterNoResultsString(filter) }</p> }
            </Box>
            { !loading && <FaqsBox settingName="ebilling" defaultFaqs={ [76999, 77000, 117797] }/> }
        </>
    )
}

export default Ebilling;