import React, { useState, useEffect, useContext, useRef } from 'react';
import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';
import content from 'helper/content';
import 'scss/component/contact-us/contact-us.scss';
import Button from 'components/ui/Button';
import { api, axios } from 'helper/api/client';
import MockData from 'components/ui/MockData';
import Icon from 'components/ui/Icon';
import { getHtml } from 'helper/html';
import permission from 'helper/permission';
import url from 'helper/url';
import UserContext from 'context/user';
import setting from 'helper/setting';
import { Link, useHistory, useLocation } from 'react-router-dom';
import 'url-search-params-polyfill';

const ContactUs = (props) => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const baseUrl = '/help-support/contact';
    const { user } = useContext(UserContext);
    const formHashTarget = useRef(null);
    const topHashTarget = useRef(null);
    const phoneNumber = setting.get('phoneNumber', '0800 574 574');
    const sector = user.sector.charAt(0).toUpperCase() + user.sector.slice(1);
    const serviceType = (permission.check('isOnCall') ? 'OnCall' : 'TopUp');
    const step = (params.get('step1') ? (params.get('step2') ? 3 : 2) : 1);
    const [form, setForm] = useState(null);
    const [options, setOptions] = useState(null);
    const selectedStep1 = (params.get('step1') ? params.get('step1') : null);
    const selectedStep2 = (params.get('step2') ? params.get('step2') : null);
    const getLinks = (links) => {
        if (links !== null) {
            return links.filter(link => {
                if (link.url && link.url.substr(0, 1) !== 'h') {
                    return permission.validRoute(link.url);
                }
                return true;
            });
        }
        return null;
    }

    useEffect(() => {
        if (topHashTarget.current) {
            topHashTarget.current.scrollIntoView();
        }
    }, []);
    useEffect(() => {
        const isAllowed = (step) => {
            const permissions = step.permissions;
            const links = getLinks(step.links);
            let allowed = false;
            if (permission.check('isCylinder') && permissions['cylinder' + sector]) {
                allowed = true;
            } else if (permission.check('isMetered') && permissions['metered']) {
                allowed = true;
            } else if (permission.check('isBulk') && permissions['bulk' + sector] && permissions['bulk' + serviceType]) {
                allowed = true;
            }
            if (allowed && !step.phoneNumber && step.links) {
                allowed = links && links.length;
            }
            return allowed;
        }
        const getAllowedOptions = (options) => {
            Object.entries(options).forEach(([optionKey, option]) => {
                options[optionKey].steps = option.steps.filter(step => isAllowed(step));
                if (!option.steps.length) {
                    delete options[optionKey];
                }
            });
            return options;
        }
        let source = axios.CancelToken.source();
        api.get('contact-us/options', {}, {
            cancelToken: source.token,
        }).then(response => {
            setOptions(getAllowedOptions(response.data.response.items));
            api.setCache(response);
        }).catch(error => {
            api.error(error);
        });
        // Will cancel AJAX on unmount
        return () => source.cancel();
        // eslint-disable-next-line
    }, [user.type, sector, serviceType]);
    useEffect(() => {
        if (form) {
            formHashTarget.current.scrollIntoView();
        }
    }, [form]);

    const getForm = () => {
        const Component = require('components/partial/form/' + form).default;
        return <Component />
    }
    const backAStep = (event) => {
        if (step === 2) {
            history.push(baseUrl);
        }
        if (step === 3) {
            history.push(baseUrl + '?step1=' + selectedStep1);
        }
    }
    const doStep1 = (event) => {
        const hasOneStep2 = Object.entries(options[event.currentTarget.dataset.step].steps).length === 1;
        history.push(baseUrl + '?step1=' + event.currentTarget.dataset.step + (hasOneStep2 ? '&step2=0' : ''));
    }
    const doStep2 = (event) => {
        history.push(baseUrl + '?step1=' + selectedStep1 + '&step2=' + event.currentTarget.dataset.step);
    }
    const loadForm = (event) => {
        setForm(event.currentTarget.dataset.form);
    }
    const getContent = (step) => {
        const links = getLinks(step.links);
        return (
            <>
                { step.content.length > 0 && <div className="contact-steps__step-content-text">{ getHtml(step.content) }</div> }
                { links && links.length > 0 &&
                <div className="contact-steps__step-content-links">
                    { links.map((link, key) => {
                        if (link.url) {
                            if (url.isFull(link.url)) {
                                return <a className="contact-steps__step-content-links-link" key={ key } href={ link.url } target="portalExternal">{ link.title }</a>
                            } else {
                                return <Link className="contact-steps__step-content-links-link" key={ key } to={ link.url }>{ link.title }</Link>
                            }
                        } else if (link.form) {
                            return <button className="contact-steps__step-content-links-link" key={ key } data-form={ link.form } onClick={ loadForm }>{ (link.title ? link.title : 'Please fill out the form below') }</button>

                        }
                        return null;
                    }) }
                </div>
                }
                { step.phoneNumber &&
                <div className="contact-steps__step-content-call">
                    { content.text('contact.preCallUs', 'Please call us for assistance') } <a href={'tel:' + phoneNumber.replace(' ', '') }>{ phoneNumber }</a>
                </div>
                }
            </>
        );
    }
    const StepItem = (props) => {
        return (
            <li className={ 'contact-steps__step-list-item' + (props.selected ? ' contact-steps__step-list-item--selected' : '') }>
                <button className="contact-steps__step-list-item-button" onClick={ props.action } data-step={ props.reference }>
                    { props.icon && <Icon icon={ props.icon } /> }
                    <span className="contact-steps__step-list-item-button-inner">{ props.title }</span>
                </button>
            </li>
        );
    }
    const getStep1 = () => {
        return (
            <ul className="contact-steps__step-list">
                { options === null && [...Array(10)].map((x, i) => {
                    return <li key={ i }><MockData { ...MockDataProps } /></li>
                }) }
                { options !== null && Object.entries(options).map(([stepReference, step]) => {
                    return <StepItem key={ stepReference } reference={ stepReference } selected={ selectedStep1 === stepReference } action={ doStep1 } title={ step.title } icon={ step.icon } />
                }) }
            </ul>
        );
    }
    const getStep2 = () => {
        if (step > 1 && selectedStep1 !== null && options && Object.entries(options[selectedStep1].steps).length) {
            return (
                <ul className="contact-steps__step-list">
                    { Object.entries(options[selectedStep1].steps).map(([stepReference, step]) => {
                        return <StepItem key={ stepReference } reference={ stepReference } selected={ selectedStep2 === stepReference } action={ doStep2 } title={ step.title } />
                    }) }
                </ul>
            );
        }
        return null;
    }
    const getStep3 = () => {
        if (step > 2 && selectedStep2 !== null && options && typeof options[selectedStep1].steps[selectedStep2] !== 'undefined') {
            const step = options[selectedStep1].steps[selectedStep2];
            return <div className="contact-steps__step-content">{ getContent(step) }</div>
        }
        return null;
    }
    const MockDataProps = { randomise: true, minWidth: 60 }
    return (
        <>
            <RouteHeader route={ props.route }/>
            <Box padding="large">
                { content.html('contact.emergencies', '<h3>For emergencies call <a href="tel:03457 200 100">03457 200 100</a></h3><p>If you suspect a gas leak, damage to pipework or installation or any other gas emergency contact us immediately 24/7.</p>') }
            </Box>
            <h2 className="contact-heading" ref={ topHashTarget }>How can we help you today?</h2>
            <div className="contact-step-titles">
                <div className="contact-step-titles__title contact-step-titles__title--active">Step 1</div>
                <div className={ 'contact-step-titles__title' + (step > 1 ? ' contact-step-titles__title--active' : '') }>Step 2</div>
                <div className={ 'contact-step-titles__title' + (step > 2 ? ' contact-step-titles__title--active' : '') }>Step 3</div>
            </div>
            <div className="contact-steps">
                <div className={ 'contact-steps__step' + (step === 1 ? ' contact-steps__step--active' : '') }>
                    <p className="contact-steps__step-sub-title">{ content.text('contact.step1', 'What would you like to know more about?') }</p>
                    { getStep1() }
                </div>
                <div className={ 'contact-steps__step' + (step === 2 ? ' contact-steps__step--active' : '') + (step < 2 ? ' contact-steps__step--inactive' : '') }>
                    <p className="contact-steps__step-sub-title">{ content.text('contact.step2', 'Select from the options') }</p>
                    { getStep2() }
                </div>
                <div className={ 'contact-steps__step' + (step === 3 ? ' contact-steps__step--active' : '') + (step < 3 ? ' contact-steps__step--inactive' : '') }>
                    <p className="contact-steps__step-sub-title">{ content.text('contact.step3', 'Ways to get in touch') }</p>
                    { getStep3() }
                </div>
                { step > 1 && <Button onClick={ backAStep } simple={ true }>Back a step</Button> }
            </div>
            <div className="contact-form" ref={ formHashTarget }>
                { form && getForm() }
            </div>
        </>
    );
};

export default ContactUs;