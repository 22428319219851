import AccountName from 'components/partial/my-account/AccountName';
import ContactDetails from 'components/partial/my-account/ContactDetails';
import MarketingPreferences from 'components/partial/my-account/MarketingPreferences';
import EbillingSignup from 'components/partial/payments/EbillingSignupForm';
import RouteHeader from 'components/partial/RouteHeader';

const AccountDetails = (props) => {
    return (
        <>
            <RouteHeader route={ props.route }/>
            <AccountName />
            <ContactDetails />
            <MarketingPreferences />
            <EbillingSignup />
        </>
    );
};

export default AccountDetails;