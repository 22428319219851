import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';

import content from 'helper/content';
import OverviewPanelFooter from '../structure/OverviewPanelFooter';
import Button from 'components/ui/Button';
import setting from 'helper/setting';


const modifierClass = 'finish-dated';

const OverviewPendingGaugeReading = ({ data }) => {
    const title = content.text('overview.pendingGaugeTitle', 'We\'re updating your account');
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle className={ modifierClass } title={ title } />
                <OverviewPanelBody className={ modifierClass }>
                    { content.html('overview.pendingGaugeText', '<p>Thanks for your tank reading, we’re currently updating your account. Please check back later..</p><p>If your reading is below {bulkWarningThreshold}%, we advise you to place your order now.</p>', { bulkWarningThreshold: setting.get('overview.bulkWarningThreshold', 40) }) }
                </OverviewPanelBody>
                <OverviewPanelFooter>
                    <div className="overview-panel__buttons">
                        <Button simple to="/orders/place-order-bulk">Place an order</Button>
                    </div>
                </OverviewPanelFooter>
            </OverviewPanel>
        </>
    );
}
export default OverviewPendingGaugeReading;