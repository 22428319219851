import textFieldValidator from './Text';

const fieldValidator = (field, fieldValue, invalidCharacters = '|^') => {
    let response = textFieldValidator(field, fieldValue);
    if (response.valid) {
        let invalidFound = '';
        [...invalidCharacters].forEach((character) => {
            if (fieldValue.includes(character)) {
                response.valid = false;
                invalidFound += character;
            }
        });
        if (!response.valid) {
            response.validationMessage = field.label + ' must not contain ' + (invalidFound.length > 1 ? ' any of: ' : '') + invalidFound;
        }
    }
    return response;
}

export default fieldValidator;