import React, { createContext, useState, useEffect, useMemo } from 'react';
import { api } from 'helper/api/client';
import content from 'helper/content';
import setting from 'helper/setting';

const defaults = {
    failedToLoad: null,
    routeConfig: [
        {
            path: 'auth',
            component: 'Login',
            title: 'Login',
            requiresAuthentication: false,
            redirectUnauthenticated: true
        }
    ],
    messages: [],
}

const ConfigContext = createContext(defaults);

export const ConfigContextProvider = (props) => {
    const [failedToLoad, setFailedToLoad] = useState(defaults.failedToLoad);
    const [routeConfig, setRouteConfig] = useState(defaults.routeConfig);
    const [messages, setMessages] = useState(defaults.messages);

    // prevents changes updating sub components
    const memoisedFailedToLoad = useMemo(() => failedToLoad, [failedToLoad]);
    const memoisedRouteConfig = useMemo(() => routeConfig, [routeConfig]);
    const memoisedMessages = useMemo(() => messages, [messages]);

    useEffect(() => {
        api.get('config')
            .then(response => {
                setting.set(response.data.response.settings);
                content.set(response.data.response.content);
                setRouteConfig(response.data.response.routes);
                setMessages(response.data.response.messages);
                setFailedToLoad(false);
                window.localStorage.setItem('maintenanceMode', 'false');
            }).catch(error => {
                setFailedToLoad(true);
                window.localStorage.setItem('maintenanceMode', 'true');
            api.error(error);
        });
    }, []);

    return <ConfigContext.Provider
        value={ {
            failedToLoad: memoisedFailedToLoad,
            routeConfig: memoisedRouteConfig,
            messages: memoisedMessages,
            setMessages: setMessages
        } }>{ props.children }</ConfigContext.Provider>;
}

export default ConfigContext;


export const isMaintenanceMode = (messages) => {
    if (window.localStorage.getItem('maintenanceMode') === 'true') {
        return true;
    }
    for (const message of messages) {
        if (message.maintenance) {
            return true;
        }
    }
    return false;
}

export const getMaintenanceMessage = (messages) => {
    for (const message of messages) {
        if (message.maintenance) {
            return message;
        }
    }
    if (window.localStorage.getItem('maintenanceMode') === 'true') {
        return { title: content.text('maintenance.title', 'The portal is currently down for maintenance'), text: content.html('maintenance.text', '<p>We apologise for any inconvenience caused. Please try again shortly.</p>') }
    }
    return false;
}