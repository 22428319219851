import { getClassName } from 'helper/bem';

const OverviewPanelTitle = (props) => {
    return (
        <div className={ getClassName('overview-panel__title', props.className) }>
            { props.title && <h3>{ props.title }</h3> }
            { props.children }
        </div>
    );
}
export default OverviewPanelTitle;