import FlogasForm from 'components/partial/FlogasForm';

const FormEquipmentFault = (props) => {
    const formFields = {
        query: { label: 'Query', required: true, type: 'Textarea', rows: 10 },
    };
    return (
        <FlogasForm formFields={ formFields } form="equipment-fault" eventTitle="Equipment fault" title="Equipment fault" />
    );
};

export default FormEquipmentFault;