import Form from 'components/form/Form';
import AccountsContext from 'context/accounts';
import FieldSet from 'components/form/FieldSet';
import { api } from 'helper/api/client';
import content from 'helper/content';
import React, { useContext, useRef } from 'react';
import InfoBox from 'components/ui/InfoBox';
import Icon from 'components/ui/Icon';

const RegisterStageVerify = (props) => {
    const infoBox = useRef(null);
    const { setAccounts } = useContext(AccountsContext);
    const fieldReference = (props.field === 'email' ? 'email' : 'phoneNumber');

    const iconClick = (event) => {
        event.preventDefault();
        if (infoBox.current) {
            if (infoBox.current.classList.contains('info-box--hidden')) {
                infoBox.current.classList.remove('info-box--hidden');
            } else {
                infoBox.current.classList.add('info-box--hidden');
            }
        }
    }
    const formFields = {
        data: { label: props.fieldNames[props.field].label, required: true, type: (props.field === 'email' ? 'Email' : 'Text'), validator: (props.field === 'email' ? 'Email' : 'Phone'), placeholder: props.verifyData[props.field], fieldAfter: <button type="button" onClick={ iconClick } className="form__info-icon" tabIndex={-1}><Icon icon="Info" /></button> },
    };
    formFields.data.value = process.env['REACT_APP_TEST_' + (props.field === 'email' ? 'EMAIL' : 'PHONE_NUMBER')];
    const validHandler = (form) => {
        const { fieldValues, setInvalidFields, logActionSuccess, setErrorMessage, enableForm } = form;
        const validateData = { accountNumber: props.formData.accountNumber, postcode: props.formData.postcode, [fieldReference]: fieldValues.data };
        if (fieldReference === 'email') {
            validateData.phoneNumber = '';
        } else {
            validateData.email = '';
        }

        api.post('customer/validate', validateData)
            .then(response => {
                if (response.data.errors && response.data.errors.failedValidateRegistrationDuplicate) {
                    setErrorMessage(content.html('register.emailExists', 'Your email is already registered, please use forgotten password to <a href="/forgotten-password">reset your password</a> or login with this email address.'));
                    enableForm();
                    setInvalidFields({ data: '' });
                } else {
                    if (api.success(response, form, !props.addAccount)) {
                        if (response.data.response.email !== '') {
                            validateData.email = response.data.response.email;
                        }
                        validateData.hasPhoneNumber = response.data.response.hasPhoneNumber;
                        props.setFormData(validateData);
                        if (props.addAccount && props.verifyData.email !== '') {
                            api.post('customer/add', validateData)
                                .then(response => {
                                    if (api.success(response, form, false)) {
                                        logActionSuccess();
                                        setAccounts(response.data.response.accounts);
                                        props.setFormStage('added');
                                    }
                                }).catch(error => {
                                api.error(error, form, 'Sorry, we could not validate your details, please check and try again');
                                setInvalidFields({ data: '' });
                            });
                        } else {
                            logActionSuccess();
                            props.setFormStage('password');
                            props.dispatchModal({ type: 'close' });
                        }
                    }
                }
            }).catch(error => {
            api.error(error, form, 'Sorry, we could not validate your details, please check and try again');
            setInvalidFields({ data: '' });
        });
    };
    return (
        <Form formFields={ formFields }
              modal
              className="register-verify"
              eventTitle={ 'Register verify - ' + props.field }
              submitName="Verify"
              valid={ validHandler }
              submitOutline={ props.addAccount }
              submitColour={ props.addAccount ? 'blue' : 'purple' }>
            <p>Please verify your { props.fieldNames[props.field].label.toLowerCase() } below</p>
            <FieldSet fields={ ['data'] } />
            <InfoBox ref={ infoBox } className="text-small hidden">
                { props.field === 'email' && content.html('register.helpInfoEmail', '<p>Your email address must match exactly what we have stored for you.</p><p>It may be an older email address you have used - you can change this once you’re logged in.</p>') }
                { props.field !== 'email' && content.html('register.helpInfoPhone', '<p>Your phone number must match exactly what we have stored for you including spaces and the + symbol.</p><p>It may be an older phone number you have used - you can change this once you’re logged in.</p>') }
            </InfoBox>
        </Form>
    );
};

export default RegisterStageVerify;