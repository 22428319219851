import { api } from 'helper/api/client';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';
import Button from 'components/ui/Button';
import Loading from 'components/view/Loading';

import 'scss/component/faqs/faqs.scss';
import { getHtml } from 'helper/html';

const FaqItem = (props) => {
    const location = useLocation();
    const pathArray = location.pathname.substr(1).split('/');
    const faqSlug = pathArray[3];
    const [faq, setFaq] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        api.get('faqs/item/' + faqSlug).then(response => {
            setFaq(response.data.response);
            api.setCache(response);
            setLoading(false);
        }).catch(error => {
            api.error(error);
            setLoading(false);
        });
    }, [faqSlug]);
    const getFaq = () => {
        return (
            <>
            <Box>
                <h2>{ faq.question }</h2>
                <div className="faq-item">{ getHtml(faq.answer) }</div>
            </Box>
                <Button to={ '/' + pathArray[0] + '/' + pathArray[1] } icon="ArrowLeft" simple={ true }>Back to FAQ list</Button>
            </>
        );
    }
    return (
        <>
            <RouteHeader route={ props.route }/>
            { faq && getFaq() }
            { !faq && !loading && <Box><p>Failed to load FAQ</p></Box> }
            { loading && <Loading /> }
        </>
    );
};

export default FaqItem;