const fieldValidator = (field, fieldValue) => {
    let response = { valid: true, validationMessage: '' }
    fieldValue = String(fieldValue).replace(' ', '').toUpperCase();
    // Same regex as flogas.co.uk
    const regex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
    if (!regex.test(fieldValue)) {
        response.valid = false;
        response.validationMessage = field.label + ' must be valid UK postcode';
    }
    return response;
}

export default fieldValidator;