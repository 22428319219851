import content from 'helper/content';
import React, { useReducer } from 'react';
import Button from 'components/ui/Button';
import Modal from 'portal/Modal';
import { modalInitialState, modalReducer } from 'helper/modalReducer';
import RegisterStageVerifyModal from './RegisterStageVerifyModal';

const RegisterStageVerify = (props) => {
    const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);
    const verifyData = props.verifyData;
    let fieldCount = 0;
    const fieldNames = {
        email: { label: 'Email address', shortLabel: 'email', type: 'Email' },
        phoneNumber: { label: 'Phone number', shortLabel: 'phone' },
        phoneNumber2: { label: 'Phone number 2', shortLabel: 'phone 2' },
        mobileNumber: { label: 'Mobile number', shortLabel: 'mobile' },
    }
    const validCount = Object.keys(verifyData).reduce((valid, field) => (verifyData[field].length ? valid + 1 : valid), 0);
    const goBack = (event) => {
        event.preventDefault();
        props.setFormStage('1');
    }
    const alternativeValidate = () => {
        props.setFormStage('alternative');
    }
    const confirmModal = (event) => {
        const content = <RegisterStageVerifyModal
                            field={ event.currentTarget.dataset.field }
                            dispatchModal={ dispatchModal }
                            addAccount={ props.addAccount }
                            setFormStage={ props.setFormStage }
                            setFormData={ props.setFormData }
                            formData={ props.formData }
                            verifyData={ verifyData }
                            fieldNames={ fieldNames }
                        />;
        dispatchModal({ type: 'open', title: 'Verify ' + fieldNames[event.currentTarget.dataset.field].shortLabel, yesOption: null, content });
    }
    const validateData = Object.keys(verifyData).reduce((object, key) => {
        if (verifyData[key].length) {
            object[key] = verifyData[key];
        }
        return object
    }, {});
    return (
        <>
            <div className="register-verify">
                { validCount === 0 &&
                    <>
                        { content.html('register.noneVerified', '<p>Thanks! We currently don’t have an email or phone number on file for you. Please activate using alternative details.</p>') }
                    </>
                }
                { validCount > 0 &&
                    <>
                    {content.html('register.verify' + (Object.keys(validateData).length > 1 ? 'Multiple' : 'Single'), '<p>Thanks! Please verify either of the below details we have on file for you.</p>') }
                        { Object.keys(verifyData).map(field => {
                            if (verifyData[field] && verifyData[field].trim().length) {
                                fieldCount ++;
                                return (
                                    <div className="register-verify__item" key={ field }>
                                        <p className="register-verify__item-title">{ fieldNames[field].label }</p>
                                        <div className="register-verify__item-data">
                                            <p className="register-verify__item-data-text">{ verifyData[field] }</p>
                                            <Button simple data-field={ field } action={ confirmModal }>Confirm { fieldNames[field].shortLabel }</Button>
                                        </div>
                                        { fieldCount < validCount && <p className="register-verify__item-or">- or -</p> }
                                    </div>
                                )
                            }
                            return null;
                        }) }
                    </>
                }
                <div className="register-verify__alternative">
                    <Button action={ alternativeValidate } simple>Activate using alternative details</Button>
                </div>
            </div>
            <Button action={ goBack } simple>Back</Button>
            <Modal { ...modal } dispatchModal={ dispatchModal }>{ modal.content }</Modal>
        </>
    );
};

export default RegisterStageVerify;