import FlogasForm from 'components/partial/FlogasForm';

const FormPricingQuery = (props) => {
    const formFields = {
        query: { label: 'Query', required: true, type: 'Textarea', rows: 10 },
    };
    return (
        <FlogasForm formFields={ formFields } form="pricing-query" eventTitle="Pricing query" title="Pricing query" />
    );
};

export default FormPricingQuery;