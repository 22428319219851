import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';

import content from 'helper/content';
import OverviewPanelFooter from '../structure/OverviewPanelFooter';
import Button from 'components/ui/Button';


const modifierClass = 'finish-dated';

const OverviewNoTerms = ({ data }) => {
    const title = content.text('overview.noTermsTitle', 'We\'re still setting up your account');
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle className={ modifierClass } title={ title } />
                <OverviewPanelBody className={ modifierClass }>
                    { content.html('overview.noTermsText', '<p>We\'re still setting up parts of your new account, so there may be some things you won\'t be able to access and view on My Account.</p><p>If you don’t think this should be the case, please get in touch.</p>') }
                </OverviewPanelBody>
                <OverviewPanelFooter>
                    <div className="overview-panel__buttons">
                        <Button simple to="/help-support/contact">Contact us</Button>
                    </div>
                </OverviewPanelFooter>
            </OverviewPanel>
        </>
    );
}
export default OverviewNoTerms;