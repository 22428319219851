import { useContext } from 'react';
import content from 'helper/content';

import AuthContext from 'context/auth';
import UserContext from 'context/user';
import AccountsContext from 'context/accounts';
import { api } from 'helper/api/client';
import log from 'helper/log';

const RegisterStageComplete = (props) => {
    const { setToken, setAuthenticated } = useContext(AuthContext);
    const { setUser } = useContext(UserContext);
    const { setAccounts } = useContext(AccountsContext);
    const seconds = 3;
    const message = '<h3>Thank you for activating</h3><p>You will be automatically taken to the overview screen in {seconds} seconds...</p>';
    setTimeout(() => {
        if (props.createdUser.user) {
            setToken(props.createdUser.token);
            log.setCustomer(props.createdUser.user);
            log.action('Form submission successful', 'Register complete', 'form_submission_successful');
            setUser({ ...props.createdUser.user });
            setAccounts(props.createdUser.user.accounts);
            if (!props.addAccount) {
                setAuthenticated(true);
            }
        } else if (props.createdUser.legacyOnly) {
            api.legacyPost('login', { token: props.createdUser.token }).then(response => {
                window.location = process.env.REACT_APP_LEGACY_URL;
            });
        }
    }, (seconds * 1000));
    return (
        content.html('register.complete', message, { seconds })
    );
};

export default RegisterStageComplete;