import React from 'react';

const OverviewInfoBox = ({ data }) => {
    const price = 123.45;
    const date = '12/02/2022';
    return (
        <>
            <h3 className="overview-panel-small__title">Your tariff</h3>
            <div className="overview-panel-small__body">
                <p className="overview-panel-small__body-primary">Standing charge £{ price }</p>
                <p className="overview-panel-small__body-secondary">Agreement expiry date { date }</p>
            </div>
        </>
    );
}

export default OverviewInfoBox;