import FlogasForm from 'components/partial/FlogasForm';

const FormRefundRequest = (props) => {
    const formFields = {
        amount: { label: 'Amount', required: true, type: 'Currency'  },
        comments: { label: 'Comments', required: true, type: 'Textarea'  },
    };
    return (
        <FlogasForm formFields={ formFields } form="refund-request" eventTitle="Refund request" title="Refund request" />
    );
};

export default FormRefundRequest;