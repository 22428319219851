import ListItem from 'components/ui/ListItem';
import { addressFields } from 'helper/address';

const AddressRow = (props) => {
    const getAddress = () => {
        let address = [];
        for (let addressLine of addressFields) {
            addressLine = String(props.address[addressLine]).trim();
            if (addressLine.length) {
                address.push(addressLine);
            }
        }
        return <>
            { props.address.nickName && <p className="list-item__title-primary">{ props.address.nickName }</p> }
            <address className="list-item__title-secondary">{ address.join(', ') }</address>
        </>
    }
    const editProps = {
        title: (props.address.nickName ? 'Edit name' : 'Set a name'),
        action: props.editAddressName,
        "data-id": props.address.id,
    }
    const deleteProps = {
        apiAction: 'delivery-address/delete/' + props.address.id,
        apiVerb: 'delete',
        modal: props.modal,
        modalTitle: 'Are you sure you want to remove this address?',
        modalYesOption: 'Yes',
        modalNoOption: 'Cancel',
        dispatchModal: props.dispatchModal,
        apiSuccess: props.deleteSuccess,
    }
    return (
        <ListItem
            className={ 'address' + (props.address.nickName ? '' : ' no-primary') }
            edit={ editProps }
            delete={ deleteProps }
        >
            { getAddress() }
        </ListItem>
    );
}
export default AddressRow;