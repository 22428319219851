import FlogasForm from 'components/partial/FlogasForm';
import FieldSet from 'components/form/FieldSet';

const FormChangeToTopUp = (props) => {
    const formFields = {
        query: { label: 'Any information you would like to share?', type: 'Textarea', rows: 10 },
    };
    return (
        <FlogasForm formFields={ formFields } form="change-to-top-up" eventTitle="Change to automatic ordering" title="Change to automatic ordering">
            <p>I would like Flogas to contact me about changing to automatic ordering.</p>
            <FieldSet fields={ ['query'] } />
        </FlogasForm>
    );
};

export default FormChangeToTopUp;