import React from 'react';
import Button from 'components/ui/Button';

const OverviewInvoicesStatements = ({ data }) => {
    return (
        <>
            <h3 className="overview-panel-small__title">Invoices and statements</h3>
            <div className="overview-panel-small__body">
                <p className="overview-panel-small__body-large">Manage your invoices and statements online, wherever, whenever.</p>
            </div>
            <div className="overview-panel-small__footer">
                <Button to="/payments/invoices-statements" simple>View invoices and statements</Button>
            </div>
        </>
    );
}

export default OverviewInvoicesStatements;