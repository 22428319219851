import MockParagraph from 'components/ui/MockParagraph';

const MockParagraphs = (props) => {
    let rows = new Array((props.rows ? parseInt(props.rows) : 3));
    if (rows < 0) {
        rows = 3;
    }
    if (rows > 12) {
        rows = 12;
    }
    rows.fill(null);
    return (
        <>
            { rows.map((value, index) => {
                return <MockParagraph key={ index } items={ props.items } element={ props.element }/>
            }) }
        </>
    );
}

export default MockParagraphs;