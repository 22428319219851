import PropTypes from 'prop-types';
import 'scss/component/faqs/faqs.scss';
import { getHtml } from 'helper/html';

const FaqItem = (props) => {
    const { faq } = props;
    const icon = (props.openId === faq.id ? '-' : '+');
    return (
        <div className="faq">
            <p className="faq__question" key={ faq.id }>
                <button key={ faq.id } className="faq__question-link" data-id={ faq.id } onClick={ () => props.setOpenId(props.openId === faq.id ? null : faq.id) }><span className="faq__question-link-icon">{ icon }</span>{ faq.question }</button>
            </p>
            <div className={ 'faq__answer' + (props.openId === faq.id ? ' faq__answer--open' : '') }><div className="faq__answer-inner">{ getHtml(faq.answer) }</div></div>
        </div>
    );
}

FaqItem.propTypes = {
    faq: PropTypes.object.isRequired
}

export default FaqItem;