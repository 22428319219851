import FlogasForm from 'components/partial/FlogasForm';

const FormCustomerComplaint = (props) => {
    const formFields = {
        query: { label: 'Details of your complaint', required: true, type: 'Textarea', rows: 10 },
    };
    return (
        <FlogasForm formFields={ formFields } form="customer-complaint" eventTitle="Customer complaint" title="Customer complaint" />
    );
};

export default FormCustomerComplaint;