const crudReducer = (state, action) => {
    switch (action.type) {
        case 'set': {
            return action.records;
        }
        case 'add': {
            return state.concat(action.record);
        }
        case 'update': {
            return state.map((record, index) => {
                if ((typeof record.id === 'number' && record.id === parseInt(action.id)) || (typeof record.id === 'string' && record.id === String(action.id))) {
                    return { ...record, ...action.record };
                }
                return record;
            });
        }

        case 'delete': {
            return state.filter((record, index) => record.id !== action.id);
        }

        default:
            throw new Error(`Not supported action ${ action.type }`);
    }
}

export default crudReducer;

/*
EXAMPLE:

import { crudReducer } from 'helper/crudReducer';

...

const [addresses, dispatchCrud] = useReducer(crudReducer, []);

// Set records
dispatchCrud({ type: 'set', records: <dataRecords> })
// Add to data
dispatchCrud({ type: 'add', record: <newRecord> })
// Update data
dispatchCrud({ type: 'update', id: <id>, record: <record> })
// Delete data
dispatchCrud({ type: 'update', id: <id> })
 */
