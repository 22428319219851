import React, { useState, useEffect } from 'react';
import Password from 'components/form/field/Password';
import getPasswordValdation from 'helper/password';

const PasswordStrength = (props) => {
    const [message, setMessage] = useState('');
    const [colour, setColour] = useState('danger');
    const passwordField = (props.formFields[props.name].passwordField ? props.formFields[props.name].passwordField : null);
    const password = String(props.value);
    useEffect(() => {
        if (password.length > 0) {
            const minLength = (props.minLength > 0 ? props.minLength : 12);
            let validationEmail = (props.validatorExtra && props.validatorExtra.email ? props.validatorExtra.email : null)
            if (props.formFields[props.name].emailField) { // For registration we get the field from the field values as it's changed
                validationEmail = props.fieldValues[props.formFields[props.name].emailField]
            }
            const validation = getPasswordValdation(props.label, password, minLength, validationEmail);
            if (passwordField !== null && password !== props.fieldValues[passwordField]) {
                setMessage(props.label + ' does not match ' + props.formFields[passwordField].label.toLowerCase());
                setColour('warning');
            } else if (validation.valid) {
                setMessage('Good password');
                setColour('success');
            } else {
                setMessage(validation.message);
                setColour(validation.colour);
            }
        } else {
            setMessage('');
            setColour('success');
        }
    }, [password, props, passwordField]);
    return <>
        <Password { ...props } />
        <div className="form__field-password-strength">
            { message.length > 0 && <div className="form__field-password-strength-indicator">
                <div className={ 'form__field-password-strength-indicator-inner form__field-password-strength-indicator-inner--' + colour }></div>
            </div> }
            { message.length > 0 && <span className="form__field-password-strength-label">{ message }</span> }
        </div>
    </>
};

export default PasswordStrength;