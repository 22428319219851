import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'element-closest-polyfill';
import 'scroll-polyfill/auto';
import { getFormFields, getFieldValues, getFieldsDisabled, getFieldVisibility } from 'helper/form/fields';
import { getValidFields, getInitialValidFields, isValid } from 'helper/form/validation';
import FormFieldList from 'components/form/FieldList';
import NotificationMessage from 'components/ui/NotificationMessage';
import Button from 'components/ui/Button';
import { getClassName } from 'helper/bem';
import log from 'helper/log';
import global from 'helper/global';
import { isSubmit, isTitle, isFieldset, getChildrenHaveSubmit, getChildrenHaveTitle, fieldValuesDefault, getChildren } from 'helper/form/form';

let filterTimeout;

const Form = (props) => {
    // Format the form fields for consistency or missing keys
    const formFields = getFormFields(props.formFields);

    // Validation states for each field
    const [fieldValidation, setFieldValidation] = useState(getInitialValidFields(formFields));

    // String error message
    const [errorMessage, setErrorMessage] = useState('');

    // Whether to hide the form, used after submission
    const [submittedMessage, setSubmittedMessage] = useState('');

    // String success message
    const [successMessage, setSuccessMessage] = useState('');

    // String success message
    const [hasChanged, setHasChanged] = useState(props.hasChanged);

    // Object of field values
    const [fieldValues, setFieldValues] = useState(getFieldValues(formFields));

    // Object of field visibility
    const [fieldVisibility, setFieldVisibility] = useState(getFieldVisibility(formFields, fieldValues));

    // Object of field values
    const [fieldsDisabled, setFieldsDisabled] = useState(getFieldsDisabled(formFields));

    // Loading (awaiting AJAX response) for visual feedback
    const [loading, setLoading] = useState(false);

    const submitButton = useRef(null);
    const formElement = useRef(null);

    // Handle field value changes when the form is a filter form (automatically submitting)
    // mounted prevents it running on inital load/mount
    const mounted = useRef(false);
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            if (props.initalScrollTo) {
                scrollToForm('auto');
            }
        } else {
            if (props.filter && !loading) {
                filterTimeout = setTimeout(() => {
                    submitButton.current.click();
                }, props.filterTimeout);
            }
            return () => {
                clearTimeout(filterTimeout);
            }
        }
    }, [props.filter, props.initalScrollTo, props.filterTimeout, fieldValues, loading]);
    useEffect(() => {
        setFieldsDisabled(getFieldsDisabled(formFields, props.loading));
    }, [props.loading, formFields]);


    useEffect(() => {
        const setKeydown = (event) => {
            if (['Enter', 'NumpadEnter'].includes(event.code)) {
                const submitFocused = submitButton.current && document.activeElement === submitButton.current;
                if (submitFocused) {
                    submitButton.current.click();
                }
            }
        }
        document.addEventListener('keydown', setKeydown, false);
        return () => {
            document.removeEventListener('keydown', setKeydown, false);
        };
    }, []);

    // Resets everything if the form fields change e.g. on an edit screen where the values are set dynamically
    useEffect(() => {
        // Only do so if the form isn't a filter form
        if (typeof props.id !== 'undefined' && !props.filter) {
            setFieldValues(getFieldValues(formFields));
            setFieldVisibility(getFieldVisibility(formFields, getFieldValues(formFields)));
            setSuccessMessage('');
            setErrorMessage('');
            setSubmittedMessage('');
            setFieldValidation(getInitialValidFields(formFields));
            setLoading(false);
        }
    }, [formFields, props.id, props.filter]);

    let classNames = '';
    if (!props.visible) {
        classNames += ' hidden';
    }
    if (props.filter) {
        classNames += ' filter';
    }
    if (props.modal) {
        classNames += ' modal';
    }
    if (props.loading) {
        classNames += ' loading';
    }
    if (props.className) {
        classNames += ' ' + props.className;
    }

    const inputChangeHandler = (event) => {
        // Handle any changes and bind the data to fieldValues
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = (event.target.checked ? '1' : '0');
        }
        let newValues = { ...fieldValues, [event.target.name]: value };
        if (JSON.stringify(fieldValues) !== JSON.stringify(newValues)) {
            let changeHandlerNewValues;
            if (props.changeHandler) {
                changeHandlerNewValues = props.changeHandler(event, newValues);
                if (changeHandlerNewValues !== false) {
                    newValues = changeHandlerNewValues;
                }
            }
            setFieldValues(prevState => {
                setHasChanged(true);
                setFieldVisibility(getFieldVisibility(formFields, newValues));
                return newValues;
            });
            global.setCanUpdateCustomer(false);
            if (props.dispatchModal) {
                props.dispatchModal({ type: 'changeWarning' });
            }
        }
    }

    const inputFocusHandler = (event) => {
        // Handle focus changes
        if (props.dispatchModal) {
            props.dispatchModal({ type: 'changeWarning' });
        }
    }
    const setInvalidField = (field, message) => {
        fieldValidation[field] = { valid: false, validationMessage: message };
        setFieldValidation(fieldValidation);
    }
    const setValidField = (field) => {
        fieldValidation[field] = { valid: true, validationMessage: '' };
        setFieldValidation(fieldValidation);
    }
    const resetForm = () => {
        const resetFieldValues = Object.keys(fieldValues)
            .reduce((obj, key) => {
                obj[key] = '';
                return obj;
            }, {});
        enableForm();
        setErrorMessage('');
        setSubmittedMessage('');
        setFieldValidation(getInitialValidFields(formFields));
        setFieldValues(resetFieldValues);
        setFieldVisibility(getFieldVisibility(formFields, getInitialValidFields(formFields)));
    }
    const enableForm = () => {
        setLoading(false);
        setFieldsDisabled(getFieldsDisabled(formFields, false));
    }
    const disableForm = () => {
        setFieldsDisabled(getFieldsDisabled(formFields, true));
    }
    const logActionSuccess = () => {
        if (props.logActionSuccess) {
            const eventAction = (props.eventTitle && props.eventTitle.length ? props.eventTitle : (window.location.pathname + window.location.hash));
            log.action('Form submission successful', eventAction, 'form_submission_successful');
        }
    }
    const setInvalidFields = (fields) => {
        let invalidFields = {};
        Object.entries(fields).forEach(([field, message]) => {
            invalidFields[field] = { valid: false, validationMessage: message };
        });
        setFieldValidation({ ...fieldValidation, ...invalidFields });
    }
    const scrollToForm = (behaviour = 'smooth') => {
        const threshold = (window.outerWidth > 1280 ? 20 : 90); // 90 is threshold for mobile header
        const objectToScroll = (document.getElementsByClassName('modal-content--open').length ? document.getElementsByClassName('modal-content--open')[0] : window);
        objectToScroll.scroll({
            top: formElement.current.getBoundingClientRect().top + window.scrollY - threshold,
            behavior: behaviour
        });
    }
    const submitHandler = (event) => {
        if (event) {
            event.preventDefault();
        }
        setLoading(true);
        if (!loading) {
            setErrorMessage('');
            setSuccessMessage('');
            setSubmittedMessage('');
            disableForm();
            const eventAction = (props.eventTitle && props.eventTitle.length ? props.eventTitle : (window.location.pathname + window.location.hash));
            // Get object of all fields and their validation states
            let validFields = getValidFields(formFields, fieldValues, fieldVisibility);
            // Sets validation states for each field e.g. class with error and error message
            setFieldValidation(validFields);

            // Custom validation function passed as a prop
            if (props.validation) {
                validFields = props.validation({ validFields, fieldValues, fieldVisibility, setErrorMessage, setSuccessMessage, setInvalidField, setValidField, resetForm, enableForm, disableForm, setSubmittedMessage, fieldValidation, setInvalidFields, scrollToForm });
            }

            if (isValid(validFields)) {
                if (props.valid) {
                    // Call the form's custom valid method
                    props.valid({ fieldValues, fieldVisibility, setErrorMessage, setSuccessMessage, setInvalidField, setValidField, resetForm, enableForm, disableForm, setSubmittedMessage, fieldValidation, setInvalidFields, logActionSuccess, scrollToForm });
                } else {
                    enableForm();
                    scrollToForm();
                    logActionSuccess();
                }
                global.setCanUpdateCustomer(true);

            } else {
                if (props.invalid) {
                    // Call the form's custom invalid method
                    props.invalid({ fieldValues, fieldVisibility, setErrorMessage, setSuccessMessage, setInvalidField, setValidField, resetForm, enableForm, disableForm, setSubmittedMessage, fieldValidation: validFields, setFieldValidation, scrollToForm });
                } else {
                    enableForm();
                    scrollToForm();
                }
                log.action('Form submission failure', eventAction, 'form_submission_failure');
            }
        }
    }

    const getSubmit = (key) => {
        const adminId = window.localStorage.getItem('_adminId');
        if (!props.filter && adminId && adminId !== '') {
            return <div key={ key } className={ getClassName('form__submit-wrapper', (props.submitAlign ? ' align-' + props.submitAlign : '') + (props.showSubmit ? '' : ' hidden')) }>
                <NotificationMessage type="warning">Form submissions disabled when logged into a customer's account</NotificationMessage>
                <Button type="submit" disabled ref={ submitButton } loading={ loading } outline={ props.submitOutline } colour={ props.submitColour }>{ props.submitName }</Button>
            </div>
        } else {
            return <div key={ key } className={ getClassName('form__submit-wrapper', (props.submitAlign ? ' align-' + props.submitAlign : '') + (props.showSubmit ? '' : ' hidden')) }>
                <Button type="submit" disabled={ !hasChanged } ref={ submitButton } loading={ loading } outline={ props.submitOutline } colour={ props.submitColour }>{ props.submitName }</Button>
            </div>
        }
    }

    const formFieldListProps = {
        formFields,
        fieldValues,
        setFieldValues,
        fieldsDisabled,
        fieldVisibility,
        //setFieldVisibility,
        fieldValidation,
        inputChangeHandler,
        inputFocusHandler,
        filter: props.filter,
        getSubmit
    }

    // Replace the children recursively with <Fieldset components that have the correct props e.g. formFields, fieldValues, fieldValidation, inputChangeHandler, inputFocusHandler
    const getChildrenWithProps = (children) => {
        return React.Children.toArray(children).map(child => {
            let newChildren;
            // Work out the new children if there are any defined
            const children = getChildren(child);
            if (children) {
                newChildren = getChildrenWithProps(children);
            }
            // If the element is a Fieldset, clone and replace it with one with the relevant props
            if (isFieldset(child)) {
                return React.cloneElement(child, { fields: child.props.fields, ...formFieldListProps }, newChildren);
            }
            // Replace any submit buttons withe the one from getSubmit
            if (isSubmit(child)) {
                return getSubmit(child.key);
            }
            // Replace the form title with one that includes the notification messages
            if (isTitle(child)) {
                return (<div key={ child.key }>
                        { React.cloneElement(child) }
                        { getNotificationMessages() }
                    </div >
                );
            }
            // Replace the current child if their children have changed
            if (newChildren) {
                return React.cloneElement(child, { ...child.props }, newChildren);
            }
            // Return the original child unchanged
            return child;
        });
    }
    const getNotificationMessages = () => {
        return (<>
            { errorMessage && <NotificationMessage key="warning" type="warning" message={ errorMessage }/> }
            { successMessage && <NotificationMessage key="success" type="success" message={ successMessage }/> }
            { (global.formFailure === props.globalIdentifier && global.failureMessage) && <NotificationMessage key="globalFailure" type="warning" hideAfter={ 3 } message={ global.failureMessage } globalFormSuccess /> }
            { (global.formSuccess === props.globalIdentifier && global.successMessage) && <NotificationMessage key="globalSuccess" type="success" hideAfter={ 3 } message={ global.successMessage } globalFormSuccess /> }
        </>);
    }
    const getForm = () => {
        return (<>
            { props.title && !getChildrenHaveTitle(props.children) && <h3 className="form__title">{ props.title }</h3> }
            { !getChildrenHaveTitle(props.children) && getNotificationMessages() }
            { React.Children.count(props.children) === 0 && <FormFieldList { ...formFieldListProps } fields={ formFields } /> }
            { getChildrenWithProps(props.children) }
            { !getChildrenHaveSubmit(props.children) && getSubmit() }
            { props.filter && props.showReset && !fieldValuesDefault(fieldValues) && <Button outline={ true } simple={ true } action={ resetForm }>{ props.resetName }</Button> }
        </>);
    }
    return (
        <form ref={ formElement } noValidate className={ getClassName('form', classNames) } onSubmit={ submitHandler }>
            { submittedMessage.length === 0 ? getForm() : submittedMessage }
        </form>
    );
};

Form.defaultProps = {
    showSubmit: true, // Whether to show the submit button
    id: null, // Used to help with identifying an edit for, 0 for an add form, can be a string
    visible: true, // If it's visible
    filter: false, // It's a filter form
    showReset: false, // It's a filter form whether to show a reset button when changed
    hasChanged: false, // Use to disabled the submit button until a change is made, can be overridden e.g. the login form
    title: null, // To show <h3 class="form__title">{ title }</h3> at the top of the form
    filterTimeout: 300, // To show <h3 class="form__title">{ title }</h3> at the top of the form
    initialSuccessMessage: '', // Label for submit button
    submitName: 'Submit', // Label for submit button
    resetName: 'Reset', // Label for reset button (only on filter forms)
    submitColour: 'blue', // Default submit colour
    submitAlign: '', // blank for left align, right for right align
    submitOutline: true, // Submit outline style button
    logActionSuccess: true, // On success automatically log an event action
    validation: null, // Function reference to do custom validation
    valid: null, // Function reference to execute after the form is submitted valid
    invalid: null, // Function reference to execute after the form is submitted invalid
    changeHandler: null, // Function reference to execute after an field value changes
}

Form.propTypes = {
    formFields: PropTypes.object.isRequired, // object of form fields
    //id: PropTypes.number,
    visible: PropTypes.bool,
    filter: PropTypes.bool,
    showReset: PropTypes.bool,
    title: PropTypes.string,
    eventTitle: PropTypes.string.isRequired, // For log and GA events
    submitName: PropTypes.string,
    resetName: PropTypes.string,
    submitColour: PropTypes.oneOf(['blue', 'purple']),
    submitAlign: PropTypes.oneOf(['', 'right']),
    submitOutline: PropTypes.bool,
    validation: PropTypes.func,
    valid: PropTypes.func,
    invalid: PropTypes.func,
    changeHandler: PropTypes.func,
}

export default Form;