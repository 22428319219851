import React, { useContext } from 'react';
import { api } from 'helper/api/client';
import InfoBox from 'components/ui/InfoBox';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import content from 'helper/content';
import RouteHeader from 'components/partial/RouteHeader';
import moment from 'moment';
import permission from 'helper/permission';
import NotificationMessage from 'components/ui/NotificationMessage';
import Box from 'components/ui/Box';
import UserContext from 'context/user';

const MyLpgBoiler = (props) => {
    const { user } = useContext(UserContext);
    const getRange = (min, max) => {
        let range = {};
        for (let index = min; index <= max; index++) {
            let label = String(index);
            range[index] = (label.length === 1 ? '0' : '') + label;
        }
        return range;
    }
    const formFields = {
        day: { label: 'Day', required: true, type: 'Dropdown', options: getRange(1,31), allTitle: 'DD', placeholder: 'DD', value: 1 },
        month: { label: 'Month', required: true, type: 'Dropdown', options: getRange(1,12), allTitle: 'MM', placeholder: 'MM', value: 1 },
        year: { label: 'Year', required: true, type: 'Dropdown', options: getRange(1960, moment().format('YYYY')), allTitle: 'YYYY', placeholder: 'YYYY' },
    };
    const getSubmittedMessage = (date) => {
        return <>
            <h3 className="form__title">LPG boiler installation date</h3><NotificationMessage type="success">Thank you for updating your installation date: <strong>{ date }</strong></NotificationMessage>
        </>;
    }
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess, setSubmittedMessage } = form;
        api.post('customer/lpg-boiler/update', fieldValues)
            .then(response => {
                if (api.success(response, form)) {
                    logActionSuccess();                    ;
                    setSubmittedMessage(getSubmittedMessage((fieldValues.day.length === 1 ? '0' : '') + fieldValues.day + '/' + (fieldValues.month.length === 1 ? '0' : '') + fieldValues.month + '/' + fieldValues.year));
                    permission.permissions.actions.hasBoilerInstallDate = true;
                }
            }).catch(error => {
            api.error(error, form, 'Failed to update installation date');
        });
    };
    return (
        <>
            <RouteHeader route={ props.route }/>
            { permission.check('hasBoilerInstallDate') && <Box padding="large">{ getSubmittedMessage(user.boilerInstallDate) }</Box> }
            { !permission.check('hasBoilerInstallDate') &&
            <Form formFields={ formFields }
                  eventTitle="Boiler installation date"
                  className="boiler-date"
                  valid={ validHandler }
                  submitName="Save boiler installation date">
                <div className="form-info-box-row">
                    <div className="form-info-box-row__fields">
                        <h3 className="form__title">LPG boiler installation date</h3>
                        { content.html('lpgBoiler.intro', '<p>Tell us the installation date for your LPG boiler. We will use this to provide you with tips on environmental efficiency and getting the most from your boiler.</p>') }
                        <div className="form__fields-row">
                            <div className="form__fields-row-field form__fields-row-field--col-4">
                                <FieldSet fields={ ['day'] }/>
                            </div>
                            <div className="form__fields-row-field form__fields-row-field--col-4">
                                <FieldSet fields={ ['month'] }/>
                            </div>
                            <div className="form__fields-row-field form__fields-row-field--col-4">
                                <FieldSet fields={ ['year'] }/>
                            </div>
                        </div>
                        <input type="submit" />
                    </div>
                    <InfoBox className="text-small">
                        { content.html('lpgBoiler.info', '<p>Your boiler installation date is usually found on a sticker on your boiler.</p>') }
                    </InfoBox>
                </div>
            </Form> }
        </>
    );
};

export default MyLpgBoiler;