import React, { useReducer } from 'react';

import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ConfigContext from 'context/config';
import UserContext from 'context/user';
import Icon from 'components/ui/Icon';
import Modal from 'portal/Modal';
import { modalInitialState, modalReducer } from 'helper/modalReducer';

const GlobalMessages = (props) => {
    const [modal, dispatchModal] = useReducer(modalReducer, { ...modalInitialState, open: true });
    const location = useLocation();
    const path = location.pathname.split('/')[1];
    const configContext = useContext(ConfigContext);
    const userContext = useContext(UserContext);
    const isVisible = (message) => {
        return window.localStorage.getItem('messageClosed' + message.id + '_' + userContext.user.accountNumber) !== 'true' && (message.path === '*' || message.path === path);
    }
    const messages = configContext.messages.concat(userContext.user.messages).filter(message => {
        return (message && !message.popup && isVisible(message));
    });
    const popUpMessages = configContext.messages.concat(userContext.user.messages).filter(message => {
        return (message && message.popup && isVisible(message));
    });
    const closeMessage = (event) => {
        let targetId = parseInt(event.currentTarget.dataset.id);
        if (isNaN(targetId) && popUpMessage) {
            targetId = popUpMessage.id;
        }
        if (!isNaN(targetId)) {
            window.localStorage.setItem('messageClosed' + targetId + '_' + userContext.user.accountNumber, 'true');
            configContext.setMessages(configContext.messages.filter(message => message.id !== targetId));
        }
    }
    let popUpMessage;
    if (messages.length || popUpMessages.length) {
        if (popUpMessages.length) {
            popUpMessage = popUpMessages[0];
            popUpMessage.content = <div dangerouslySetInnerHTML={ { __html: popUpMessage.text } }></div>;
        }
        return (
            <>
                { messages.map((message, index) => {
                    return (
                        <div key={ index } className="global-message">
                            <button className="global-message__close" onClick={ closeMessage } data-id={ message.id }><Icon icon="Close"/></button>
                            <h3>{ message.title }</h3>
                            <div dangerouslySetInnerHTML={ { __html: message.text } }></div>
                        </div>
                    )
                }) }
                { popUpMessage && <Modal { ...modal } dispatchModal={ dispatchModal } closeAction={ closeMessage } title={ popUpMessage.title }>{ popUpMessage.content }</Modal> }
            </>
        );
    }
    return null;
}

export default GlobalMessages;