import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';

import content from 'helper/content';
import OverviewPanelFooter from '../structure/OverviewPanelFooter';
import Button from 'components/ui/Button';


const modifierClass = 'finish-dated';

const OverviewAutoOrdering = ({ data }) => {
    const title = content.text('overview.finishDatedTitle', 'Your account is closed');
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle className={ modifierClass } title={ title } />
                <OverviewPanelBody className={ modifierClass }>
                    { content.html('overview.finishDatedText', '<p>Your account is finish dated.</p>') }
                </OverviewPanelBody>
                <OverviewPanelFooter>
                    <div className="overview-panel__buttons">
                        <Button simple to="/help-support/contact">Contact us</Button>
                    </div>
                </OverviewPanelFooter>
            </OverviewPanel>
        </>
    );
}
export default OverviewAutoOrdering;