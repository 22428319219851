import React, { useContext } from 'react';
import ConfigContext from 'context/config';
import NavigationContext from 'context/navigation';
import { useLocation } from 'react-router-dom';
import Tabs from 'components/ui/Tabs';
import { getSubNavigation } from 'helper/customer';
import UserContext from 'context/user';

const SubNav = () => {
    const userContext = useContext(UserContext);
    const location = useLocation();
    const pathArray = location.pathname.substr(1).split('/');
    const basePath = pathArray[0];
    const { routeConfig } = useContext(ConfigContext);
    const navigationContext = useContext(NavigationContext);
    const navigationItem = navigationContext.navConfig.find(navItem => {
        return navItem.path === basePath;
    });
    let subNavCount = 0;
    const getSubNav = () => {
        let route;
        let subNav = {};
        if (navigationItem && navigationItem.children) {
            for (const child of getSubNavigation(navigationItem.children, userContext.user, basePath)) {
                let childPath = child.path.split('/');
                route = routeConfig.find(route => {
                    let routePath = route.path.split('/');
                    if (childPath.length === 1) {
                        return route.path === basePath + '/' + child.path
                    } else {
                        return childPath[0] === routePath[0];
                    }
                });
                if (route) {
                    if ((process.env.REACT_APP_NAV_TEST_MODE && process.env.REACT_APP_NAV_TEST_MODE === 'true') || child.permission) {
                        subNav[child.path] = { title: child.title + (child.permission ? '' : ' *') };
                        subNavCount ++;
                    }
                }
            }
        }
        return subNav;
    };
    const subNav = getSubNav();
    if (subNavCount > 0) {
        return <Tabs tabs={ subNav } nav={ true } />
    }
    return null;
}


export default SubNav;