import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';
import Table from 'components/table/Table';
import { api, axios } from 'helper/api/client';
import NotFound from 'components/view/NotFound';
import printHandler from 'helper/print';
import format from 'helper/format';
import Button from 'components/ui/Button';
import FaqsBox from 'components/partial/FaqsBox';

const TransactionItem = (props) => {
    const { id } = useParams();
    const [transaction, setTransaction] = useState(null);
    useEffect(() => {
        let source = axios.CancelToken.source();
        api.get('transactions/item/' + id)
            .then(response => {
                setTransaction(response.data.response);
                api.setCache(response);
            }).catch(error => {
            setTransaction(false);
            api.error(error);
        });
        // Will cancel AJAX on unmount
        return () => source.cancel();
    }, [id]);
    const theadConfig = {
        date: { className: 'date', content: 'Date' },
        type: { className: 'label-left',content: 'Type' },
        vat: { className: 'amount', content: 'VAT' },
        total: { className: 'amount', content: 'Total' },
    };
    let tableData = [];

    if (transaction === false) {
        return (
            <>
                <RouteHeader route={ props.route }/>
                <NotFound/>
            </>
        );
    } else {
        if (transaction) {
            tableData = [
                {
                    date: transaction.date,
                    type: transaction.type,
                    vat: (transaction.vat !== '' ? format.currency(transaction.vat) : ''),
                    total: format.currency(transaction.total),
                }
            ];
        }
        return (
            <>
                <RouteHeader route={ props.route }/>
                <p><Button simple to="/transactions/previous" noPadding>Back to previous transactions</Button></p>
                <Box padding="large">
                    <div className="box__header">
                        <h3>Reference { transaction ? transaction.reference : '' }</h3>
                    </div>
                    {/*<div className="box__info">
                        <div className="box__info-item"><strong>Payment made by</strong> <MockDataElement data={ transaction } item="paymentBy"/></div>
                    </div>*/}
                    <Table
                        extraClass="transaction-item"
                        boxSize="large"
                        head={ theadConfig }
                        body={ tableData }
                        loading={ !transaction }
                    />
                    <div className="box__buttons">
                    { (transaction && transaction.orderNumber) &&
                        <Button to={ '/orders/previous/item/' + transaction.orderNumber } simple={ true } noPadding>View order</Button>
                    }
                    <Button simple={ true } onClick={ printHandler } icon="Print" noPadding>Print</Button>
                    </div>
                </Box>
                <FaqsBox noPrint settingName="transactionsPage" defaultFaqs={ [76999, 77000, 117797] } />
            </>
        )
    }
};

export default TransactionItem;