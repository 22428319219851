import FlogasForm from 'components/partial/FlogasForm';

const FormEquipmentQuery = (props) => {
    const formFields = {
        query: { label: 'Query', required: true, type: 'Textarea', rows: 10 },
    };
    return (
        <FlogasForm formFields={ formFields } form="equipment-query" eventTitle="Equipment query" title="Equipment query" />
    );
};

export default FormEquipmentQuery;