import 'scss/component/banner/banner.scss';
import Button from 'components/ui/Button';


const Banner = () => {
    return (
<div className={"banner"}>
    <p className={"banner-text"}>Offset your carbon footprint with Flogas</p>
    <Button className={"banner-button"} outline={true} to="carbon-offsetting">Find out more</Button>
</div>
    )
}

export default Banner;