import { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'scss/component/date-picker/date-picker.scss';

const Date = (props) => {
    const [open, setOpen] = useState(false)
    const dateFormat = 'DD/MM/YYYY';
    const getInitialDate = () => {
        if (props.value) {
            if (moment(props.value, dateFormat).isValid()) {
                return moment(props.value, dateFormat).toDate();
            }
        }
        return null;
    }
    const [selected, setSelected] = useState(getInitialDate())
    const onChangeHandler = (date, event) => {
        let valid = true;
        //let newDate = date;
        // Only set valid based on entered keypress date
        if (event.target.value && event.target.value.length && event.target.value.length === 10) {
            valid = moment(event.target.value, dateFormat).isValid();
        }
        if (valid) {
            props.inputChangeHandler( { target: { name: props.name, value: (date === null ? '' : moment(date).format(dateFormat)) } });
            setSelected(date);
        }
    };
    return (
        <DatePicker
            showPopperArrow={false}
            dateFormat="dd/MM/yyyy"
            className={ 'form__field-control form__field-control--date' + (open ? ' form__field-control--date-open' : '') }
            selected={ selected }
            disabled={ props.disabled }
            minDate={ props.minDate }
            maxDate={ props.maxDate }
            placeholderText={ props.placeholder }
            onCalendarClose={ () => setOpen(false) }
            onCalendarOpen={ () => setOpen(true) }
            onChange={ onChangeHandler } />
    );
};

export default Date;