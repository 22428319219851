import React from 'react';

export const addressFields = ['address1', 'address2', 'town', 'county', 'postcode'];

export const getAddress = (address) => {
    let addressLine = '';
    if (address[addressFields[0]]) {
        return (
            <>
                { addressFields.map((addressField) => {
                    addressLine = (typeof address[addressField] !== 'undefined') ? String(address[addressField]).trim() : '';
                    if (addressLine.length) {
                        return <span key={ addressField }>{ addressLine }<br /></span>;
                    }
                    return null;
                })
                }
            </>
        )
    }
    return <>Not specified</>;
}
export const getAddressLine = (address) => {
    let addressItem = '';
    let addressLine = addressFields.map((addressField) => {
        addressItem = (typeof address[addressField] !== 'undefined') ? String(address[addressField]).trim() : '';
        if (addressItem && addressItem.length) {
            return addressItem;
        }
        return null;
    }).filter(addressLine => addressLine);
    return addressLine.join(', ');
}