import React, { useContext } from 'react';
import UserContext from 'context/user';
import { api } from 'helper/api/client';
import { getRecordFields } from 'helper/form/fields';
import { contactFields, contactValidation } from 'helper/contact';
import ContactAddEditForm from 'components/partial/my-account/ContactAddEditForm';
import global from 'helper/global';
import permission from 'helper/permission';

const ContactDetails = () => {
    const { user, setUser } = useContext(UserContext);
    const formFields = getRecordFields(contactFields, user.contact);
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess } = form;
        api.patch('contact/update/' + user.contact.id, fieldValues)
            .then(response => {
                if (api.success(response, form)) {
                    global.formSuccess = 'contactDetails';
                    global.successMessage = 'Contact details updated successfully';
                    setUser({
                        ...user,
                        permissions: { ...user.permissions, actions: { ...user.permissions.actions, isCustomerLocked: response.data.isCustomerLocked } },
                        contact: { ...user.contact, ...fieldValues }
                    });
                    logActionSuccess();
                } else if (response.data.isCustomerLocked) {
                    global.formFailure = 'contactDetails';
                    setUser({
                        ...user,
                        permissions: { ...user.permissions, actions: { ...user.permissions.actions, isCustomerLocked: response.data.isCustomerLocked } },
                    });
                }
            }).catch(error => {
            api.error(error, form, 'Failed to update contact details');
        });
    };

    return (
        <>
            <ContactAddEditForm
                formFields={ formFields }
                valid={ validHandler }
                mainContact={ permission.check('isMainContact') }
                submitName="Update your details"
                eventTitle="Contact details"
                loading={ permission.check('isCustomerLocked') }
                validation={ contactValidation }
                globalIdentifier="contactDetails"
                title="Your details"
            />
        </>
    );
};

export default ContactDetails;