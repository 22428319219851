import isEmail from 'validator/lib/isEmail';

const fieldValidator = (field, fieldValue) => {
    let response = { valid: true, validationMessage: '' }
    fieldValue = String(fieldValue).toLowerCase();
    if (!isEmail(fieldValue)) {
        response.valid = false;
        response.validationMessage = field.label + ' must be valid email address';
    }
    return response;
}

export default fieldValidator;