import React, { useState, useEffect, useReducer, useContext } from 'react';
import UserContext from 'context/user';
import { api } from 'helper/api/client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'scss/component/toast/toast.scss';
import RouteHeader from 'components/partial/RouteHeader';
import permission from 'helper/permission';
import { addressFields } from 'helper/address';

import log from 'helper/log';

import crudReducer from 'helper/crudReducer';
import { modalReducer, modalInitialState } from 'helper/modalReducer';

import Modal from 'portal/Modal';

import Box from 'components/ui/Box';
import AddressRow from 'components/partial/my-account/AddressRow';

import DeliveryAddressEdit from 'components/partial/my-account/DeliveryAddressEdit';
import AddressAddEdit from 'components/partial/my-account/AddressAddEdit';

import 'scss/component/addresses/addresses.scss';
import toastProps from 'helper/toast';
import Button from 'components/ui/Button';
import FaqsBox from 'components/partial/FaqsBox';
import global from 'helper/global';

const ManageAddresses = (props) => {
    const { user } = useContext(UserContext);
    const billingAddress = user.billingAddress;
    const invoiceAddress = user.invoiceAddress;
    const manageAddress = permission.check('address.viewDelivery', false);
    const [autoOpenModal, setAutoOpenModal] = useState(false);
    const [addresses, dispatchAddresses] = useReducer(crudReducer, []);
    const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (manageAddress) {
            api.get('delivery-address/list').then(response => {
                dispatchAddresses({ type: 'set', records: response.data.response.addresses });
                setLoading(false);
                api.setCache(response, 1);
            }).catch(error => {
                api.error(error);
            });
        }
    }, [manageAddress]);

    const getAddressById = (id) => {
        return addresses.find((address, index) => parseInt(address.id) === parseInt(id));
    }
    const getAddress = (address) => {
        let addressLine = '';
        if (address[addressFields[0]]) {
            return (
                <>
                    { addressFields.map((addressField) => {
                        addressLine = (typeof address[addressField] !== 'undefined') ? String(address[addressField]).trim() : '';
                        if (addressLine.length) {
                            return <span className="addresses-current__column-address-line" key={ addressField }>{ addressLine }<br /></span>;
                        }
                        return null;
                    })
                    }
                </>
            )
        }
        return <>No address specified</>;
    }
    const deleteSuccess = (response, apiData) => {
        dispatchAddresses({ type: 'delete', id: apiData.id });
        log.action('Address management', 'Deleted', 'address_management');
        api.cacheClear('delivery-address/list');
        toast.success('Address removed');
    }
    const editAddressName = (event) => {
        dispatchModal({
            type: 'open',
            title: 'Update address',
            content: <DeliveryAddressEdit id={ event.currentTarget.dataset.id } dispatchModal={ dispatchModal } dispatchAddresses={ dispatchAddresses } addresses={ addresses } address={ getAddressById(event.currentTarget.dataset.id) }/>
        });
    }
    const editBillingAddress = (event) => {
        dispatchModal({
            type: 'open',
            title: 'Edit billing address',
            content: <AddressAddEdit type="billing" address={ billingAddress } dispatchModal={ dispatchModal } />
        });
    }
    const editInvoiceAddress = (event) => {
        dispatchModal({
            type: 'open',
            title: 'Edit invoice address',
            content: <AddressAddEdit type="invoice" address={ invoiceAddress } dispatchModal={ dispatchModal } />
        });
    }
    if (!autoOpenModal && (global.formSuccess === 'invoiceUpdated' || global.formSuccess === 'billingUpdated')) {
        setAutoOpenModal(true);
        global.formSuccess === 'billingUpdated' ? editBillingAddress() : editInvoiceAddress();
    }
    const buttonProps = { simple: true, small: true };
    return (
        <>
            <ToastContainer { ...toastProps } />
            <RouteHeader route={ props.route } />
            <Box padding="large">
                <div className={ 'addresses-current' + (!manageAddress && permission.check('address.viewBilling') ? ' addresses-current--three' : '') }>
                    { permission.check('address.viewBilling') &&
                    <div className="addresses-current__column">
                        <h4>Billing/payment address</h4>
                        <address className="addresses-current__column-address">
                            { getAddress(billingAddress) }
                        </address>
                        { permission.check('address.editBilling', true) && !permission.check('isCustomerLocked') && <Button { ...buttonProps } fullClassName="addresses-current__column-link" action={ editBillingAddress }>Edit billing/payment address</Button> }
                    </div> }
                    { !manageAddress &&
                    <div className="addresses-current__column">
                        <h4>Delivery address</h4>
                        <address className="addresses-current__column-address">
                            { getAddress(user.deliveryAddress) }
                        </address>
                    </div> }
                    <div className="addresses-current__column">
                        <h4>Invoice address</h4>
                        <address className="addresses-current__column-address">
                            { getAddress(invoiceAddress) }
                        </address>
                        { permission.check('address.editInvoice', true) && !permission.check('isCustomerLocked') && <Button { ...buttonProps } fullClassName="addresses-current__column-link" action={ editInvoiceAddress }>Edit invoice address</Button> }
                    </div>
                </div>
            </Box>
            { manageAddress &&
                <Box padding="large" loading={ loading }>
                    { !loading && <>
                        <h4>Delivery addresses</h4>
                        { addresses.length === 0 && <p>There are no delivery addresses</p> }
                        { addresses.map((address, index) => {
                            return (
                            <AddressRow key={ address.id }
                                        address={ address }
                                        modal={ modal }
                                        dispatchModal={ dispatchModal }
                                        editAddressName={ editAddressName }
                                        deleteSuccess={ deleteSuccess }
                            />
                            )
                        }) }
                    </>
                    }
                </Box>
            }
            { (!manageAddress || !loading) && <FaqsBox settingName="addressPage" defaultFaqs={ [77022, 5003077] } /> }
            <Modal { ...modal } dispatchModal={ dispatchModal }>{ modal.content }</Modal>
        </>
    );
};

export default ManageAddresses;