import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import InfoBox from 'components/ui/InfoBox';
import content from 'helper/content';
import permission from 'helper/permission';
import ContactLocked from 'components/ui/ContactLocked';

const ContactAddEditForm = (props) => {
    return (
        <>
            <Form
                { ...props }
                loading={ props.mainContact && permission.check('isCustomerLocked') }
            >
                { props.mainContact && <ContactLocked /> }
                { props.title && <h3 className="form__title">{ props.title }</h3> }
                <div className="form-info-box-row">
                    <div className="form-info-box-row__fields">
                        <div className="form__fields-row">
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['title'] }/>
                            </div>
                        </div>
                        <div className="form__fields-row">
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['firstName'] }/>
                            </div>
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['surname'] }/>
                            </div>
                        </div>
                        <FieldSet fields={ ['email'] }/>
                        <div className="form__fields-row">
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['phonePreference'] }/>
                            </div>
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['mobileNumber'] }/>
                            </div>
                        </div>
                        <div className="form__fields-row">
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['phoneNumber'] }/>
                            </div>
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['phoneNumber2'] }/>
                            </div>
                        </div>
                    </div>
                    { props.mainContact &&
                    <InfoBox className="text-small">
                        { content.html('contactDetails.info', '<p>Editing your main contact will not change your Account name. If you wish to do this please <a href="/help-support/contact">contact us</a>.</p>') }
                    </InfoBox> }
                </div>
            </Form>
        </>
    );
}

export default ContactAddEditForm;