import RouteHeader from 'components/partial/RouteHeader';
import EbillingSignupForm from './EbillingSignupForm';

const EbillingSignup = (props) => {
    return (
        <>
            <RouteHeader route={ props.route }/>
            <EbillingSignupForm />
        </>
    );
};

export default EbillingSignup;