import { getLastGaugeReadingDate } from 'helper/overview';

const OverviewTankRemainingNoData = ({ data }) => {
    return (
        <>
            <h3 className="overview-panel-small__title">Last known tank level</h3>
            <div className="overview-panel-small__body">
                <p className="overview-panel-small__body-percentage">{ data.lastGaugeReading }%</p>
                <p className="overview-panel-small__body-percentage-text">Based on your last reading { getLastGaugeReadingDate(data, true) }</p>
            </div>
        </>
    )
}
export default OverviewTankRemainingNoData;