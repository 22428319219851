import Form from 'components/form/Form';
import content from 'helper/content';
import { registerValidation } from 'helper/form/changePassword';
import { api } from 'helper/api/client';
import FieldSet from 'components/form/FieldSet';
import InfoBox from 'components/ui/InfoBox';
import React from 'react';

const RegisterStagePassword = (props) => {
    let formFields = {
        password: { label: 'Password', required: true, type: 'PasswordStrength', value: process.env.REACT_APP_TEST_PASSWORD, emailField: 'accountEmail' },
        passwordConfirm: { label: 'Confirm password', required: true, type: 'PasswordStrength', value: process.env.REACT_APP_TEST_PASSWORD, passwordField: 'password', emailField: 'accountEmail' },
        accountEmail: {label: 'Email address', required: true, type: 'Email', value: (props.formData.email ? props.formData.email : process.env.REACT_APP_REGISTER_EMAIL) },
        phoneNumber: { label: 'Phone number', required: true, type: 'Text', validator: 'Phone', maxLength: 20 },
    };
    if (props.formData.hasPhoneNumber) {
        delete formFields.phoneNumber;
    }
    if (props.addAccount) {
        delete formFields.password;
        delete formFields.passwordConfirm;
    }
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess, setErrorMessage, enableForm, setInvalidFields } = form;
        if (props.formData.email === '' || props.formData.email === null) {
            props.formData.email = fieldValues.accountEmail;
        }
        api.post('customer/' + (props.addAccount ? 'add' : 'register'), { ...props.formData, ...fieldValues })
            .then(response => {
                if (response.data.errors && response.data.errors.failedValidateRegistrationDuplicate) {
                    setErrorMessage(content.html('register.emailExists', 'Your email is already registered, please use forgotten password to <a href="/forgotten-password">reset your password</a> or login with this email address.'));
                    enableForm();
                    setInvalidFields({ accountEmail: '' });
                } else if (api.success(response, form)) {
                    logActionSuccess();
                    props.setCreatedUser({ token: response.data.response.token, user: response.data.response.customer, legacyOnly: response.data.response.legacyOnly });
                    props.setFormStage('complete');
                }
            }).catch(error => {
                api.error(error, form, 'Invalid information submitted, please try again');
        });
    };
    return (
        <Form formFields={ formFields }
              visible={ props.visible }
              eventTitle="Register complete"
              logActionSuccess={ false }
              valid={ validHandler }
              validation={ registerValidation }
              submitAlign="right"
              submitOutline={ props.addAccount }
              submitColour={ props.addAccount ? 'blue' : 'purple' }
              submitName="Complete activation">

            <div className="form__autocomplete">
                <label>Email address <input name="email" type="text" /></label>
                <input name="password" type="password"  />
            </div>
            { props.addAccount && content.html('register.verifiedAddAccount', '<p><strong>Success!</strong><br>We have verified your account. Please set an email address to complete this process.</p>') }
            { !props.addAccount && content.html('register.verifiedNoEmail', '<p><strong>Success!</strong><br>We have verified your account. Please set an email and safe password to complete your activation.</p>') }
            <FieldSet fields={ ['accountEmail'] }/>
            <FieldSet fields={ ['phoneNumber'] }/>
            { !props.addAccount && <>
            <InfoBox className="text-small">
                { content.html('register.newPassword', '<p>Passwords must be at least 12 characters, contain at least one letter, one uppercase letter, one number and one symbol. Passwords are case sensitive.</p>') }
            </InfoBox>
            <FieldSet fields={ ['password', 'passwordConfirm'] }/>
            </> }
        </Form>
    );
};

export default RegisterStagePassword;