import React from 'react';
import Button from 'components/ui/Button';
import permission from 'helper/permission';

const OverviewReturnGasBottles = ({ data }) => {
    return (
        <>
            <h3 className="overview-panel-small__title">Return gas bottles</h3>
            <div className="overview-panel-small__body">
                <p className="overview-panel-small__body-large">Return your gas bottles for free with Flogas</p>
                <p className="overview-panel-small__body-small">Gas bottles are property of Flogas and must be returned once empty.</p>
            </div>
            <div className="overview-panel-small__footer">
                { permission.validRoute('/orders/return-gas-bottles') && <Button to="/orders/return-gas-bottles" simple>Return gas bottles</Button> }
                { !permission.validRoute('/orders/return-gas-bottles') && <Button href="https://www.flogas.co.uk/gas-bottles/returns" simple>Return gas bottles</Button> }
            </div>
        </>
    );
}

export default OverviewReturnGasBottles;