import React, { useContext } from 'react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import TagManager from 'react-gtm-module'

import 'scss/index.scss';
import 'scss/component/print/print.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/ui/Layout';
import LiveChat from 'components/ui/LiveChat';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import { ReactComponent as Logo } from 'images/logo.svg';
import Loading from 'components/ui/Loading';
import ConfigContext, { isMaintenanceMode, getMaintenanceMessage } from 'context/config';
import content from 'helper/content';
import { getHtml } from 'helper/html';
import debug from 'helper/debug';

if (process.env.REACT_APP_UA_ID) {
    ReactGA.initialize(process.env.REACT_APP_UA_ID);
}
if (process.env.REACT_APP_GA4_ID) {
    ReactGA4.initialize(process.env.REACT_APP_GA4_ID);
}
if (process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID })
}

const App = () => {
    const userContext = useContext(UserContext);
    const authContext = useContext(AuthContext);
    const configContext = useContext(ConfigContext);
    const authorised = authContext.authenticated && !userContext.user.isDefault;
    const showSplashScreen = () => {
        if (userContext.switching) {
            return true;
        }
        // App is loaded and can be displayed, routes are > 1 (not just the login route) and the user is logged in and set or not logged in
        if (authContext.csrf && !configContext.failedToLoad && configContext.routeConfig.length > 1 && (authorised || !authContext.authenticated)) {
            return false;
        }
        return true;
    }
    const getSplashScreenMessage = () => {
        if (configContext.failedToLoad === true) {
            debug.error('Development mode error: Failed to load message/routes');
            return <p className="splash-screen__message">{ content.text('failedToLoad', 'Failed to load, please try again later') }</p>
        }
        return null;
    }
    const splashScreenMessage = getSplashScreenMessage();
    const splashScreen = (
        <div className={ 'splash-screen' + (!showSplashScreen() ? ' splash-screen--loaded' : '') }>
            <div className="splash-screen__inner">
                <div className="splash-screen__inner-logo"><Logo/></div>
                { !splashScreenMessage && <Loading size="40" strokeSize="3"/> }
                { splashScreenMessage }
                { userContext.switching && <div className="splash-screen__message"><p>Switching account</p></div> }
            </div>
        </div>
    );
    if (isMaintenanceMode(configContext.messages)) {
        const message = getMaintenanceMessage(configContext.messages);
        return (
            <div className="splash-screen splash-screen--maintenance">
                <div className="splash-screen__inner">
                    <div className="splash-screen__inner-logo"><Logo/></div>
                    <div className="splash-screen__inner-message">
                        <h3>{ message.title }</h3>
                        { getHtml(message.text) }
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            { splashScreen }
            { !showSplashScreen() &&
                <>
                    <Router>
                        <Layout />
                    </Router>
                    <LiveChat authorised={ authorised } />
                </>
            }
        </>
    );
}
export default App;