import Button from 'components/ui/Button';
import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';
import OverviewPanelFooter from '../structure/OverviewPanelFooter';
import OverviewTankUsage from 'components/partial/overview/panel/generic/OverviewTankUsage';
import OverviewTankGaugeForm from 'components/partial/overview/panel/generic/OverviewTankGaugeForm';
import OverviewLorry from '../generic/OverviewLorry';
import { hasGaugeReading } from 'helper/overview';
import permission from '../../../../../helper/permission';

const modifierClass = 'top-up';

const OverviewOnCall = ({ data, setData }) => {
    let title = 'Your order';
    if (!data.hasOrder) {
        if (hasGaugeReading(data)) {
            if (data.hasOrder) {
                title = 'Your usage';
            } else {
                if (permission.check('isOnStop')) {
                    title = 'Your account is currently on hold';
                } else {
                    title = 'Place an order';
                }
            }
        } else {
            title = 'Submit tank gauge reading';
        }
    }
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle title={ title } />
                <OverviewPanelBody>
                    { hasGaugeReading(data) && <>
                        { data.hasOrder ? <OverviewLorry data={ data } /> : <OverviewTankUsage data={ data } /> }
                    </> }
                    { !hasGaugeReading(data) && <OverviewTankGaugeForm data={ data } setData={ setData } /> }
                </OverviewPanelBody>
                <OverviewPanelFooter>
                    <div className="overview-panel__buttons">
                        { permission.check('isOnStop') && <>
                            <Button simple to="/payments/make-payment">Make a payment</Button>
                            <Button simple to="/help-support/contact">Contact us</Button>
                        </> }
                        { !permission.check('isOnStop') && <>
                            { (!data.hasOrder && hasGaugeReading(data)) && <Button simple to="/orders/place-order-bulk">Orders and deliveries</Button> }
                            { (!data.hasOrder && !hasGaugeReading(data)) && <Button simple to="/help-support/faqs?faq=how-do-i-read-my-gauge">How do I read my tank gauge?</Button> }
                        </> }
                    </div>
                </OverviewPanelFooter>
            </OverviewPanel>
        </>
    );
}
export default OverviewOnCall;