import Text from 'components/form/field/Text';

const Currency = (props) => {
    const keyUp = (event) => {
        let value = event.target.value.replace(',', '');
        if (isNaN(value)) {
            value = '';
        } else {
            const values = value.toString().split('.');
            if (values[1] && values[1].length > 2) {
                values[1] = values[1].substr(0, 2);
            }
            value = values.join('.');
        }
        if (value !== event.target.value) {
            props.setFieldValues({...props.fieldValues, [props.name]: value});
        }
    }
    return <>
        <span className="form__field-before">£</span>
        <Text { ...props } onKeyUp={ keyUp } allowedAttributes={ ['onKeyUp'] } />
    </>
};

export default Currency;