import React, { useEffect, useRef, useContext } from 'react';
import TabsContext from 'context/tabs';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { getClassName } from 'helper/bem';
import { getStyle, getActiveTabByHash, getActiveTabPath } from 'helper/tabs';

const Tabs = (props) => {
    const location = useLocation();
    const history = useHistory();
    const tabsContext = useContext(TabsContext);
    const pathArray = location.pathname.substr(1).split('/');
    const basePath = '/' + pathArray[0];
    const activeTab = (props.nav ? getActiveTabPath(pathArray, props.tabs) : getActiveTabByHash(location, props.tabs));

    const tabsNavElement = useRef(null);
    const tabsNavIndicator = useRef(null);

    const initialStyle = (tabsContext.tabs[basePath] ? tabsContext.tabs[basePath] : {});

    useEffect(() => {
        // Prevent the line showing in the wrong position when font is loading
        setTimeout(() => {
            const newStyle = getStyle(tabsNavElement, 'tabs-nav__tab');
            if (tabsNavIndicator.current && newStyle.left !== initialStyle.left) {
                tabsNavIndicator.current.style.width = newStyle.width;
                tabsNavIndicator.current.style.left = newStyle.left;
                let newLeft = parseInt(newStyle.left.replace('px', ''));
                if (newLeft + parseInt(newStyle.width.replace('px', '')) > tabsNavElement.current.offsetWidth) {
                    tabsNavElement.current.scrollLeft = newLeft;
                }
                tabsContext.setTabs({...tabsContext.tabs, [basePath]: newStyle});
            }
        }, 50);
    }, [activeTab, basePath, tabsContext, initialStyle.left]);

    const setTab = (event) => {
        let newTab = event.currentTarget.dataset.tab;
        tabsContext.setTabs({...tabsContext.tabs, [basePath]: getStyle(tabsNavElement, 'tabs-nav__tab')});
        history.push((props.nav ? basePath + '/' + newTab : { hash: newTab }));
    }
    const getContent = () => {
        return (
            <div className="tabs-content">
                { Object.entries(props.tabs).map(([index, tab]) => {
                    const Component = () => {
                        if (tab.component) {
                            return React.createElement(require('components/partial/' + tab.component).default);
                        }
                        return null;
                    };
                    return <div key={ index } className={ 'tabs-content__tab' + (activeTab === index ? ' tabs-content__tab--active' : '') }>
                        { tab.content }
                        { tab.component && activeTab === index ? <Component /> : '' }
                    </div>;
                }) }
            </div>
        );
    }

    return (
        <div className={ getClassName('tabs', (props.nav ? 'nav' : '')) }>
            <ul className="tabs-nav" ref={ tabsNavElement } id="tabs-nav">
                <li className="tabs-nav__tab-indicator" ref={ tabsNavIndicator } style={ initialStyle }></li>
                { Object.entries(props.tabs).map(([index, tab]) => {
                    return <li key={ index } data-tab={ index } className={ 'tabs-nav__tab' + (activeTab === index ? ' tabs-nav__tab--active' : '') }><button className="tabs-nav__tab-button" onClick={ setTab } data-tab={ index }>{ tab.title }</button></li>
                }) }
            </ul>
            { !props.nav && getContent() }
        </div>
    );
}

Tabs.defaultProps = {
    nav: false,
}
Tabs.propTypes = {
    tabs: PropTypes.object.isRequired,
    className: PropTypes.string,
}

export default Tabs;