//import { Link } from 'react-router-dom';
import { ReactComponent as CarbonTrust } from 'images/carbon-trust.svg';

const Footer = () => {
    const today = new Date();
    return (
        <footer className="footer">
            <div className="footer__inner">
                <p className="footer__inner-copy">
                    <CarbonTrust />
                    © Flogas { today.getFullYear() }. All rights reserved. Company Registration No. 993638 | VAT No. GB164494584. Flogas Britain Ltd. A DCC company. Registration Number 993638. Rayns Way, Watermead Business Park,
                    Syston, Leicestershire. LE71PF.
                </p>
            </div>
        </footer>
    )
}

export default Footer;