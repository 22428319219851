import React, { useContext, Suspense, lazy } from 'react';
import { Redirect, Route, useLocation, Switch } from 'react-router-dom';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import ConfigContext from 'context/config';
import Loading from 'components/view/Loading';
import NotFound from 'components/view/NotFound';
import log from 'helper/log';
import { getRoutes } from 'helper/customer';
import global from 'helper/global';
import LegacyIframe from 'components/partial/LegacyIframe';

const loginPath = 'auth';

const Routes = () => {
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const { user } = useContext(UserContext);
    const { routeConfig } = useContext(ConfigContext);
    global.setCanUpdateCustomer(true);

    const isValidRoute = (route) => {
        if (authContext.authenticated && user.limited !== null && route.path === 'limited') {
            return true;
        } else if (authContext.authenticated) {
            if (route.redirectUnauthenticated) {
                return false;
            }
        } else {
            if (route.requiresAuthentication) {
                return false;
            }
        }
        return true;
    };
    return (
        <Suspense fallback={ <Loading/> }>
            <Switch location={ location }>
                <Route exact={ true } path="/" render={ () => {
                    return <Redirect to={'/' + loginPath} />
                } } />
                {
                    getRoutes(routeConfig).map((route, index) => {
                        let exact = !route.path.includes(':');
                        if (route.component) {
                            return <Route
                                key={ index }
                                path={ '/' + route.path }
                                exact={ exact }
                                render={ () => {
                                    if (isValidRoute(route)) {
                                        log.page();
                                        if (route.legacyPath) {
                                            return (
                                                <>
                                                    <LegacyIframe route={ route }/>
                                                </>
                                            )
                                        }
                                        const Component = lazy(() => {
                                            return import('components/' + (route.component.includes('/') ? 'partial' : 'view') + '/' + route.component + '.js')
                                        });
                                        return (
                                            <>
                                                <Component route={ route }/>
                                            </>
                                        );
                                    } else {
                                        return <Redirect to={ '/' + (authContext.authenticated ? (user.limited !== null ? 'limited' : 'overview') : loginPath) }/>
                                    }
                                } }
                            />
                        }
                        return '';
                    })
                }
                <Route match="*" render={ () => {
                    if (authContext.authenticated) {
                        if (user.limited === null) {
                            log.page()
                            return <NotFound/>
                        } else {
                            return <Redirect to={'/limited' } />
                        }
                    } else {
                        return <Redirect to={'/' + loginPath } />
                    }
                } } />
            </Switch>
        </Suspense>
    )
}

export default Routes;