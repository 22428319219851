import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';
import OverviewPanelFooter from 'components/partial/overview/panel/structure/OverviewPanelFooter';
import Button from 'components/ui/Button';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import content from 'helper/content';
import { api } from 'helper/api/client';
import moment from 'moment';

const modifierClass = 'metered';

const OverviewMeteredEstate = ({ data }) => {
    const formFields = {
        reading: { label: 'Meter reading', type: 'Number', required: true, minLength: 5, placeholder: 'e.g. ' + ((data.minMeterReading || data.lastMeterReading) ? Math.max(data.minMeterReading, data.lastMeterReading + 1) : '1234'), min: data.minMeterReading, max: data.maxMeterReading },
        date: { label: 'Date of reading', type: 'Date', required: true, value: moment().format('DD/MM/YYYY') },
    };
    const validHandler = (form) => {
        const { fieldValues, setSubmittedMessage, enableForm, logActionSuccess } = form;
        api.post('overview/metered-reading', fieldValues).then(response => {
            if (api.success(response, form)) {
                logActionSuccess();
                setSubmittedMessage(content.html('overview.meterSubmitted', '<p>Your meter reading has been submitted.</p>'));
            }
        }).catch(error => {
            enableForm();
            api.error(error, form, 'Could not submit meter reading.');
        });
    }
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle title="Submit a meter reading"/>
                <OverviewPanelBody>
                    { !data.canSubmitMeterReading && content.html('overview.meterCantSubmit', '<p>We\'re just processing your latest meter reading. Please check back later.</p>') }
                    { data.canSubmitMeterReading &&
                    <Form formFields={ formFields }
                          valid={ validHandler }
                          submitOutline={ false }
                          eventTitle="Tank gauge submission overview">
                        { content.html('overview.meterIntro', '<p>Sending us a meter reading once a month ensures you never pay for more gas than you use. Enter your reading below.</p>') }
                        <div className="form__fields-row">
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['reading'] }/>
                            </div>
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ ['date'] }/>
                            </div>
                        </div>
                        { data.lastMeterReading && data.lastMeterReadingDate &&
                            <p className="text-small">Last meter reading { data.lastMeterReading } { data.lastMeterReadingTypeFormatted ? '(' + data.lastMeterReadingTypeFormatted + ')' : ''} on { data.lastMeterReadingDate }</p>
                        }
                    </Form> }
                </OverviewPanelBody>
                <OverviewPanelFooter>
                    <div className="overview-panel__buttons">
                        <Button simple to="/help-support/faqs?faq=how-do-i-read-my-meter">How do I read my meter?</Button>
                    </div>
                </OverviewPanelFooter>
            </OverviewPanel>
        </>
    );
}
export default OverviewMeteredEstate;