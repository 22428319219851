import fieldValidatorPhone from 'helper/form/validation/Phone';
import fieldValidatorEmail from 'helper/form/validation/Email';
import fieldValidatorPostcode from 'helper/form/validation/Postcode';

export const optionalFields = ['email', 'phoneNumber', 'postcode'];

export const validateFormFields = {
    accountNumber: { label: 'Account number', type: 'Text', required: true, value: process.env.REACT_APP_TEST_ACCOUNT_NUMBER, validator: 'AccountNumber', placeholder: 'e.g. 1234567' },
    email: { label: 'Email', type: 'Email', value: process.env.REACT_APP_TEST_EMAIL, placeholder: 'e.g. john.smith@emailhost.co.uk' },
    phoneNumber: { label: 'Phone Number', type: 'Text', value: process.env.REACT_APP_TEST_PHONE_NUMBER, validator: 'Phone', placeholder: 'e.g. 01234 567891' },
    postcode: { label: 'Postcode', type: 'Text', value: process.env.REACT_APP_TEST_POSTCODE, validator: 'Postcode', placeholder: 'e.g. AB1 2CD' },
    //invoiceNumber: { label: 'Invoice Number', type: 'Text', value: process.env.REACT_APP_TEST_INVOICE_NUMBER, validator: 'InvoiceNumber', placeholder: 'e.g. 654321' },
};
export const invalidHandlerValidate = (form) => {
    const { setErrorMessage, enableForm, fieldValidation } = form;
    enableForm();
    if (fieldValidation.accountNumber.valid) {
        setErrorMessage('Please enter 2 of the 4 pieces of information');
    } else {
        setErrorMessage('');
    }
}

export const validationHandlerStep1 = (form) => {
    const { fieldValues } = form;
    let { validFields } = form;
    let filledCount = 0;
    for (const field of optionalFields) {
        if (fieldValues[field].length) {
            filledCount++;
        }
    }
    if (filledCount < 2) {
        validFields.email = fieldValidatorEmail(validateFormFields.email, fieldValues.email);
        validFields.phoneNumber = fieldValidatorPhone(validateFormFields.phoneNumber, fieldValues.phoneNumber);
        validFields.postcode = fieldValidatorPostcode(validateFormFields.postcode, fieldValues.postcode);
    }
    return validFields;
};