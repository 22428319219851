export const passwordValidation = (form) => {
    const { fieldValues, setErrorMessage } = form;
    let { validFields } = form;
    if (typeof fieldValues.currentPassword !== 'undefined' && fieldValues.currentPassword === fieldValues.newPassword) {
        validFields.newPassword.valid = false;
        validFields.newPasswordConfirm.valid = false;
        setErrorMessage('New password must be different to your current password');
    } else if (fieldValues.newPassword !== fieldValues.newPasswordConfirm) {
        validFields.newPassword.valid = false;
        validFields.newPasswordConfirm.valid = false;
        setErrorMessage('Passwords do not match');
    }
    return validFields;
};
export const registerValidation = (form) => {
    const { fieldValues, setErrorMessage } = form;
    let { validFields } = form;
    if (fieldValues.password !== fieldValues.passwordConfirm) {
        validFields.password.valid = false;
        validFields.passwordConfirm.valid = false;
        setErrorMessage('Passwords do not match');
    }
    return validFields;
};