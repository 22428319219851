import FieldSet from 'components/form/FieldSet';
import FlogasForm from 'components/partial/FlogasForm';
import content from 'helper/content';
import permission from 'helper/permission';
import moment from 'moment';
import setting from 'helper/setting';

const MovingProperty = (props) => {
    const ownershipTypes = {
        owner: 'Owner',
        tenant: 'Tenant',
        landlord: 'Landlord',
    };
    const orderingProgramOptions = {
        leave: 'Leave Auto-ordering/Top Up until moving out date',
        remove: 'Remove Auto-ordering now',
    }
    const formFields = {
        ownership: { label: 'Are you the', required: true, type: 'Radios', inline: true, options: ownershipTypes, errorMessage: 'Please confirm ownership status' },

        endDate: { label: 'End date', required: true, type: 'Date', minDate: moment().subtract(60, 'd').toDate(), afterLabel: 'We will use this date as the final date you’re responsible for the property.' },

        forwardingAddress: { label: 'Forwarding address', required: true, type: 'AddressLookup', targetFields: ['forwardingAddress1', 'forwardingAddress2', 'forwardingTown', 'forwardingCounty', 'forwardingPostcode'] },
        forwardingAddress1: { label: 'Forwarding address line 1', required: true, type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        forwardingAddress2: { label: 'Forwarding address line 2', type: 'Text', validator: 'CodasString', maxLength: 50 },
        forwardingTown: { label: 'Forwarding address town/city', required: true, type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        forwardingCounty: { label: 'Forwarding address county', required: false,  type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        forwardingPostcode: { label: 'Forwarding address postcode', required: true, type: 'Text', validator: 'Postcode', maxLength: 12 },

        serialNumber: { label: 'Meter serial number', required: true, type: 'Text' },
        meterReading: { label: 'Final meter reading', required: true, type: 'Text' },
        orderingProgram: { label: 'Ordering program', type: 'Radios', options: orderingProgramOptions, inline: true, afterLabel: 'If you\'re currently on our Auto-ordering (Top Up) programme, would you like to remove this and order your own gas? NOTE - we recommend you order gas at around ' + setting.get('overview.bulkWarningThreshold', 40) + '% to allow time for delivery.' },

        newOwner: { label: 'New owner name/contact details (if known)', type: 'Textarea' },
        notes: { label: 'Additional notes', type: 'Textarea', placeholder: 'Please give as much detail as possible...' }
    };
    let formSlug = 'moving-property-domestic';
    if (permission.check('isBulk')) {
        if (!permission.check('isOnCall')) {
            delete formFields.orderingProgram;
        }
    }
    if (permission.check('isMetered')) {
        delete formFields.orderingProgram;
        formSlug = 'moving-property-metered';
    } else {
        delete formFields.serialNumber;
        delete formFields.meterReading;
    }
    return (
        <FlogasForm formFields={ formFields } form={formSlug} eventTitle="Moving property" title="Moving property">
            { content.html('movingProperty.intro', '<p>If you’re moving out of a property supplied by Flogas, there’s a few details we’ll need to know to help you wave a fond farewell!</p>') }
            <div className="form-info-box-row">
                <div className="form-info-box-row__fields">
                    <FieldSet fields={ ['ownership', 'endDate', 'orderingProgram', 'serialNumber', 'meterReading', 'forwardingAddress', 'newOwner', 'notes'] }/>
                    <FieldSet fields={ ['confirm'] }/>
                </div>
                {/*<InfoBox className="text-small">
                    { content.html('movingProperty.info', '<p>We only use your data to respond to your enquiry, for account matters (if you are a customer) and for our legitimate purposes of telling you news about Flogas products and services. We will not share your data (except for legal purposes). Find out more in our <a href="/help-support/page/privacy-policy">privacy policy</a> section.</p>' +
                        '<p>If you do not wish to for your information to be used to hear from us about Flogas products and services, <a href="/my-account#marketing-preferences">update these preferences online now</a>.</p>') }
                </InfoBox>*/}
            </div>
            <input type="submit" />
        </FlogasForm>
    );
};

export default MovingProperty;