import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';
import { api, axios } from 'helper/api/client';
import format from 'helper/format';
import Table from 'components/table/Table';
import Button from 'components/ui/Button';
import MockDataElement from 'components/ui/MockDataElement';
import FaqsBox from 'components/partial/FaqsBox';
import UserContext from 'context/user';
import BalancePendingMessage from 'components/ui/BalancePendingMessage';

const PaymentConfirmation = (props) => {
    const { setBalance } = useContext(UserContext);
    const { id } = useParams();
    const [payment, setPayment] = useState(null);
    useEffect(() => {
        let source = axios.CancelToken.source();
        setTimeout(() => {
            api.get('payment/item/' + id).then(response => {
                if (api.success(response)) {
                    setPayment(response.data.response.payment);
                    setBalance(response.data.response.balance);
                    // Clear the cache so that the updated outstanding transactions would show if the customer goes back to it
                    api.cacheClear('payment/outstanding');
                    api.cacheClear('transactions/list');
                    api.setCache(response);
                } else {
                    setPayment(false);
                }
                }).catch(error => {
                    api.error(error);
            });
        }, 500);
        // Will cancel AJAX on unmount
        return () => source.cancel();
    }, [id, setBalance]);

    const theadConfig = {
        reference: { className: '', content: 'Reference' },
        amount: { className: 'amount', content: 'Amount' },
    };
    if (payment && payment.type === 'balance') {
        delete theadConfig.reference;
    }
    let tableData = [];
    let tableFoot = [];
    if (payment === false) {
        return (
            <>
                <RouteHeader route={ props.route }/>
                <Box padding="large">
                    <h3>Payment error</h3>
                    <p>The payment you requested could not found</p>
                </Box>
            </>
        );
    } else {
        if (payment && payment.type === 'outstanding_transactions') {
            tableData = payment.items.map((paymentItem) => {
                return {
                    reference: paymentItem.reference,
                    amount: format.currency(paymentItem.amount),
                }
            });
            tableFoot = [
                [
                    { className: 'total label', value: 'Total' },
                    { className: 'total amount', value: format.currency(payment.amount) },
                ]
            ];
        }
        return (
            <>
                <RouteHeader route={ props.route }/>
                <Box padding="large">
                    <div className="box__header">
                        <h3>Thank you for your <MockDataElement data={ payment } item="name"/></h3>
                    </div>
                    <BalancePendingMessage />
                    <div className="box__info">
                        <div className="box__info-item"><strong>Reference</strong> <MockDataElement data={ payment } item="reference"/></div>
                        <div className="box__info-item"><strong>Payment date</strong> <MockDataElement data={ payment } item="date"/></div>
                        <div className="box__info-item"><strong>Payment type</strong> <MockDataElement data={ payment } item="typeTitle"/></div>
                        { payment && payment.type !== 'outstanding_transactions' && <div className="box__info-item"><strong>Payment amount</strong> <MockDataElement data={ payment } item="amount" dataFormat="currency"/></div> }
                    </div>
                    { payment && payment.type === 'outstanding_transactions' &&
                    <Table
                        extraClass="transaction-item"
                        boxSize="large"
                        head={ theadConfig }
                        body={ tableData }
                        foot={ tableFoot }
                        loading={ !payment }
                    /> }
                    <div className="box__buttons">
                        <Button to="/payments/transactions" simple={ true } noPadding>View transactions</Button>
                    </div>
                </Box>
                <FaqsBox noPrint settingName="paymentConfirmationPage" defaultFaqs={ [77000, 117795] } />
            </>
        )
    }
};

export default PaymentConfirmation;