import { useContext } from 'react';
import RouteHeader from 'components/partial/RouteHeader';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import content from 'helper/content';
import { passwordValidation } from 'helper/form/changePassword';
import { api } from 'helper/api/client';
import InfoBox from 'components/ui/InfoBox';
import UserContext from 'context/user';

const ChangePassword = (props) => {
    const { user } = useContext(UserContext);
    const formFields = {
        currentPassword: { label: 'Current password', required: true, type: 'Password', validator: 'Text', minLength: 6, value: process.env.REACT_APP_TEST_PASSWORD }, // Does not use password validator due to old passwords just being 6 characters
        newPassword: { label: 'New password', required: true, type: 'PasswordStrength', minLength: 12, value: process.env.REACT_APP_TEST_PASSWORD, validatorExtra: { email: user.email } },
        newPasswordConfirm: { label: 'Confirm new password', required: true, type: 'PasswordStrength', minLength: 12, value: process.env.REACT_APP_TEST_PASSWORD, passwordField: 'newPassword', validatorExtra: { email: user.email } }
    };

    const validHandler = (form) => {
        const { fieldValues, setInvalidField, logActionSuccess, setSuccessMessage, resetForm } = form;
        const formValues = { currentPassword: fieldValues.currentPassword, newPassword: fieldValues.newPassword };
        api
            .post('customer/password/update', formValues)
            .then(response => {
                if (api.success(response, form)) {
                    setSuccessMessage('Password changed successfully');
                    resetForm();
                    logActionSuccess();
                }
            }).catch(error => {
            api.error(error, form);
            setInvalidField('currentPassword', 'Invalid current password, please try again');
        });
    };

    return (
        <>
            <RouteHeader route={ props.route }/>
            <Form formFields={ formFields }
                  eventTitle="Change password"
                  valid={ validHandler }
                  validation={ passwordValidation }
                  submitName="Change your password">
                <div className="form-info-box-row">
                    <div className="form-info-box-row__fields">
                        <FieldSet fields={ ['currentPassword', 'newPassword', 'newPasswordConfirm'] }/>
                    </div>
                    <InfoBox className="text-small">
                        { content.html('register.newPassword', '<p>Passwords must be at least 12 characters, contain at least one letter, one uppercase letter, one number, and one symbol. Passwords are case sensitive.</p>') }
                    </InfoBox>
                </div>
            </Form>
        </>
);
};

export default ChangePassword;