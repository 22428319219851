import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';

import content from 'helper/content';


const modifierClass = 'finish-dated';

const OverviewOnStop = ({ data }) => {
    const title = content.text('overview.onStopTitle', 'Your account is on financial stop');
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle className={ modifierClass } title={ title } />
                <OverviewPanelBody className={ modifierClass }>
                    { content.html('overview.onStopText', '<p>Text to be confirmed.</p>') }
                </OverviewPanelBody>
            </OverviewPanel>
        </>
    );
}
export default OverviewOnStop;