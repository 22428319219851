import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import RouteHeader from 'components/partial/RouteHeader';
import Hero from 'components/partial/Hero';
import Box from 'components/ui/Box';

import { api, axios } from 'helper/api/client';
import MockParagraphs from 'components/ui/MockParagraphs';
import NotFound from 'components/view/NotFound';

import 'scss/component/page/page.scss';

const Page = (props) => {
    const location = useLocation();
    const params = useParams();
    const pathArray = location.pathname.substr(1).split('/');
    const getSlugFromPath = () => {
        if (pathArray.indexOf('page') !== -1) {
            let newIndex = pathArray.indexOf('page') + 1;
            if (pathArray[newIndex]) {
                return pathArray[newIndex];
            }
        }
        return null;
    }
    const slug = (params.slug ? params.slug : getSlugFromPath());
    const fullPage = (pathArray.length === 2 ? true : false);
    const [page, setPage] = useState(null);
    useEffect(() => {
        let source = axios.CancelToken.source();
        api.get('page/item/' + slug)
            .then(response => {
                setPage(response.data.response);
                api.setCache(response);
            }).catch(error => {
            setPage(false);
            api.error(error);
        });
        // Will cancel AJAX on unmount
        return () => source.cancel();
    }, [slug]);
    if (page !== false) {
        return (
            <>
                <RouteHeader route={ props.route } />
                { fullPage && <Hero loading={ page === null } title={ (page ? page.title : '') }/> }
                <Box className="page">
                    { !fullPage && page && <h3>{ page.title }</h3> }
                    { page && <div className="page-content" dangerouslySetInnerHTML={ { __html: page.content } }></div> }
                    { !page && <MockParagraphs items={ 50 }/> }
                </Box>
            </>
        )
    }
    return <NotFound/>;
};

export default Page;