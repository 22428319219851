import React, { useContext } from 'react';
import AccountsContext from 'context/accounts';
import { api } from 'helper/api/client';
import { getRecordFields } from 'helper/form/fields';
import InfoBox from 'components/ui/InfoBox';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import content from 'helper/content';
import global from 'helper/global';

const AccountName = () => {
    const { accounts, setAccounts } = useContext(AccountsContext);
    const currentContact = accounts.filter(account => account.current)[0];
    const formFields = getRecordFields({
        alias: { label: '', showLabel: false, type: 'Text', minLength: 3, maxLength: 50, placeholder: 'e.g. Laundry room' }
    }, currentContact);
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess } = form;
        api.post('customer/accounts/update', { id: currentContact.id, ...fieldValues })
            .then(response => {
                if (api.success(response, form)) {
                    logActionSuccess();
                    global.formSuccess = 'accountName';
                    global.successMessage = 'Account name updated successfully';
                    setAccounts(accounts.map((account, accountIndex) => {
                        if (account.current) {
                            account.alias = fieldValues.alias;
                        }
                        return account;
                    }));
                }
            }).catch(error => {
            api.error(error, form, 'Failed to update account name');
        });
    };

    return (
        <>
            <Form formFields={ formFields }
                  eventTitle="Account name"
                  className="account-name"
                  globalIdentifier="accountName"
                  valid={ validHandler }
                  submitName="Save account name">
                <div className="form-info-box-row">
                    <div className="form-info-box-row__fields">
                        <h3 className="form__title">Account nickname (optional)</h3>
                        <FieldSet fields={ ['alias'] }/>
                        <InfoBox className="text-small account-name-first">
                            { content.html('accountName.info', '<p>You can give your account a nickname, which will be displayed in the account dropdown and can give an easy reference if switching between multiple accounts. You will still need your account number if you contact us.</p>') }
                        </InfoBox>
                        <input type="submit" />
                    </div>
                    <InfoBox className="text-small account-name-second">
                        { content.html('accountName.info', '<p>You can give your account a nickname, which will be displayed in the account dropdown and can give an easy reference if switching between multiple accounts. You will still need your account number if you contact us.</p>') }
                    </InfoBox>
                </div>
            </Form>
        </>
    );
};

export default AccountName;