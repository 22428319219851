import format from 'helper/format';

const MockDataElement = (props) => {
    const Element = (props.element ? props.element : 'div');
    let className = 'mock-data';
    if (props.randomise) {
        className += ' mock-data--randomise';
    }
    if (props.inline) {
        className += ' mock-data--inline';
    }
    if (props.round) {
        className += ' mock-data--round';
    }
    if (props.data && props.data !== null && typeof props.data[props.item] !== 'undefined') {
        if (props.dataFormat) {
            return format[props.dataFormat](props.data[props.item]);
        }
        return props.data[props.item];
    }
    return (
        <Element className={ className }><span className="mock-data__inner" style={ props.style }/></Element>
    );
}

export default MockDataElement;