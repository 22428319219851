import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import Revalidate from 'components/partial/auth/Revalidate';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import NotificationMessage from 'components/ui/NotificationMessage';

import { api } from 'helper/api/client';
import content from 'helper/content';
import queryString from 'query-string';
import NeedHelp from 'components/ui/NeedHelp';
import log from 'helper/log';

const Login = (props) => {
    const params = queryString.parse(window.location.search);
    const revalidateTokenDefault = {
        accountNumber: (params.accountNumber ? params.accountNumber : null),
        email: (params.email ? params.email : null),
        phoneNumber: (params.phoneNumber ? params.phoneNumber : null),
        postcode: (params.postcode ? params.postcode : null),
    }
    const authContext = useContext(AuthContext);
    const { setUser } = useContext(UserContext);
    const [revalidateCustomer, setRevalidateCustomer] = useState(revalidateTokenDefault); // For step shown when logging in and needing to re-validate details
    const [hasErrored, setHasErrored] = useState(false); // True once any error shown, displays help link
    const [mfaValues, setMfaValues] = useState(null); // Object of original login values if requires MFA
    const [revalidateToken, setRevalidateToken] = useState((params.revalidateToken ? params.revalidateToken : null));
    const [notificationVisible, setNotificationVisible] = useState(true); // To allow hiding the you have logged out message when moving to reauthenticate
    const rememberValue = (window.localStorage.getItem('accountReference') && window.localStorage.getItem('accountReference').length ? '1' : '0');
    const accountReference = (window.localStorage.getItem('accountReference') && window.localStorage.getItem('accountReference').length ? window.localStorage.getItem('accountReference') : (mfaValues ? mfaValues.accountReference : ''));
    const formFields = {
        accountReference: { label: 'Email or account number', type: 'Text', required: true, minLength: 5, placeholder: 'e.g. john.smith@emailhost.co.uk', value: accountReference }, // simon@evosite.co.uk
        password: { label: 'Password', type: 'Password', required: true, validator: 'Text', minLength: 6, maxLength: 100, value: (mfaValues ? mfaValues.password : process.env.REACT_APP_LOGIN_PASSWORD) },
        mfaCode: { label: 'MFA Code', type: 'Text', required: mfaValues !== null, validator: 'Text', minLength: 6, maxLength: 6 },
        remember: { label: 'Remember me on this device', type: 'Checkbox', required: false, value: rememberValue },
    };
    const validHandler = (form) => {
        const { fieldValues, setInvalidFields, setErrorMessage, enableForm } = form;
        api.get('sanctum/csrf-cookie').then(() => {
            api.post('customer/login', fieldValues).then(response => {
                if (api.success(response, form)) {
                    window.localStorage.setItem('accountReference', (fieldValues.remember === '1' ? String(fieldValues.accountReference) : ''));
                    if (typeof response.data.response.requiresMfa !== 'undefined' && response.data.response.requiresMfa === true) {
                        setMfaValues({ accountReference: fieldValues.accountReference, password: fieldValues.password });
                    } else if (typeof response.data.response.revalidate !== 'undefined') {
                        setNotificationVisible(false);
                        setRevalidateToken(response.data.response.token);
                        setRevalidateCustomer(response.data.response.revalidate);
                        log.action('Form submission successful', 'Login revalidate', 'form_submission_successful');
                    } else if (response.data.response.legacyOnly) {
                        api.legacyPost('login', { token: response.data.response.token }).then(response => {
                            window.location = process.env.REACT_APP_LEGACY_URL;
                            log.action('Form submission successful', 'Login legacy', 'form_submission_successful');
                        });
                    } else if (typeof response.data.response.customer !== 'undefined' && response.data.response.customer !== false) {
                        authContext.setToken(response.data.response.token);
                        log.setCustomer(response.data.response.customer);
                        log.action('Form submission successful', 'Login', 'form_submission_successful');
                        setUser(response.data.response.customer);
                        authContext.setAuthenticated(true);
                        window.localStorage.setItem('customerSector', (fieldValues.remember === '1' ? response.data.response.customer.sector : ''));
                    } else {
                        throw new Error('loginUnknownResponse');
                    }
                }
            }).catch(error => {
                enableForm();
                setHasErrored(true);
                setInvalidFields({
                    accountReference: '',
                    password: ''
                });
                setErrorMessage(error.message !== 'loginUnknownResponse' && error?.response?.data?.errors?.invalidLogin
                    ? content.html('login.error', 'We\'ve been unable to find your account. You may need to <a href="/forgotten-password">reset your password</a> for access.')
                    : content.html('login.serverError', 'We\'ve been unable to verify your details. Please call <a href="tel:0800 574 574">0800 574 574</a>.')
                );
            });
        });
    };
    const getLoginForm = () => {
        return (
            <>
            <Form formFields={ formFields }
                  className="login"
                  eventTitle="Login"
                  logActionSuccess={ false }
                  valid={ validHandler }
                  hasChanged={ true }
                  submitAlign="right"
                  submitOutline={ false }
                  submitColour="purple"
                  submitName="Sign in">
                <div className="form__autocomplete">
                    <label>Username <input name="accountNumber" type="text" /></label>
                    <input name="password" type="password"  />
                </div>
                { mfaValues === null && <FieldSet fields={ ['accountReference', 'password'] }/> }
                { mfaValues && <>
                    <FieldSet fields={ ['mfaCode'] } />
                    <NotificationMessage>Please enter the MFA code that has been emailed to you. It is valid for 15 minutes.</NotificationMessage>
                </> }
                <div className="form-row">
                    <FieldSet className="remember" fields={ ['remember'] }/>
                    <Link to="/forgotten-password" className="link link--blue">Forgot password?</Link>
                </div>
                <div className="form-row">
                    <NeedHelp visible={ hasErrored } />
                    <input type="submit" />
                </div>
            </Form>
        </>
        );
    }
    return (
        <>
            { notificationVisible && authContext.loggedOutReason !== null && <NotificationMessage>{
                (authContext.loggedOutReason === 'inactive' ?
                        content.text('loggedOut.inactive', 'Due to lack of activity, you have been automatically logged out of your account. Please sign in again to continue.')
                        : content.text('loggedOut.default', 'You have been signed out')
                ) }</NotificationMessage> }
            { revalidateCustomer.accountNumber === null && getLoginForm() }
            { revalidateCustomer.accountNumber !== null && <Revalidate customer={ revalidateCustomer } token={ revalidateToken }/> }
        </>
    );
};

export default Login;