import React, { useContext, useEffect, useRef, useState } from 'react';
import Box from 'components/ui/Box';
import UserContext from 'context/user';
import { getAddressLine, getAddress } from 'helper/address';
import Button from 'components/ui/Button';
import format from 'helper/format';
import log from 'helper/log';
import 'scss/component/pay/pay.scss';
import RealexHpp from 'global-payments/rxp-js';
import { api } from 'helper/api/client';
import Loading from 'components/ui/Loading';
import NotificationMessage from 'components/ui/NotificationMessage';
import { useParams } from 'react-router-dom';
import RouteHeader from 'components/partial/RouteHeader';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import { getRecordFields } from 'helper/form/fields';
import { getPhoneNumber } from 'helper/contact';
import MockData from 'components/ui/MockData';
import Icon from 'components/ui/Icon';
import global from 'helper/global';
import ReactGA from 'react-ga';

const Pay = (props) => {
    const { id } = useParams();
    const [iframeKey, setIframeKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [payment, setPayment] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [billingAddresses, setBillingAddresses] = useState([]);
    const [paymentError, setPaymentError] = useState(false);
    const paymentColumn = useRef(null);
    const paymentIframe = useRef(null);
    const loadingPanel = useRef(null);
    const { user } = useContext(UserContext);
    global.setCanUpdateCustomer(false);
    const record = {
        phoneNumber: (payment && payment.phoneNumber ? payment.phoneNumber : getPhoneNumber(user.contact)),
        addressType: (payment && payment.billingAddress1 ? (payment.contactId ? 'previous' : 'enter') : null),
        contactId: (payment && payment.contactId ? payment.contactId : null),
        address1: (payment && payment.billingAddress1 ? payment.billingAddress1 : ''),
        address2: (payment && payment.billingAddress2 ? payment.billingAddress2 : ''),
        town: (payment && payment.billingTown ? payment.billingTown : ''),
        county: (payment && payment.billingCounty ? payment.billingCounty : ''),
        postcode: (payment && payment.billingPostcode ? payment.billingPostcode : '')
    };
    const getAddresses = () => {
        let addresses = {};
        if (billingAddresses.length) {
            for (const billingAddress of billingAddresses) {
                addresses[billingAddress.id] = getAddressLine(billingAddress);
            }
        }
        return addresses;
    }
    const tryAgain = () => {
        setErrorMessage(null);
        setIframeKey(payment.attempt);
    }
    const formFields = getRecordFields({
        phoneNumber: { label: 'Phone number', required: true, type: 'Text', validator: 'Phone', placeholder: 'e.g. 071234 567890' },
        addressType: { label: 'Address type', showLabel: false, type: 'Radios', inline: true, required: true, options: { previous: 'Select a previous address', enter: 'Enter new address' } },
        contactId: { label: 'Select a previous used address...', required: true, type: 'Dropdown', options: getAddresses(), allTitle: 'Please select', visibleWhen: { addressType: 'previous' } },
        address: { label: '', showLabel: false, placeholder: 'Enter your address...', type: 'AddressLookup', addressFieldsVisible: true, visibleWhen: { addressType: 'enter' } },
        address1: { label: 'Address line 1', required: true, type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        address2: { label: 'Address line 2', type: 'Text', validator: 'CodasString', maxLength: 50 },
        town: { label: 'Town/city', required: true, type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        county: { label: 'County', required: false,  type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        postcode: { label: 'Postcode', required: true, type: 'Text', validator: 'Postcode', maxLength: 50 },
    }, record);
    if (billingAddresses.length === 0) {
        delete formFields.addressType;
        delete formFields.contactId;
    }

    useEffect(() => {
        if (!payment) {
            api.get('payment/item/' + id, { includeBillingAddresses: true }).then(response => {
                if (api.success(response)) {
                    if (response.data.response.payment.isPayable) {
                        setPayment(response.data.response.payment);
                        setBillingAddresses(response.data.response.billingAddresses);
                        setIframeKey(response.data.response.payment.attempt);
                    } else {
                        setPaymentError(true);
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    setPaymentError(true);
                }
            }).catch(error => {
                setLoading(false);
                setPaymentError(true);
                api.error(error);
            });
        }
    }, [payment, id, formFields]);

    useEffect(() => {
        if (payment && payment.hasDetails && iframeKey !== null) {
            setLoading(true);
            RealexHpp.setHppUrl('https://pay.' + (process.env.REACT_APP_PAYMENT_SANDBOX === 'false' ? '' : 'sandbox.') + 'realexpayments.com/pay');
            api.get('payment/process/' + id).then(response => {
                if (api.success(response)) {
                    setLoading(false);
                    RealexHpp.embedded.init(
                        'autoload',
                        'paymentIframe',
                        (response) => {
                            if (response.RESULT && response.RESULT === '00') {
                                if (process.env.REACT_APP_UA_ID) {
                                    ReactGA.plugin.require('ec');
                                    payment.items.forEach(function(item, itemId) {
                                        ReactGA.plugin.execute('ec', 'addProduct', {
                                            name: 'Payment ' + (item.reference ? 'of reference' : 'amount'),
                                            currency: 'GBP',
                                            price: item.amount,
                                            quantity: 1,
                                        });
                                    });
                                    ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
                                        id: id,
                                        revenue: payment.amount,
                                        shipping: 0,
                                        currency: 'GBP',
                                        tax: 0
                                    });
                                    ReactGA.ga('send', 'pageview');
                                }
                                if (process.env.REACT_APP_GA4_ID) {
                                    log.action('Payment', 'Payment', 'payment', { value: payment.amount });
                                }
                                // Timeout to give GA to send data before page redirect
                                window.setTimeout(() => {
                                    if (payment.type === 'order') {
                                        window.location = '/orders/place-order-bulk/' + payment.items[0].reference + '/confirmation';
                                    } else {
                                        window.location = '/payments/make-payment/confirmation/' + id;
                                    }
                                }, 1000);
                            } else {
                                setPayment({...payment, attempt: payment.attempt + 1 });
                                setIframeKey(null);
                                if (response.error) {
                                    setErrorMessage(response.message);
                                } else {
                                    setErrorMessage(response.MESSAGE);
                                }
                            }
                        },
                        response.data.response.hppJson,
                        {
                            onResize: (data) => {
                                if (paymentIframe.current) {
                                    paymentIframe.current.style.height = data.height;
                                }
                            }
                        }
                    );
                }
            }).catch(error => {
                setPaymentError(true);
                api.error(error);
            });
        }
    }, [payment, id, paymentIframe, iframeKey]);
    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                if (loadingPanel.current !== null) {
                    loadingPanel.current.style.display = 'none';
                }
            }, 200);
        }
    }, [loading]);
    const changeHandler = (event, fieldValues) => {
        if (event.target.name === 'addressType') {
            fieldValues.address1 = '';
            fieldValues.address2 = '';
            fieldValues.town = '';
            fieldValues.county = '';
            fieldValues.postcode = '';
            fieldValues.contactId = '';
            return fieldValues;
        } else if (event.target.name === 'contactId') {
            const newAddress = billingAddresses.filter(billingAddress => String(billingAddress.id) === fieldValues.contactId);
            if (newAddress.length) {
                if (!newAddress[0].town.length && newAddress[0].address2.length) {
                    newAddress[0].town = newAddress[0].address2;
                    newAddress[0].address2 = '';
                }
                const newFieldValues = { ...fieldValues, ...newAddress[0] };
                delete newFieldValues.id;
                return newFieldValues;
            }
        }
        return false;
    }
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess } = form;
        api.post('payment/confirm-address/' + id, fieldValues).then(response => {
            if (api.success(response, form)) {
                setPayment({
                    ...payment,
                    billingAddress1: fieldValues.address1,
                    billingAddress2: fieldValues.address2,
                    billingTown: fieldValues.town,
                    billingCounty: fieldValues.county,
                    billingPostcode: fieldValues.postcode,
                    hasDetails: true
                });
                logActionSuccess();
            }
        }).catch(error => {
            api.error(error, form, 'Failed to confirm address');
        });
    };
    const editBillingAddress = (event) => {
        setPayment({...payment, hasDetails: false});
    }
    const getPaymentType = (payment, item) => {
        if (payment.type === 'outstanding_transaction') {
            return 'Transaction: ' + item.reference;
        } else if (payment.type === 'order') {
            return 'Order: ' + item.reference;
        }
        return 'One-off payment';
    }
    return (
        <>
            <RouteHeader route={ props.route }/>
            { process.env.REACT_APP_PAYMENT_SANDBOX !== 'false' && <NotificationMessage type="warning" className="global">Payments are in test mode, please use card number 4263970000005262 or for failure 4000120000001154</NotificationMessage> }

            <div className="pay">
                <div ref={ paymentColumn } className="pay__column pay__column--left">
                    <Loading ref={ loadingPanel } className={ 'pay__loading' + (loading ? '' : ' pay__loading--loaded') } />
                    { errorMessage &&
                        <div className="pay__error">
                            <h3><Icon icon="Warning" />There has been a problem with your payment</h3>
                            <p>The information below is provided from our payment partner, Global Payments, and may give an insight into this issue. Please either try this payment again or contact us if you continue to experience a problem:</p>
                            <p className="pay__error-message">{ errorMessage }</p>
                        <Button action={ tryAgain }>Try payment again</Button>
                        </div>
                    }
                    { paymentError &&
                        <div className="pay__error">
                            <h3><Icon icon="Warning" />Payment error</h3>
                            <p>The payment is invalid, please try again.</p>
                            <p><Button to="/payments/make-payment" simple noPadding>Back to make a payment</Button></p>
                        </div>
                    }
                    { !errorMessage && !loading && !paymentError && (!payment || (payment && !payment.hasDetails)) &&
                        <>
                            <Form formFields={ formFields }
                                  eventTitle={ 'Confirm billing address' }
                                  changeHandler={ changeHandler }
                                  valid={ validHandler }
                                  submitName="Confirm details">
                                <h3>Please confirm your billing details</h3>
                                <div className="form__fields-row">
                                    <div className="form__fields-row-field form__fields-row-field--col-6">
                                        <FieldSet fields={ ['phoneNumber'] } />
                                    </div>
                                </div>
                                <FieldSet fields={ ['addressType', 'contactId', 'address'] } />
                            </Form>

                        </>
                    }
                    { !paymentError && iframeKey !== null && (payment && payment.hasDetails) &&
                        <>
                            <iframe key={ iframeKey } ref={ paymentIframe } id="paymentIframe" title="Payment iframe" />
                        </>
                    }
                </div>
                <div className="pay__column pay__column--right">
                    { record.address1.length > 0 &&
                        <Box>
                            <h4>Billing details</h4>
                            <p className="pay__phone-number"><strong>Phone number</strong><br />{ record.phoneNumber }</p>
                            <address><strong>Billing address</strong><br />
                                { getAddress(record) }
                                { (payment && payment.hasDetails)  && <Button simple fullClassName="pay__edit-address" action={ editBillingAddress }>Edit billing details</Button> }
                            </address>
                        </Box>
                    }
                    <Box>
                        <h4>Payment summary</h4>
                        { payment && <>
                            { payment.items.map((item, index) => {
                                return <p className="pay__summary pay__summary--item" key={ index }>{ getPaymentType(payment, item) } <span>{ format.currency(item.amount) }</span></p>
                            }) }
                            <p className="pay__summary pay__summary--total">Total:<span>{ (payment ? format.currency(payment.amount) : '') }</span></p>
                        </> }
                        { !payment && <>
                            { [...Array(3)].map((x, i) => {
                                return <MockData key={ i } randomise minWidth={ 60 } />
                            }) }
                        </> }
                    </Box>
                </div>
            </div>
        </>
    );
};

export default Pay;