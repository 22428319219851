import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import content from 'helper/content';
import { api } from 'helper/api/client';
import permission from 'helper/permission';

const OverviewTankGaugeForm = (props) => {
    const formFields = {
        reading: { label: 'Tank gauge reading', type: 'Percentage', required: true, min: 1, max: 100, placeholder: 'e.g. 45' },
    };
    const validHandler = (form) => {
        const { fieldValues, setErrorMessage, enableForm, logActionSuccess } = form;
        api.post('overview/gauge-reading', fieldValues).then(response => {
            if (api.success(response, form)) {
                props.setData(response.data.response.data);
                logActionSuccess();
                api.cacheClear('overview/data-' + (permission.check('isMetered') ? 'metered' : 'bulk'));
            }
        }).catch(error => {
            enableForm();
            setErrorMessage('Unable to submit tank gauge reading');
        });
    }
    return (
        <>
            <Form formFields={ formFields }
                  valid={ validHandler }
                  submitOutline={ false }
                  eventTitle="Tank gauge submission overview">
                { content.html('overview.tankGaugeIntro', '<p>Sending us a monthly tank gauge reading will help us to keep accurately calculating your usage.</p><p>Please enter your reading below as a whole number (no decimal places).</p>') }
                <div className="form__fields-row">
                    <div className="form__fields-row-field form__fields-row-field--col-6">
                        <FieldSet fields={ ['reading'] }/>
                    </div>
                </div>
            </Form>
        </>
    );
}
export default OverviewTankGaugeForm;