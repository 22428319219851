import React, { useReducer, useContext } from 'react';
import RouteHeader from 'components/partial/RouteHeader';

import AccountsContext from 'context/accounts';

import { ToastContainer, toast } from 'react-toastify';
import toastProps from 'helper/toast';
import 'react-toastify/dist/ReactToastify.css';
import 'scss/component/toast/toast.scss';

import AuthContext from 'context/auth';
import UserContext from 'context/user';
import Box from 'components/ui/Box';
import FavouriteButton from 'components/ui/FavouriteButton';
import { modalReducer, modalInitialState } from 'helper/modalReducer';
import Modal from 'portal/Modal';
import AccountNameEdit from 'components/partial/my-account/AccountNameEdit';
import ListItem from 'components/ui/ListItem';
import { getType } from 'helper/account';
import { api } from 'helper/api/client';
import log from 'helper/log';
import permission from 'helper/permission';

const ManageAccounts = (props) => {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const { accounts, setAccounts } = useContext(AccountsContext);
    const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);

    const deleteSuccess = (response, apiData) => {
        toast.success('Account removed');
        // Prevents an error due being called within AJAX response
        setTimeout(() => {
            setAccounts(accounts.filter(account => account.id !== apiData.id));
        }, 200);
    }
    const editAlias = (event) => {
        dispatchModal({ type: 'open', title: event.currentTarget.dataset.title, yesOption: null, content: <AccountNameEdit id={ parseInt(event.currentTarget.dataset.id) } dispatchModal={ dispatchModal } accounts={ accounts }/> });
    }
    const getAccount = (account) => {
        return (
            <>
                { account.alias && <p className="list-item__title-primary">{ account.alias }</p> }
                <p className="list-item__title-secondary">{ getType(account.type) } { account.accountNumber }{ (account.postcode ? ' - ' + account.postcode : '') }</p>
                <FavouriteButton account={ account } />
            </>
        )
    }
    const switchAccount = (event) => {
        userContext.setSwitching(true);
        api.post('customer/accounts/switch', { id: parseInt(event.currentTarget.dataset.id) })
            .then(response => {
                if (api.success(response)) {
                    log.action('Customer account', 'Switched', 'customer_account');
                    authContext.setToken(response.data.response.token);
                    userContext.setUser(response.data.response.customer);
                    setAccounts(response.data.response.customer.accounts);
                    props.history.push('/overview');
                    userContext.setSwitching(false);
                }
            }).catch(error => {
            userContext.setSwitching(false);
        });
    }

    return (
        <>
            <ToastContainer { ...toastProps } />
            <RouteHeader route={ props.route }/>
            <Box padding="large">
                <h4>Manage accounts</h4>
                { accounts.map((account, index) => {
                    const viewProps = {
                        title: 'Visit account',
                        action: switchAccount,
                        "data-id": account.id,
                        disabled: account.current
                    };
                    const editProps = {
                        title: (account.alias ? 'Rename' : 'Set a nickname'),
                        action: editAlias,
                        "data-id": account.id,
                        "data-title": (account.alias ? 'Update nickname' : 'Set a nickname'),
                    }
                    const deleteProps = {
                        title: 'Delete',
                        apiAction: 'customer/accounts/remove',
                        apiVerb: 'delete',
                        apiData: { id: account.id },
                        modal: modal,
                        modalTitle: 'Are you sure you want to remove this account?',
                        modalYesOption: 'Yes',
                        modalNoOption: 'Cancel',
                        dispatchModal: dispatchModal,
                        apiSuccess: deleteSuccess,
                        disabled: account.current
                    }
                    return (
                        <ListItem
                            key={ index }
                            className={ 'account' + (account.alias ? '' : ' no-primary') }
                            view={ viewProps }
                            edit={ editProps }
                            delete={ permission.check('canDeleteAccounts') ? deleteProps : null }
                        >
                            { getAccount(account) }
                        </ListItem>
                    );
                }) }
            </Box>
            <Modal { ...modal } dispatchModal={ dispatchModal }>{ modal.content }</Modal>
        </>
    );
};

export default ManageAccounts;