import React from 'react';
import Button from 'components/ui/Button';

const OverviewEncourageAutoOrdering = ({ data }) => {
    return (
        <>
            <h3 className="overview-panel-small__title">Opt into Auto-ordering</h3>
            <div className="overview-panel-small__body">
                <p className="overview-panel-small__body-large">Our Auto-Ordering service builds a profile of your usage, and we’ll automatically place your deliveries, so you don’t have to.</p>
            </div>
            <div className="overview-panel-small__footer">
                <Button to="/form/change-to-top-up" simple>Opt into Auto-ordering</Button>
            </div>
        </>
    );
}

export default OverviewEncourageAutoOrdering;