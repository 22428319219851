import moment from 'moment';

export const fromToValidationHandler = (form) => {
    const { fieldValues } = form;
    let { validFields } = form;
    if (fieldValues.from.length || fieldValues.to.length) {
        const format = 'DD/MM/YYYY';
        let from = moment(fieldValues.from, format);
        let to = moment(fieldValues.to, format);
        if (fieldValues.to.length && !to.isAfter(from)) {
            validFields.to = { valid: false, validationMessage: '' };
        }
        if (fieldValues.from.length && from.isAfter(moment())) {
            validFields.from = { valid: false, validationMessage: '' };
        }
    }
    return validFields;
};