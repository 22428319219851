import RouteHeader from 'components/partial/RouteHeader';
import 'scss/component/payment/payment.scss';
import MakePaymentAmount from './MakePaymentAmount';
import MakePaymentTransaction from './MakePaymentTransaction';
import FaqsBox from 'components/partial/FaqsBox';

const MakePayment = (props) => {
    return (
        <>
            <RouteHeader route={ props.route }/>
            <MakePaymentAmount />
            <MakePaymentTransaction />
            <FaqsBox noPrint settingName="makePayment" defaultFaqs={ [77000, 117795] } />
        </>
    );
};

export default MakePayment;