import PropTypes from 'prop-types';
import Thead from 'components/table/TableHead';
import Tfoot from 'components/table/Tfoot';
import Tbody from 'components/table/TableBody';
import TbodyLoading from 'components/table/TbodyLoading';
import Loading from 'components/ui/Loading';
import Pagination from 'components/ui/Pagination';
import Button from 'components/ui/Button';
import { getClassName } from 'helper/bem';
import printHandler from 'helper/print';
import { useState } from 'react';
import Icon from 'components/ui/Icon';

const Table = (props) => {
    const [pageHistory, setPageHistory] = useState({}); // To store previous page "after" responses
    let classNames = '';
    if (props.striped) {
        classNames += ' striped';
    }
    if (props.extraClass) {
        classNames += ' ' + props.extraClass;
    }
    if (props.loading) {
        classNames += ' loading';
    }
    if (props.extraClass) {
        classNames += ' ' + props.extraClass;
    }

    const paginationAction = (event) => {
        setPageHistory({...pageHistory, [event.currentTarget.dataset.page]: event.currentTarget.dataset.after});
        props.paginationAction(parseInt(event.currentTarget.dataset.page), event.currentTarget.dataset.after);
    }
    const getPrevNextPage = () => {
        if (!props.total && (props.page > 1 || props.nextPage || (props.after !== null && (props.after !== '' && props.page === 1)))) {
            let nextDisabled, previousAfter, nextAfter, nextPage;
            if (props.after === null) {
                nextDisabled = props.loading || !props.next;
                nextPage = props.nextPage;
            } else {
                nextDisabled = props.loading || props.after === '';
                nextPage = props.page + 1;
                previousAfter = pageHistory[(props.page - 1)];
                nextAfter = props.after;
            }
            return (
                <div className="table-footer__back-next">
                    <span className="table-footer__back-next-page">Page { props.page }</span>
                    <Button disabled={ props.loading || props.page === 1 } simple onClick={ paginationAction } data-page={ (props.page - 1) } data-after={ previousAfter }>Previous</Button>
                    <Button disabled={ nextDisabled } simple onClick={ paginationAction } data-page={ nextPage } data-after={ nextAfter }>Next</Button>
                </div>
            );
        }
        return null;
    }
    const getTableFooter = () => {
        return (
            <div className="table-footer">
                { props.print && <Button size="small" simple={ true } className="table-footer" onClick={ printHandler } icon="Print" disabled={ props.loading }>Print { props.page && ' page' }</Button> }
                { (!props.loading && props.page && props.total) && <Pagination className="table" page={ props.page } total={ props.total } numberPerPage={ props.numberPerPage } paginationAction={ props.paginationAction }/> }
                { getPrevNextPage() }
            </div>
        )
    }
    return (
        <>
            <div className={ 'table-wrapper' + (props.boxSize ? ' table-wrapper--in-box-' + props.boxSize : '') }>
                { props.title && <h2 className="table-title">{ props.title + (props.page && props.page > 1 ? ' page ' + props.page : '') }</h2> }
                { props.loading && !props.error && <Loading className="table__loading" size="40"/> }
                { props.error && <div className="table__error"><Icon icon="Warning" />Error loading table data</div> }
                <table className={ getClassName('table', classNames) }>
                    { props.showHead && <Thead head={ props.head } loading={ props.loading }/> }
                    { props.loading && <TbodyLoading head={ props.head } numberPerPage={ props.numberPerPage }/> }
                    { !props.loading &&
                        <>
                            <Tbody head={ props.head } body={ props.body }/>
                            { props.foot && <Tfoot foot={ props.foot } /> }
                        </>
                    }
                </table>
                { (props.print || props.export || props.page || props.after) && getTableFooter() }
            </div>
        </>
    );
};


Table.defaultProps = {
    title: '',
    showHead: true,
    striped: true,
    extraClass: '',
    boxSize: null,
    loading: false,
    error: false,
    print: false,
    export: false,
    page: null,
    nextPage: null,
    after: null, // Used with Codas API as no proper pagination available
    //total: null,
    sortAction: null,
    sortBy: null,
    sortDirection: null,
    numberPerPage: null, // For pagination and also the number of mock rows to show when loadings
};

Table.propTypes = {
    title: PropTypes.string,
    showHead: PropTypes.bool,
    striped: PropTypes.bool,
    extraClass: PropTypes.string,
    loading: PropTypes.bool,
    boxSize: PropTypes.oneOf(['page', 'normal', 'large']),
    print: PropTypes.bool,
    export: PropTypes.bool,
    page: PropTypes.number,
    nextPage: PropTypes.number,
    //total: PropTypes.number,
    sortAction: PropTypes.func,
    sortBy: PropTypes.string,
    sortDirection: PropTypes.string,
    numberPerPage: PropTypes.number,
    head: PropTypes.object.isRequired,
    body: PropTypes.array.isRequired,
}

export default Table;