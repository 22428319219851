import React, { useState, useReducer, useEffect, useContext } from 'react';
import { api, axios } from 'helper/api/client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'scss/component/toast/toast.scss';
import RouteHeader from 'components/partial/RouteHeader';

import crudReducer from 'helper/crudReducer';
import { modalReducer, modalInitialState } from 'helper/modalReducer';

import Modal from 'portal/Modal';

import Table from 'components/table/Table';
import Box from 'components/ui/Box';
import Button from 'components/ui/Button';
import ContactAddEdit from 'components/partial/my-account/ContactAddEdit';
import toastProps from 'helper/toast';
import global from 'helper/global';
import UserContext from 'context/user';

const ManageContacts = (props) => {
    const { user } = useContext(UserContext);
    const [contacts, dispatchContacts] = useReducer(crudReducer, []);
    const [after, setAfter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [autoOpenModal, setAutoOpenModal] = useState(false);
    const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);
    const [filter, setFilter] = useState({ page: 1, after: null });
    useEffect(() => {
        let source = axios.CancelToken.source();
        setLoading(true);
        api.get('contact/list', { ...filter }, {
            cancelToken: source.token,
        }).then(response => {
            dispatchContacts({ type: 'set', records: response.data.response.items });
            setAfter((response.data.response.after ? response.data.response.after : '')); // Must be '' as null us used to determine whether to show it or not
            setLoading(false);
            api.setCache(response);
        }).catch(error => {
            api.error(error);
        });
        // Will cancel AJAX on unmount
        return () => source.cancel();
    }, [filter]);

    const getContacts = (page, after) => {
        setFilter({ page, after });
        setAfter(after)
    }
    const deleteSuccess = (response, apiData) => {
        dispatchContacts({ type: 'delete', id: apiData.id });
        api.cacheClear('contact/list');
        toast.success('Contact removed');
    }
    const addContact = () => {
        dispatchModal({ type: 'open', title: 'Add contact', yesOption: null, content: <ContactAddEdit dispatchModal={ dispatchModal } dispatchContacts={ dispatchContacts } setFilter={ setFilter } contacts={ contacts }/> });
    }
    const editContact = (event) => {
        dispatchModal({ type: 'open', title: 'Update contact', yesOption: null, content: <ContactAddEdit id={ event.currentTarget.dataset.id } dispatchModal={ dispatchModal } dispatchContacts={ dispatchContacts } contacts={ contacts }/> });
    }
    const theadConfig = {
        name: { content: 'Name' },
        email: { className: '', content: 'Email' },
        button: { className: 'button', content: '' },
    };
    const getButtons = (contact) => {
        const buttonProps = { fullClassName: 'table-button', size: 'small', outline: true, simple: true, colour: 'blue' };
        return (
            <>
                <Button { ...buttonProps } action={ editContact } data-id={ contact.id }>Edit</Button>
                <Button
                    { ...buttonProps }
                    apiAction={ 'contact/remove/' + contact.id }
                    apiData={ { id: contact.id } }
                    apiVerb="delete"
                    disabled={ contact.isMainContact || contact.id === user.contact.id }
                    apiSuccess={ deleteSuccess }
                    modal={ modal }
                    modalTitle="Are you sure you want to remove this contact?"
                    modalYesOption="Yes"
                    modalNoOption="Cancel"
                    dispatchModal={ dispatchModal }>Delete</Button>
            </>);
    }
    const tableData = contacts.map((contact, index) => {
        return {
            name: contact.title + ' ' + contact.firstName + ' ' + contact.surname + (contact.isMainContact ? ' (main contact)' : ''),
            email: contact.email,
            phone: contact.phoneNumber + (contact.mobileNumber.length ? ' / ' + contact.mobileNumber : ''),
            postcode: contact.postcode,
            button: getButtons(contact)
        }
    });
    if (!autoOpenModal && global.formSuccess === 'contactEditMain') {
        setAutoOpenModal(true);
        dispatchModal({ type: 'open', title: 'Update contact', yesOption: null, content: <ContactAddEdit id="00000" dispatchModal={ dispatchModal } dispatchContacts={ dispatchContacts } contacts={ contacts }/> });
    }
    return (
        <>
            <ToastContainer { ...toastProps } />
            <RouteHeader route={ props.route } />
            <Box padding="large">
                <h3>Contacts</h3>
                { !loading && contacts.length === 0 && <p>You have no contacts</p> }
                { (loading || contacts.length > 0) && <Table
                    extraClass="contacts"
                    boxSize="large"
                    showHead={ false }
                    head={ theadConfig }
                    body={ tableData }
                    page={ (filter ? filter.page : 1) }
                    after={ after }
                    paginationAction={ getContacts }
                    numberPerPage={ 12 }
                    loading={ loading }
                /> }
                <p><Button action={ addContact } simple={ true } disabled={ loading }>+ Add contact</Button></p>
            </Box>
            <Modal { ...modal } dispatchModal={ dispatchModal }>{ modal.content }</Modal>
        </>
    );
};

export default ManageContacts;