import Button from 'components/ui/Button';
import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';
import OverviewPanelFooter from '../structure/OverviewPanelFooter';
import OverviewTankUsage from 'components/partial/overview/panel/generic/OverviewTankUsage';
import OverviewLorry from 'components/partial/overview/panel/generic/OverviewLorry';
import OverviewTankGaugeForm from 'components/partial/overview/panel//generic/OverviewTankGaugeForm';

import 'scss/component/overview-remaining-box/overview-remaining-box.scss';
import content from 'helper/content';
import { hasGaugeReading } from 'helper/overview';

const modifierClass = 'auto-ordering';

const OverviewAutoOrdering = ({ data, setData }) => {
    const title = (hasGaugeReading(data) ? (data.hasOrder ? 'Your order' : 'Your usage') : 'Submit a tank gauge reading');
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle className={ modifierClass } title={ title }/>
                <OverviewPanelBody className={ modifierClass }>
                    { hasGaugeReading(data) && <>
                        { data.hasOrder ? <OverviewLorry data={ data } /> : <OverviewTankUsage data={ data } /> }
                    </> }
                    { !hasGaugeReading(data) && <>
                        { data.remainingPercentage !== null && data.remainingPercentage > 0 &&
                        <div className="remaining-box remaining-box--wide">
                            <div className="remaining-box__row">
                            <div className="remaining-box__row-text">{ content.text('overview.autoOrderingEstimatedUsages', 'Based on your previous usage our systems estimate your tank level to be at') }</div>
                            <p className="remaining-box__row-percentage">{ data.remainingPercentage }%</p>
                            </div>
                        </div> }
                        <OverviewTankGaugeForm data={ data } setData={ setData } />
                    </> }
                </OverviewPanelBody>
                <OverviewPanelFooter>
                    { data.remainingPercentage >= data.warningThreshold &&
                    <div className="overview-panel__buttons">
                        <Button simple to="/view-all-bulk-data">View all bulk data</Button>
                    </div> }
                </OverviewPanelFooter>
            </OverviewPanel>
        </>
    );
}
export default OverviewAutoOrdering;