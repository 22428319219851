import React, { useContext } from 'react';
import Table from 'components/table/Table';
import moment from 'moment';
import UserContext from 'context/user';

const UsageList = (props) => {
    const { usage } = props;
    const { user } = useContext(UserContext);
    if (usage) {
        const theadConfig = {
            date: { className: '', content: 'Date' },
            percentage: { className: '', content: 'Level (%)' },
        };
        if (user.capacity) {
            theadConfig.level = {className: '', content: 'Level'};
        }
        const tableData = usage.map((usageItem) => {
            return {
                date: moment(usageItem.date).format('D/M/YY'),
                percentage: usageItem.level + '%',
                level: user.capacity ? Math.round((user.capacity / 100) * usageItem.level) + ' litres' : null,
            }
        });
        return (
            <>
                <Table
                    extraClass="usage"
                    boxSize="large"
                    loading={ props.loadingUsage }
                    numberPerPage={ 10 }
                    head={ theadConfig }
                    body={ tableData }
                />
            </>
        );
    }
    return null;
};

export default UsageList;