import Th from 'components/table/Th';

const TableHead = (props) => {
    return (
        <thead>
        <tr>
            { Object.entries(props.head).map(([index, heading]) => {
                return (
                    <Th key={ index } className={ (heading.className ? heading.className : '') }>
                        { heading.content }
                    </Th>
                );
            }) }
        </tr>
        </thead>
    );
};

export default TableHead;