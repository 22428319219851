import FlogasForm from 'components/partial/FlogasForm';
import FieldSet from 'components/form/FieldSet';
import { useContext, useEffect, useState } from 'react';
import UserContext from 'context/user';
import content from 'helper/content';
import NotificationMessage from 'components/ui/NotificationMessage';

const FormDirectDebitSignUp = (props) => {
    const { user } = useContext(UserContext);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    let phoneNumber = '';
    if (user.contact.phonePreference === 'M' && user.contact.mobileNumber.length) {
        phoneNumber = user.contact.mobileNumber;
    } else if (user.contact.phonePreference === 'T' && user.contact.phoneNumber.length) {
        phoneNumber = user.contact.phoneNumber;
    } else if (user.contact.phoneNumber.length) {
        phoneNumber = user.contact.phoneNumber;
    } else if (user.contact.mobileNumber.length) {
        phoneNumber = user.contact.mobileNumber;
    } else if (user.contact.phoneNumber2.length) {
        phoneNumber = user.contact.phoneNumber2;
    }
    const formFields = {
        telephone: { label: 'Contact phone number', required: true, type: 'Text', value: phoneNumber, validator: 'Phone' },
    };
    const successCallback = function() {
        console.log('callback run');
        window.localStorage.setItem('directDebitRequested_' + user.accountNumber, new Date().getTime().toString());
        setHasSubmitted(true);
    }
    useEffect(() => {
        if (!hasSubmitted) {
            const lastSubmittedTime = window.localStorage.getItem('directDebitRequested_' + user.accountNumber);
            if (lastSubmittedTime && parseInt(lastSubmittedTime, 10) >= (new Date().getTime() - (3600 * 24 * 7 * 1000))) {
                setHasSubmitted(true);
            }
        }
    }, [hasSubmitted, user.accountNumber]);
    return (<>
        {hasSubmitted && <div className="form"><NotificationMessage>{ content.text('directDebit.submitted', 'We\'ve received your Direct Debit request and will be in touch soon.') }</NotificationMessage></div>}
        {!hasSubmitted && <FlogasForm formFields={ formFields } form="direct-debit-sign-up" eventTitle="Sign up to Direct Debit" title="Sign up to Direct Debit" submitName="Submit" successCallback={successCallback}>
            <p>Fill out the box below and we'll give you a call to set up your Direct Debit.</p>
            <FieldSet fields={ ['telephone'] }/>
        </FlogasForm>}
    </>);
};

export default FormDirectDebitSignUp;