import React, { useContext, useRef, useState } from 'react';
import Form from 'components/form/Form';
import AccountsContext from 'context/accounts';
import content from 'helper/content';
import { api } from 'helper/api/client';
import FieldSet from 'components/form/FieldSet';
import Button from 'components/ui/Button';
import InfoBox from 'components/ui/InfoBox';
import Icon from 'components/ui/Icon';

const RegisterStageAlternative = (props) => {
    const emailInfoBox = useRef(null);
    const phoneInfoBox = useRef(null);
    const { setAccounts } = useContext(AccountsContext);
    const [invalid, setInvalid] = useState(false);
    const emailIconClick = (event) => {
        event.preventDefault();
        if (emailInfoBox.current) {
            emailInfoBox.current.classList[emailInfoBox.current.classList.contains('info-box--hidden') ? 'remove' : 'add']('info-box--hidden');
        }
    }
    const phoneIconClick = (event) => {
        event.preventDefault();
        if (phoneInfoBox.current) {
            phoneInfoBox.current.classList[phoneInfoBox.current.classList.contains('info-box--hidden') ? 'remove' : 'add']('info-box--hidden');
        }
    }
    const formFields = {
        email: { label: 'Email', type: 'Email', validator: 'Email', required: true, placeholder: 'e.g. john.smith@emailhost.com', fieldAfter: <button type="button" onClick={ emailIconClick } className="form__info-icon" tabIndex={-1}><Icon icon="Info" /></button> },
        phoneNumber: { label: 'Phone number', type: 'Text', validator: 'Phone', placeholder: 'e.g. 071234 567890', required: true, value: process.env.REACT_APP_TEST_PHONE_NUMBER, fieldAfter: <button type="button" onClick={ phoneIconClick } className="form__info-icon" tabIndex={-1}><Icon icon="Info" /></button> },
    };
    const goBack = (event) => {
        event.preventDefault();
        props.setFormStage('verify');
    }
    const restartProcess = (event) => {
        event.preventDefault();
        props.setFormData(props.initialFormData);
        props.setFormStage('1');
    }
    const tryAgain = (event) => {
        event.preventDefault();
        setInvalid(false);
    }
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess } = form;
        api.post('customer/validate', { accountNumber: props.formData.accountNumber, postcode: props.formData.postcode, invoiceNumber: props.formData.invoiceNumber, ...fieldValues })
            .then(response => {
                if (api.success(response, form, !props.addAccount)) {
                    if (props.addAccount && (fieldValues.email || props.verifyData.email !== '')) {
                        api.post('customer/add', { accountNumber: props.formData.accountNumber, postcode: props.formData.postcode, ...fieldValues })
                            .then(response => {
                                if (api.success(response, form)) {
                                    logActionSuccess();
                                    setAccounts(response.data.response.accounts);
                                    props.setFormStage('added');
                                }
                            }).catch(error => {
                                setInvalid(true);
                        });
                    } else {
                        if (response.data.response.email !== '') {
                            fieldValues.email = response.data.response.email;
                        }
                        fieldValues.hasPhoneNumber = response.data.response.hasPhoneNumber;
                        props.setFormData({ ...props.formData, ...fieldValues });
                        logActionSuccess();
                        props.setFormStage('password');
                    }
                }
            }).catch(error => {
                setInvalid(true);
        });
    };
    return (
        <>
            <Form formFields={ formFields }
                  className="register-alternative"
                  eventTitle="Register alternative details"
                  valid={ validHandler }
                  visible={ !invalid }
                  hasChanged={ process.env.NODE_ENV === 'development' }
                  submitAlign="right"
                  submitOutline={ props.addAccount }
                  submitColour={ props.addAccount ? 'blue' : 'purple' }
                  submitName={ props.addAccount ? 'Verify and add' : 'Next step' }>
                { content.html('register.alternate', '<p>Enter alternative details that you believe we have on file for your account.</p>') }
                <FieldSet fields={ ['email'] }/>
                <InfoBox ref={ emailInfoBox } className="text-small hidden">
                    { content.html('register.helpInfoEmail', '<p>Your email address must match exactly what we have stored for you.</p><p>It may be an older email address you have used - you can change this once you’re logged in.</p>') }
                </InfoBox>
                <FieldSet fields={ ['phoneNumber'] }/>
                <InfoBox ref={ phoneInfoBox } className="text-small hidden">
                    { content.html('register.helpInfoPhone', '<p>Your phone number must match exactly what we have stored for you including spaces and the + symbol.</p><p>It may be an older phone number you have used - you can change this once you’re logged in.</p>') }
                </InfoBox>
                <div className="form-row form-row--submit">
                    <Button action={ goBack } noPadding simple>Back</Button>
                    <input type="submit" />
                </div>
            </Form>
            { invalid &&
                <>
                { content.html('register.alternateFailure', '<p><strong>Sorry!</strong><br />We have not been able to verify your account details. Please try again or call us on <a href="tel:0800 574 574">0800 574 574</a> for additional help with activating your account.</p>') }
                    <p><Button action={ tryAgain } noPadding simple>Try again</Button></p>
                    <p><Button action={ restartProcess } noPadding simple>Restart activation process</Button></p>

                </>
            }
        </>
    );
};

export default RegisterStageAlternative;