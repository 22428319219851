import Td from 'components/table/Td';

const Tfoot = (props) => {
    return (
        <tfoot>
        { props.foot.map((rowColumns, index) => {
            return (
                <tr key={ index }>
                    { rowColumns.map((column, colIndex) => {
                        return <Td key={ colIndex } className={ column.className } colspan={ column.colspan }>{ column.value }</Td>
                    }) }
                </tr>
            )
        }) }
        </tfoot>
    );
};

export default Tfoot;