import React, { useState, useEffect } from 'react';
import { api, axios } from 'helper/api/client';
import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';
import Pagination from 'components/ui/Pagination';
import Icon from 'components/ui/Icon';
import { getFilter, getFilterNoResultsString, setHistoryState } from 'helper/filter';
import 'scss/component/faqs/faqs.scss';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import queryString from 'query-string';
import MockData from 'components/ui/MockData';
import FaqItem from 'components/ui/FaqItem';

const Faqs = (props) => {
    const params = queryString.parse(window.location.search);
    const urlFaq = (params.faq ? params.faq : null);
    const [openId, setOpenId] = useState(null);
    const numberPerPage = 12;
    const [faqs, setFaqs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(true);
    const [filter, setFilter] = useState(getFilter({ page: 1, category: params.category, keyword: params.keyword }));
    const formFields = {
        keyword: { label: 'Keyword', type: 'Text', placeholder: 'Search FAQs...', value: params.keyword, showLabel: false, className: 'keyword', fieldAfter: <Icon icon="Search" /> },
        category: { label: '', type: 'Dropdown', options: categories, allTitle: 'Filter', value: params.category, showLabel: false, className: 'filter' }
    };
    useEffect(() => {
        let source = axios.CancelToken.source();
        api.get('faqs/categories').then(response => {
            setCategories(response.data.response.items);
            api.setCache(response);
        }).catch(error => {
            api.error(error);
        });
        return () => source.cancel();
    }, []);
    useEffect(() => {
        let source = axios.CancelToken.source();
        setLoading(true);
        api.get('faqs/list', { ...filter, faq: urlFaq }, {
            cancelToken: source.token,
        }).then(response => {
            setFaqs(response.data.response.items);
            if (filter.page !== response.data.response.page) {
                setFilter({ ...filter, page: response.data.response.page });
            }
            setTotal(response.data.response.total);
            setLoading(false);
            setFormLoading(false);
            if (urlFaq) {
                const foundFaq = response.data.response.items.filter(faq => faq.slug === urlFaq);
                if (foundFaq) {
                    setOpenId(foundFaq[0].id);
                }
            }
            /*if (urlFaq) {
                let historyParams = { faq: urlFaq };
                if (response.data.response.page > 1) {
                    historyParams.page = response.data.response.page;
                }
                setHistoryState(historyParams);
            }*/
            api.setCache(response);
        }).catch(error => {
            api.error(error);
        });
        return () => source.cancel();
    }, [filter, urlFaq]);
    const filterHandler = (form) => {
        const { fieldValues, enableForm } = form;
        const params = { ...fieldValues, page: 1 };
        setHistoryState(params);
        setFilter(params);
        enableForm();
    };
    const setFaqsPage = (page) => {
        const params = { ...filter, page: page };
        setHistoryState({ page: page });
        setFilter(params);
    }
    return (
        <>
            <RouteHeader route={ props.route }/>
            <Box padding="large">
                <h3>Frequently asked questions</h3>
                <Form formFields={ formFields }
                      eventTitle="FAQs filter"
                      showReset={ false }
                      filter={ true }
                      loading={ formLoading }
                      className="faqs"
                      showSubmit={ false }
                      valid={ filterHandler }>
                    <FieldSet fields={ ['keyword'] } />
                    <button className="faqs-search" disabled={ formLoading }><Icon icon="Search" /></button>
                    <FieldSet fields={ ['category'] } />
                </Form>
                <div className="faqs-list">
                    { loading && [...Array(12)].map((x, i) => {
                        return <MockData key={ i } randomise={ true } minWidth={ 30 } />
                    }) }
                    { !loading && faqs && faqs.length === 0 && <p>There are no FAQs { getFilterNoResultsString(filter) }</p> }
                    { !loading && faqs && faqs.length > 0 && faqs.map((faq, index) => {
                        return <FaqItem key={ index } faq={ faq } openId={ openId } setOpenId={ setOpenId } />
                    })
                    }
                </div>
                { !loading && total > numberPerPage && <Pagination className="center" page={ filter.page } total={ total } numberPerPage={ numberPerPage } paginationAction={ setFaqsPage }/> }
            </Box>
        </>
    )
}

export default Faqs;