import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

export const getLastGaugeReadingDate = (data, format = false) => {
    let lastGaugeReadingDate = data.lastGaugeReadingDate;
    if (lastGaugeReadingDate) {
        lastGaugeReadingDate = moment(lastGaugeReadingDate, dateFormat);
    }
    if (format) {
        return (lastGaugeReadingDate ? lastGaugeReadingDate.format(dateFormat) : '');
    }
    return lastGaugeReadingDate;
}
export const hasGaugeReading = (data) => {
    const lastGaugeReadingDate = getLastGaugeReadingDate(data);
    if (lastGaugeReadingDate && lastGaugeReadingDate.diff(moment(), 'days') >= 0) {
        return true;
    }
    return false;
}