import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import InfoBox from 'components/ui/InfoBox';
import content from 'helper/content';
import Button from 'components/ui/Button';
import { api } from 'helper/api/client';
import Balance from 'components/ui/Balance';
import { useContext } from 'react';
import UserContext from 'context/user';
import permission from "helper/permission";

const MakePaymentAmount = (props) => {
    const { balance } = useContext(UserContext);
    const formFields = {
        amount: { label: 'Amount to pay', type: 'Currency', placeholder: '0.00', required: true, min: 0.01, max: 100000},
    };
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess } = form;
        api.post('payment/create', { amount: parseFloat(fieldValues.amount), type: 'balance' })
            .then(response => {
                if (api.success(response, form)) {
                    logActionSuccess();
                    // Must do a refresh due to CSP headers
                    window.location = '/payments/make-payment/pay/' + response.data.response.id;
                }
            }).catch(error => {
            api.error(error, form, 'Failed to submit payment');
        });
    };
    return (
        <Form formFields={ formFields }
              eventTitle="Make one-off payment"
              className="make-payment"
              valid={ validHandler }
              submitColour="purple"
              submitOutline={ false }
              submitName="Make one-off payment">
            <div className="form-info-box-row">
                <div className="form-info-box-row__fields">
                    <h2 className="form__title">Your balance</h2>
                    <p><Balance /></p>
                    <FieldSet fields={ ['amount'] }/>
                </div>
                <InfoBox className="text-small">
                    { content.html('payment.info' + (balance < 0 ? 'Negative' : ''), '<p>Content here about what debit / credit means.</p>') }
                </InfoBox>
            </div>
            <div className="form-row form-row--submit-buttons">
                <input type="submit" />
                {!permission.check('isDirectDebitType') &&
                    <Button to="/form/direct-debit-sign-up" outline>Set up a direct debit</Button>
                }
            </div>
        </Form>
    );
};

export default MakePaymentAmount;