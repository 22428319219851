import React from 'react';
import { Link } from 'react-router-dom';

const OverviewAutoOrdering = ({ data }) => {
    return (
        <>
            <h3 className="overview-panel-small__title">You're on Auto-ordering</h3>
            <div className="overview-panel-small__body overview-panel-small__body--no-footer">
                <p className="overview-panel-small__body-large">You don't need to worry about ordering your own gas, we're monitoring your usage and gas levels to place your orders for you.</p>
                <p className="overview-panel-small__body-small">If you're below 10%, please <Link to="/help-support/contact">contact us</Link> for more information about your order.</p>
            </div>
        </>
    );
}

export default OverviewAutoOrdering;