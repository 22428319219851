import Form from 'components/form/Form';

import { api } from 'helper/api/client';
import log from 'helper/log';

const DeliveryAddressEdit = (props) => {
    const address = props.address;
    const formFields = {
        nickName: { label: 'Name', value: address.nickName, type: 'Text', validator: 'CodasString', minLength: 2 },
    }
    const submitName = 'Update address name';
    const validHandler = (form) => {
        const { fieldValues, setSuccessMessage, logActionSuccess } = form;
        api.patch('delivery-address/update/' + address.id, fieldValues)
            .then(response => {
                if (api.success(response, form)) {
                    setSuccessMessage('Address updated successfully');
                    log.action('Address management', 'Updated address', 'address_management');
                    // Update the address array used by <Addresses />
                    props.dispatchAddresses({ type: 'update', id: props.id, record: { ...address, nickName: fieldValues.nickName } });
                    logActionSuccess();
                    api.cacheClear('delivery-address/list');
                    props.dispatchModal({ type: 'closeIn', time: 5000 });
                }
            }).catch(error => {
            api.error(error, form, 'Failed to update address');
        });
    };
    const id = (props.id ? parseInt(props.id) : 0);

    return (
        <>
            <Form modal={ true } formFields={ formFields } id={ id }
                  eventTitle={ 'Address ' + (id > 0 ? 'edit' : 'add') }
                  valid={ validHandler }
                  submitName={ submitName }/>
        </>
    );
};

export default DeliveryAddressEdit;