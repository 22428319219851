import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Hero from 'components/partial/Hero';
import ConfigContext from 'context/config';
import SubNav from 'components/partial/SubNav';
import GlobalNotificationMessage from 'components/ui/GlobalNotificationMessage';
import GlobalMessages from 'components/ui/GlobalMessages';

const RouteHeader = (props) => {
    const { routeConfig } = useContext(ConfigContext);
    const routePathArray = props.route.path.split('/');
    let heroRoute = props.route;
    if (routePathArray.length > 1) {
        const parentRoute = routeConfig.find(route => route.path === routePathArray[0]);
        if (parentRoute) {
            heroRoute = parentRoute;
        }
    }
    return (
        <>
            <GlobalMessages/>
            { heroRoute.title.length > 0 && <Hero route={ heroRoute} /> }
            { props.title && props.title.length > 0 && <Hero title={ props.title }/> }
            <GlobalNotificationMessage globalClass={ true } />
            <SubNav />
        </>
    )
}

RouteHeader.defaultProps = {
    route: {
        title: ''
    }
};
RouteHeader.propTypes = {
    route: PropTypes.object,
}

export default RouteHeader;