import Box from 'components/ui/Box';
import Icon from 'components/ui/Icon';
import content from 'helper/content';
import Form from 'components/form/Form';
import permission from 'helper/permission';
import { api } from 'helper/api/client';
import ContactLocked from 'components/ui/ContactLocked';
import React, { useContext } from 'react';
import UserContext from 'context/user';
import global from 'helper/global';

const EbillingSignup = (props) => {
    const { user, setUser } = useContext(UserContext);
    const validHandler = (form) => {
        const { fieldValues, setErrorMessage, setSubmittedMessage, enableForm, logActionSuccess } = form;
        api.post('ebilling/signup', fieldValues).then(response => {
            if (api.success(response, form)) {
                global.formSuccess = 'ebillingSignup';
                global.successMessage = 'Thank you for signing up to e-Billing! Future Invoices and Statements will be sent to your email and available on My Account.';
                setSubmittedMessage(content.html('ebilling.thankYou', '<p><strong>Thank you for signing up to e-billing.</strong></p>'));
                logActionSuccess();
                setUser({
                    ...user,
                    permissions: { ...user.permissions, actions: { ...user.permissions.actions, canSignUpForEbilling: false, isCustomerLocked: response.data.isCustomerLocked } },
                });
            } else if (response.data.isCustomerLocked) {
                global.formFailure = 'ebillingSignup';
                setUser({
                    ...user,
                    permissions: { ...user.permissions, actions: { ...user.permissions.actions, isCustomerLocked: response.data.isCustomerLocked } },
                });
            }
        }).catch(error => {
            enableForm();
            setErrorMessage('Unable to sign up to e-billing');
        });
    }
    if (global.formSuccess === 'ebillingSignup') {
        return <Box padding="large">
            <h3><Icon icon="Online" className="heading-icon" />Say goodbye to paper bills and opt in to e-billing</h3>
            { content.html('ebilling.thankYou', '<p>Thank you for signing up to e-billing!</p>') }
        </Box>
    }
    if (permission.check('canSignUpForEbilling', false)) {
        return (
            <Box padding="large">
                <ContactLocked />
                <h3><Icon icon="Online" className="heading-icon" />Say goodbye to paper bills and opt in to e-billing</h3>
                    { content.html('ebilling.signup', '<p>E-billing is fast and simple and helps reduce carbon emissions and it saves you recycling all those bills and paper envelopes. Simply click the button below to sign up.</p>') }
                    <Form
                        formFields={ {} }
                        className="in-box"
                        eventTitle="E-billing signup"
                        submitName="Sign up"
                        globalIdentifier="ebillingSignup"
                        loading={ permission.check('isCustomerLocked') }
                        hasChanged={ !permission.check('isCustomerLocked') }
                        valid={ validHandler }
                    />
            </Box>
        );
    }
    return null;
};

export default EbillingSignup;