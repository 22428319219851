import FieldSet from 'components/form/FieldSet';
import FlogasForm from 'components/partial/FlogasForm';
import InfoBox from 'components/ui/InfoBox';
import content from 'helper/content';
import permission from 'helper/permission';

const MovingProperty = (props) => {
    const formFields = {
        companyName: { label: 'Company Name', type: 'Text' },
        companyNumber: { label: 'Company Registration Number', type: 'Text' },
        vatNumber: { label: 'VAT Number', type: 'Text' },

        forwardingAddress: { label: 'Forwarding address', required: true, type: 'AddressLookup', targetFields: ['forwardingAddress1', 'forwardingAddress2', 'forwardingTown', 'forwardingCounty', 'forwardingPostcode'] },
        forwardingAddress1: { label: 'Forwarding address line 1', required: true, type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        forwardingAddress2: { label: 'Forwarding address line 2', type: 'Text', validator: 'CodasString', maxLength: 50 },
        forwardingTown: { label: 'Forwarding address town/city', required: true, type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        forwardingCounty: { label: 'Forwarding address county', required: false,  type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 50 },
        forwardingPostcode: { label: 'Forwarding address postcode', required: true, type: 'Text', validator: 'Postcode', maxLength: 12 },

        endDate: { label: 'Move date', required: true, type: 'Date' },
        percentage: { label: 'Percentage in tank', type: 'Number', min: 1, max: 100  },
    };
    const formSlug = permission.check('isMetered') ? 'moving-property-metered' : 'moving-property-commercial';
    return (
            <FlogasForm formFields={ formFields } form={formSlug} eventTitle="Moving property commercial" title="Moving property">
                { content.html('movingPropertyCommercial.intro', '<p>If you are moving out of a business with a Flogas tank, please complete the form below. </p>') }
                <div className="form-info-box-row">
                    <div className="form-info-box-row__fields">
                        <FieldSet fields={ ['companyName', 'companyNumber', 'vatNumber', 'forwardingAddress', 'endDate', 'percentage'] }/>
                        <FieldSet fields={ ['confirm'] }/>
                    </div>
                    <InfoBox className="text-small">
                        { content.html('movingPropertyCommercial.info', '<p>We only use your data to respond to your enquiry, for account matters (if you are a customer) and for our legitimate purposes of telling you news about Flogas products and services. We will not share your data (except for legal purposes). Find out more in our <a href="/help-support/page/privacy-policy">privacy policy</a> section.</p>' +
                            '<p>If you do not wish to for your information to be used to hear from us about Flogas products and services, <a href="/my-account#marketing-preferences">update these preferences online now</a>.</p>') }
                    </InfoBox>
                </div>
                <input type="submit" />
            </FlogasForm>
    );
};

export default MovingProperty;