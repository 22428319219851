import React, { useContext, useEffect, useState } from 'react';
import Box from 'components/ui/Box';
import 'scss/component/place-order-bulk/place-order-bulk.scss';
import RouteHeader from 'components/partial/RouteHeader';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import NotificationMessage from 'components/ui/NotificationMessage';
import { api, axios } from 'helper/api/client';
import MockData from 'components/ui/MockData';
import permission from 'helper/permission';
import content from 'helper/content';
import { useHistory, Link } from 'react-router-dom';
import Loading from 'components/ui/Loading';
import Button from 'components/ui/Button';
import UserContext from 'context/user';
import { getLastGaugeReadingDate } from 'helper/overview';

const PlaceOrderBulk = (props) => {
    const { user } = useContext(UserContext);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [fillType, setFillType] = useState('fill');
    useEffect(() => {
        if (permission.check('hasProductTerms')) {
            let source = axios.CancelToken.source();
            api.get('bulk/data', {}, { cancelToken: source.token }).then(response => {
                if (api.success(response)) {
                    setData(response.data.response);
                    setLoading(false);
                    //api.setCache(response, 1); - Don't cache
                }
            });
            return () => source.cancel();
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (data && data.currentOrder) {
            setFillType(data.currentOrder.type);
            setHasChanged(true);
        }
    }, [data]);
    const [hasChanged, setHasChanged] = useState(true);
    const getAmount = () => {
        if (data && data.currentOrder && data.currentOrder.type === 'amount') {
            return data.currentOrder.totalQuantity;
        }
        return '';
    }
    const formFields = {
        amount: { label: 'Enter an amount in litres', type: 'Number', required: true, min: (data ? data.minLitres : 1), max: (data ? data.maxLitres : null), placeholder: 'e.g. ' + (data ? data.maxLitres : 1000), fieldAfter: <span className="form__field-after">litres</span>, value: getAmount() },
        tankReading: { label: 'Tank gauge reading', type: 'Percentage', required: false, min: 1, max: 100, placeholder: 'e.g. 45', value: (data ? data.currentGaugeReading : '') },
        poNumber: { label: 'Purchase order number', type: 'Text', placeholder: 'If required', value: (data && data.currentOrder) && data.currentOrder.poNumber },
    };
    const typeChange = (event) => {
        setHasChanged(true);
        setFillType(event.target.value);
    }
    const validationHandler = (form) => {
        const { validFields } = form;
        if (fillType !== 'amount') {
            validFields.amount.valid = true;
        }
        return validFields;
    }
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess, setInvalidField } = form;
        api.post('bulk/order/create-update', { ...fieldValues, type: fillType })
            .then(response => {
                if (api.success(response, form)) {
                    console.log('/orders/place-order-bulk/' + response.data.response.id + '/summary')
                    api.cacheClear('bulk/order/' + response.data.response.id);
                    logActionSuccess();
                    history.push('/orders/place-order-bulk/' + response.data.response.id + '/summary');
                } else if (response.data.errors.poNumber) {
                    setInvalidField('poNumber', 'Your account requires you to enter a Purchase order number for this order');
                }
            }).catch(error => {
            api.error(error, form);
        });
    }
    const mockDataProps = { randomise: true, minWidth: 60 };
    const getCantOrder = () => {
        let text = content.html('bulkOrder.cantOrderText', '<p>Your account isn\'t set up to allow placing orders yet.</p><p>Please get in touch with us for assistance with your account.</p>');
        let button = <Button simple to="/help-support/contact">Contact us</Button>;
        if (data.requiresPayment && user.balance < 0) {
            text = content.html('bulkOrder.cwoNegativeBalance', '<p>You have an outstanding balance on your account that must be paid before you can place an order.</p>');
            button = <Button to="/payments/make-payment">Make a payment</Button>;
        }
        if (permission.check('isFinishDated')) {
            text = content.html('bulkOrder.cantOrderTextFinishDated', text);
        }
        if (permission.check('isOnStop')) {
            text = content.html('bulkOrder.cantOrderTextOnStop', text);
        }
        if (data.tankCapacity === 0) {
            text = content.html('bulkOrder.noCapacityText', '<p>We need to update your tank details on our system.</p><p>Please get in touch with us to update these details.</p>');
        }
        return (
            <>
            <h3>{ content.text('bulkOrder.cantOrderTitle', 'Unable to place order') }</h3>
            { text }
            { button }
           </>
        );
    }
    const getPlaceOrderForm = () => {
        return (
            <>
                <h3>Place your order today</h3>
                { loading && [...Array(4)].map((x, i) => <MockData key={ i } { ...mockDataProps } />) }
                { !loading && <Form
                    formFields={ formFields }
                    eventTitle="Bulk place order"
                    className="in-box"
                    valid={ validHandler }
                    validation={ validationHandler }
                    hasChanged={ hasChanged }
                    submitName="Review order"
                    submitOutline={ false }
                >
                    { !data.requiresPayment &&
                    <div className={ 'place-order-bulk__selection' + (fillType === 'fill' ? ' place-order-bulk__selection--selected' : '') }>
                        <div className="form__field-radio">
                            <input type="radio" id="amountType-fill" name="amountType" value="fill" onChange={ typeChange } defaultChecked={ fillType === 'fill' } />
                            <label htmlFor="amountType-fill">Fill my tank</label>
                        </div>
                        <p>We’ll aim to fill your tank to the maximum 85% capacity.</p>
                    </div> }

                    { data.canEnterQuantity &&
                    <div className={ 'place-order-bulk__selection' + (fillType === 'amount' ? ' place-order-bulk__selection--selected' : '') }>
                        <div className="form__field-radio">
                            <input type="radio" id="amountType-amount" name="amountType" value="amount" onClick={ typeChange } defaultChecked={ fillType === 'amount' }  />
                            <label htmlFor="amountType-amount">Order a specific quantity</label>
                        </div>
                        <div className="place-order-bulk__selection-content">
                            <FieldSet fields={ ['amount'] } />
                            <NotificationMessage>Your order must be between { data.minLitres } and { data.maxLitres } litres</NotificationMessage>
                        </div>
                    </div> }
                    <p>Provide us with your current tank reading to help calculate how much gas you'll need when we deliver.</p>
                    <FieldSet fields={ ['poNumber', 'tankReading'] } />
                </Form> }
            </>
        )
    }
    const canOrder = () => {
        if (data.requiresPayment && user.balance < 0) {
            return false;
        }
        return permission.check('hasProductTerms') && !permission.check('isOnStop') && !permission.check('isFinishDated') && (!data.requiresPayment || data.canEnterQuantity) && data.tankCapacity > 0;
    }
    const updateOrderValidHandler = (form) => {
        const { fieldValues, setSubmittedMessage, setErrorMessage, enableForm, logActionSuccess } = form;
        api.post('bulk/order/update', fieldValues).then(response => {
            if (api.success(response, form)) {
                logActionSuccess();
                setSubmittedMessage(content.html('bulkOrder.tankGaugeSubmitted', '<p>Your tank gauge reading has been submitted.</p>'));
            }
        }).catch(error => {
            enableForm();
            setErrorMessage('Unable to submit tank gauge reading');
        });
    }
    const getUpdateOrderForm = () => {
        const formFields = {
            reading: { label: 'Tank gauge reading', type: 'Percentage', required: true, min: 1, max: 100, placeholder: 'e.g. 45' },
        };
        return (
            <>
                <h3>You have an order in progress</h3>
                <h4>Order reference <Link to={ '/orders/previous/item/' + data.order.id }>{ data.order.reference }</Link></h4>
                <NotificationMessage type="warning">{ content.html('bulkOrder.inProgressWarning', 'You cannot place a new order with one in progress') }</NotificationMessage>
                <Form formFields={ formFields }
                      valid={ updateOrderValidHandler }
                      submitOutline={ false }
                      className="in-box"
                      eventTitle="Place order tank gauge submission">
                    { content.html('bulkOrder.orderInProgress', '<p>We need a tank gauge reading to accurately calculate your usage.<br />Enter your reading below.</p>') }
                    <div className="form__fields-row">
                        <div className="form__fields-row-field form__fields-row-field--col-6">
                            <FieldSet fields={ ['reading'] }/>
                        </div>
                    </div>
                </Form>
            </>
        );
    }
    return (
        <>
            <RouteHeader route={ props.route }/>
            { process.env.NODE_ENV === 'development' && data && data.currentOrder && <NotificationMessage>Debug for testing: Quote ID { data.currentOrder.quoteId ? data.currentOrder.quoteId : 'not set' }</NotificationMessage> }
            <div className="place-order-bulk">
                <div className="place-order-bulk__column place-order-bulk__column--left">
                    <Box padding="large">
                        { permission.check('isFinishDated') && <>
                            <h3>Your account is finish dated</h3>
                            { content.html('bulkOrder.finishDated', '<p>You cannot place an order.</p>') }
                        </> }
                        { loading && permission.check('hasProductTerms') && [...Array(6)].map((x, i) => <MockData key={ i } { ...mockDataProps } />) }
                        { !permission.check('hasProductTerms') && <>
                            <h3>{ content.text('bulkOrder.noTermsTitle', 'We\'re still setting up your account') }</h3>
                            { content.html('bulkOrder.noTermsText', '<p>Text to be confirmed.</p>') }
                        </> }
                        { !loading && !canOrder() && getCantOrder() }
                        { loading && <Loading className="place-order-bulk__loading" /> }
                        { !loading && canOrder() && !data.order && getPlaceOrderForm() }
                        { !loading && canOrder() && data.order && getUpdateOrderForm() }
                    </Box>
                </div>
                { !permission.check('isFinishDated') && permission.check('hasProductTerms') &&
                <div className="place-order-bulk__column place-order-bulk__column--right">
                    <Box padding="large">
                        <div className="place-order-bulk__stats">
                            { loading && [...Array(2)].map((x, i) => <MockData key={ i } { ...mockDataProps } />) }
                            { !loading && data.remainingPercentage && data.remainingPercentage > 0 && <>
                                <p className="place-order-bulk__stats-title">{ content.text('bulkOrder.statsRemaining', 'Based on your previous usage our systems estimate your tank level to be at') }</p>
                                <div className="place-order-bulk__stats-remaining">
                                    { data.remainingPercentage && <p className="place-order-bulk__stats-remaining-percentage">{ data.remainingPercentage }%</p> }
                                    { data.daysRemaining && <p className="place-order-bulk__stats-remaining-days">{ data.daysRemaining } day{ data.daysRemaining !== 1 ? 's' : '' } of gas remaining *</p> }
                                </div>
                                { data.daysRemaining && <p className="place-order-bulk__stats-remaining-asterisk">* Based on your last reading { getLastGaugeReadingDate(data, true) }</p> }
                            </> }
                            { !loading && (!data.remainingPercentage || data.remainingPercentage < 0) && <p className="place-order-bulk__stats-no-remaining">{ content.text('bulkOrder.statsNoRemaining', 'We don\'t have enough data to calculate your percentage remaining at the moment') }</p> }
                        </div>
                    </Box>
                </div> }
            </div>
        </>
    );
};

export default PlaceOrderBulk;