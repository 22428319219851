import { getClassName } from 'helper/bem';
import Icon from 'components/ui/Icon';

const PaginationItem = (props) => {
    const onClick = (event) => {
        const page = parseInt(event.currentTarget.dataset.page);
        props.paginationAction(page);
    }
    const clickable = props.page > 0 && props.page <= props.totalPages && props.page !== props.currentPage;
    let className = (props.page === props.currentPage ? 'active' : (clickable ? '' : ' disabled'));
    return (
        <li className={ getClassName('pagination__item', className) }>
            { clickable ? <button title={ props.title } className="pagination__item-link" onClick={ onClick } data-page={ props.page }>{ props.children }</button> :
                <span title={ props.title } className="pagination__item-link">{ props.children }</span> }
        </li>
    );

}
const Pagination = (props) => {
    const totalPages = Math.ceil(props.total / props.numberPerPage);
    const maxPages = 5;
    const page = parseInt(props.page);
    let pages = [];
    let firstPage = 1;
    let lastPage = totalPages;
    if (totalPages > maxPages) {
        firstPage = page - Math.floor(maxPages / 2)
        if (firstPage < 1) {
            firstPage = 1;
        }
        lastPage = firstPage + (maxPages - 1);
        if (lastPage > totalPages) {
            lastPage = totalPages;
            firstPage = totalPages - (maxPages - 1);
        }
    }

    for (let page = firstPage; page <= lastPage; page++) {
        pages.push(page);
    }
    if (props.total === 0 || totalPages <= 1) {
        return null;
    }
    return (
        <ul className={ getClassName('pagination', props.className) }>
            { totalPages > maxPages && firstPage > 1 && <PaginationItem title="First page" paginationAction={ props.paginationAction } currentPage={ page } page={ 1 } totalPages={ totalPages }><Icon icon="PaginationStart"/></PaginationItem> }
            <PaginationItem title="Previous page" paginationAction={ props.paginationAction } currentPage={ page } page={ page - 1 } totalPages={ totalPages }><Icon icon="PaginationLeft"/></PaginationItem>
            { pages.map((value, index) => {
                return <PaginationItem title={ 'Go to page ' + value } key={ index } paginationAction={ props.paginationAction } currentPage={ page } page={ value } totalPages={ totalPages }>{ value }</PaginationItem>
            }) }
            <PaginationItem title="Next page" paginationAction={ props.paginationAction } currentPage={ page } page={ page + 1 } totalPages={ totalPages }><Icon icon="PaginationRight"/></PaginationItem>
            { totalPages > maxPages && lastPage < totalPages && <PaginationItem title="Last page" paginationAction={ props.paginationAction } currentPage={ page } page={ totalPages } totalPages={ totalPages }><Icon icon="PaginationEnd"/></PaginationItem> }
        </ul>
    )
}

export default Pagination;