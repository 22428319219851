import NotificationMessage from 'components/ui/NotificationMessage';
import moment from 'moment';

const BalancePendingMessage = (props) => {
    let timeToUpdate = 'take up to a few hours';
    const today = moment();
    const endOfMonth = moment().clone().endOf('month');
    if (today.isSame(endOfMonth)) {
        if (today.day() === 2) {
            timeToUpdate = 'until Monday';
        }
    }
    return <NotificationMessage>Please note your { props.itemType } may { timeToUpdate } to update.</NotificationMessage>
}

BalancePendingMessage.defaultProps = {
    itemType: 'balance'
}

export default BalancePendingMessage;