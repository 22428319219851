import FlogasForm from 'components/partial/FlogasForm';

const FormInstallationQuery = (props) => {
    const formFields = {
        query: { label: 'Query', required: true, type: 'Textarea', rows: 10 },
    };
    return (
        <FlogasForm formFields={ formFields } form="installation-query" eventTitle="Installation query" title="Installation query" />
    );
};

export default FormInstallationQuery;