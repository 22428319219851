import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';
import OverviewPanelFooter from '../structure/OverviewPanelFooter';

import content from 'helper/content';
import Button from 'components/ui/Button';


const modifierClass = 'finish-dated';

const OverviewNoCapacity = ({ data }) => {
    const title = content.text('overview.noCapacityTitle', 'We need to update your tank details');
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle className={ modifierClass } title={ title } />
                <OverviewPanelBody className={ modifierClass }>
                    { content.html('overview.noCapacityText', '<p>We need to update your tank details on our system.</p><p>Please get in touch with us to update these details.</p>') }
                </OverviewPanelBody>
                <OverviewPanelFooter>
                    <div className="overview-panel__buttons">
                        <Button simple to="/help-support/contact">Contact us</Button>
                    </div>
                </OverviewPanelFooter>
            </OverviewPanel>
        </>
    );
}
export default OverviewNoCapacity;