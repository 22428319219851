import React, { useContext, useEffect, useState } from 'react';
import Box from 'components/ui/Box';
import 'scss/component/place-order-bulk/place-order-bulk.scss';
import RouteHeader from 'components/partial/RouteHeader';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import NotificationMessage from 'components/ui/NotificationMessage';
import { api } from 'helper/api/client';
import MockData from 'components/ui/MockData';
import { useHistory, useParams } from 'react-router-dom';
import { getAddress } from 'helper/address';
import UserContext from 'context/user';
import Button from 'components/ui/Button';
import content from 'helper/content';

const PlaceOrderBulkSummary = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const formFields = {
        deliveryInstructions: { label: 'Special delivery instructions', type: 'Text', rows: 1, maxLength: 50, showCharactersRemaining: true, placeholder: 'Include opening times or helpful information...', value: order && order.deliveryInstructions },
        poNumber: { label: 'Purchase order number', type: 'Text', placeholder: 'If required', value: order && order.poNumber },
    };
    useEffect(() => {
        if (!order) {
            api.get('bulk/order/' + id).then(response => {
                if (api.success(response)) {
                    setOrder({ ...response.data.response.order, requiresPayment: response.data.response.requiresPayment, showPriceToCustomer: response.data.response.showPriceToCustomer });
                    api.setCache(response, 1);
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                api.error(error);
            });
        }
    }, [order, id]);
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess, setInvalidField } = form;
        api.post('bulk/order/confirm', fieldValues)
            .then(response => {
                if (api.success(response, form)) {
                    if (response.data.response.paymentId) {
                        window.location = '/payments/make-payment/pay/' + response.data.response.paymentId;
                    } else {
                        history.push('/orders/place-order-bulk/' + id + '/confirmation');
                    }
                    logActionSuccess();
                } else if (response.data.errors.poNumber) {
                    setInvalidField('poNumber', 'Your account requires you to enter a Purchase order number for this order');
                }
            }).catch(error => {
            api.error(error, form);
        });
    }
    return (
        <>
            <RouteHeader route={ props.route }/>
            { process.env.NODE_ENV === 'development' && order && <NotificationMessage>Debug for testing: Quote ID { order.quoteId ? order.quoteId : 'not set' }</NotificationMessage> }
            <div className="place-order-bulk">
                <div className="place-order-bulk__column place-order-bulk__column--left">
                    <Box padding="large">
                        <h3>Order summary</h3>
                        { loading && [...Array(6)].map((x, i) => <MockData key={ i } randomise />) }
                        { !loading && order === null && <>
                            <p>Failed to load your order.</p>
                            <p><Button to="/orders/place-order-bulk" outline>Please try again</Button></p>
                        </>
                        }
                        { order && <Form
                            formFields={ formFields }
                            eventTitle="Bulk confirm order"
                            className="in-box place-order-bulk-summary"
                            valid={ validHandler }
                            hasChanged
                            submitName={ (order.requiresPayment ? 'Proceed to payment' : 'Complete order') }
                            submitOutline={ false }
                        >
                            <div className="place-order-bulk__summary">
                                { order.items.map((orderItem, index) => {
                                    return (
                                        <div className="place-order-bulk__summary-row" key={index}>
                                            <div className="place-order-bulk__summary-row-title">
                                                <strong>Quantity: </strong>
                                                <span>{ order.type === 'fill' ? 'Fill Tank with' : orderItem.quantity + ' litres of' } Bulk propane</span>
                                            </div>
                                            { order.showPriceToCustomer && orderItem.itemExc !== 0 &&
                                            <div className="place-order-bulk__summary-row-price">
                                                <strong>Price: </strong>
                                                <span>{ orderItem.itemExc } pence per { order.costUnit } (+ { orderItem.vatRate }% VAT)</span>
                                            </div> }
                                        </div>)
                                })
                                }
                                { order.showPriceToCustomer && order.items[0].itemExc > 0 && <NotificationMessage>{ content.html('bulkOrder.infoBox' + (order.type === 'fill' ? 'Fill' : 'Amount'), 'This is an estimated payment based on your current tank level. Actual total will be based on amount used to fill to 85% at time of delivery.') }</NotificationMessage> }
                            </div>
                            <FieldSet fields={ ['deliveryInstructions', 'poNumber'] } />
                            <div className="form-row">
                                <Button to="/orders/place-order-bulk" outline>Back</Button>
                                <input type="submit" />
                            </div>
                        </Form> }
                    </Box>
                </div>
                <div className="place-order-bulk__column place-order-bulk__column--right">
                    <div className="place-order-bulk__address">
                        <Box padding="large">
                            <h4>Delivery address</h4>
                            <address>
                                { !loading && getAddress(user.deliveryAddress) }
                                { loading && [...Array(5)].map((x, i) => <MockData key={ i } randomise />) }
                            </address>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PlaceOrderBulkSummary;