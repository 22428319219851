import React, { useEffect, useState } from 'react';
import Box from 'components/ui/Box';
import 'scss/component/place-order-bulk/place-order-bulk.scss';
import RouteHeader from 'components/partial/RouteHeader';
import { api } from 'helper/api/client';
import { useParams } from 'react-router-dom';
import Button from 'components/ui/Button';
import content from 'helper/content';
import FaqsBox from 'components/partial/FaqsBox';
import MockData from 'components/ui/MockData';
import log from 'helper/log';
import permission from "helper/permission";

const PlaceOrderBulk = (props) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    useEffect(() => {
        if (!order) {
            api.get('bulk/order-confirmation/' + id).then(response => {
                if (api.success(response)) {
                    setOrder(response.data.response.order);
                    if (window.localStorage.getItem('orderSent' + id) === null) {
                        log.action('Form submission successful', 'Order confirmed bulk', 'portal_order_confirmed_bulk', {
                            event_category: 'Orders',
                            total_litres: (response.data.response.order.totalQuantity > 0 ? response.data.response.order.totalQuantity : null),
                            order_value: response.data.response.order.totalExc / 100
                        });
                        window.localStorage.setItem('orderSent' + id, true);
                    }
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                api.error(error);
            });
        }
    }, [order, id]);
    return (
        <>
            <RouteHeader route={ props.route }/>
            <Box padding="large">
                { loading && [...Array(3)].map((x, i) => <MockData key={ i } randomise />) }
                { !loading && !order && <><h3>Invalid order</h3><p>The order <strong>{ id }</strong> cannot be loaded.</p></> }
                { !loading && order && <>
                <h3>Thank you for your order</h3>
                <p>Order number <strong>{ id }</strong></p>
                { content.html('order.confirmationDelivery', '<p>Your order will be delivered within 10 working days.</p>') }
                <div className="box__buttons">
                    { order.status === 'api_success' && <Button to="/payments/make-payment">Make a payment</Button> }
                    {!permission.check('isDirectDebitType') &&
                        <Button to="/form/direct-debit-sign-up" outline>Set up direct debit</Button>
                    }
                </div>
                </> }
            </Box>
            { !loading && <FaqsBox settingName="orderConfirmation" defaultFaqs={ [77003, 76999, 77022] } /> }
        </>
    );
};

export default PlaceOrderBulk;