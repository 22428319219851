import React, { useState, useEffect } from 'react';
import { api, axios } from 'helper/api/client';
import RouteHeader from 'components/partial/RouteHeader';
import Table from 'components/table/Table';
import Box from 'components/ui/Box';
import Form from 'components/form/Form';
import moment from 'moment';
import { getFilter, getFilterNoResultsString, setHistoryState } from 'helper/filter';
import FieldSet from 'components/form/FieldSet';
import { useLocation, Link } from 'react-router-dom';
import Button from 'components/ui/Button';
import permission from 'helper/permission';
import printHandler from 'helper/print';
import format from 'helper/format';
import { fromToValidationHandler } from 'helper/date';

const Orders = (props) => {
    const location = useLocation();
    const sorts = { dateDesc: 'Newest to oldest', date: 'Oldest to newest' };
    const [orders, setOrders] = useState([]);
    const [after, setAfter] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState({ ...getFilter({ sortBy: 'dateDesc', from: '', to: '' }), page: 1, after: null });
    const formFields = {
        from: { label: 'From', type: 'Date', value: filter.from, placeholder: moment().subtract(1, 'month').format('DD/MM/YYYY') },
        to: { label: 'To', type: 'Date', value: filter.to, placeholder: moment().format('DD/MM/YYYY') },
        sortBy: { label: 'Sort', type: 'Dropdown', options: sorts, showAll: false, value: filter.sortBy }
    };
    useEffect(() => {
        let source = axios.CancelToken.source();
        setLoading(true);
        api.get('orders/list', filter, {
            cancelToken: source.token,
        }).then(response => {
            setOrders(response.data.response.items);
            api.setPerformanceMetrics(response);
            setAfter((response.data.response.after ? response.data.response.after : '')); // Must be '' as null us used to determine whether to show it or not
            setLoading(false);
            setError(false);
            api.setCache(response);
        }).catch(error => {
            api.error(error);
            setError(true);
        });
        return () => source.cancel();
    }, [filter]);

    const getOrders = (page, after) => {
        setFilter({ ...filter, page, after });
        setAfter(after);
    }
    const filterHandler = (form) => {
        const { fieldValues, enableForm } = form;
        const params = { ...fieldValues, page: 1 };
        setHistoryState(params);
        setFilter(params);
        enableForm();
    };
    const getTitle = () => {
        let title = 'Orders';
        if (filter.from) {
            title += ' from ' + filter.from;
        }
        if (filter.to) {
            title += ' to ' + filter.to;
        }
        return title;
    }
    let newWindow;
    const download = (event) => {
        newWindow = window.open('/loading.html', 'orders');
    }
    const downloadSuccess = (response, apiData) => {
        let openInNewWindow = (navigator.pdfViewerEnabled !== undefined)
            ? navigator.pdfViewerEnabled
            : ! (navigator.mimeTypes["application/pdf"] !== undefined && navigator.mimeTypes["application/pdf"].enabledPlugin);

        if (openInNewWindow) {
            newWindow.location = response.data.response.pdf;
        } else {
            newWindow.close();
            setTimeout(() => {
                window.location = response.data.response.pdf;
            }, 200);
        }
    }
    const downloadFailure = (response, apiData) => {
        newWindow.close();
    }
    const theadConfig = {
        date: { className: 'date', content: 'Date' },
        reference: { className: 'label-left', content: 'Ref' },
        product: { content: 'Product' },
        status: { className: '', content: 'Status' },
        total: { className: 'amount', content: 'Total' },
        view: { className: 'button', content: '' }
    };
    if (permission.check('isCylinder')) {
        delete theadConfig.amount;
    }
    if (permission.check('isBulk')) {
        delete theadConfig.product;
    }
    if (permission.check('isMetered')) {
        delete theadConfig.product;
        delete theadConfig.status;
    }
    const tableData = orders.map((orderItem) => {
        return {
            date: orderItem.date,
            reference: orderItem.reference,
            product: orderItem.product,
            status: orderItem.status,
            total: format.currency(orderItem.total),
            view: <Link to={ location.pathname + '/item/' + orderItem.id }>View</Link>
        }
    });

    return (
        <>
            <RouteHeader route={ props.route }/>
            <Box padding="large">
                <h3>Previous orders</h3>
                <Form formFields={ formFields }
                      eventTitle="Orders filter"
                      filter={ true }
                      showSubmit={ false }
                      valid={ filterHandler }
                      validation={ fromToValidationHandler }
                      submitName="Filter">
                    <FieldSet fields={ ['from', 'to', 'type', 'sortBy'] }/>
                </Form>
                <div className="box__header">
                    <div className="box__buttons">
                        <Button to="/payments/make-payment" outline>Make a payment</Button>
                        {!permission.check('isDirectDebitType') &&
                            <Button to="/form/direct-debit-sign-up" outline>Set up a direct debit</Button>
                        }
                    </div>
                    <div className="box__buttons">
                        <Button simple onClick={ printHandler } disabled={ loading || !tableData.length } icon="Print">Print</Button>
                        <Button simple onClick={ download } apiAction="orders/downloads" apiCache={ 5 } apiData={ filter } apiSuccess={ downloadSuccess } apiFailure={ downloadFailure } disabled={ loading || !tableData.length } icon="Download">Download page PDF</Button>
                    </div>
                </div>
                { (loading || tableData.length > 0) &&
                <Table
                    extraClass="table--orders"
                    boxSize="large"
                    head={ theadConfig }
                    body={ tableData }
                    export={ true }
                    title={ getTitle() }
                    page={ (filter ? filter.page : 1) }
                    after={ after }
                    paginationAction={ getOrders }
                    numberPerPage={ 12 }
                    loading={ loading }
                    error={ error }
                /> }
                { !loading && tableData.length === 0 && <p>There are no orders { getFilterNoResultsString(filter) }</p> }
            </Box>
        </>
    )
}

export default Orders;