import React, { useEffect, useState } from 'react';
import Button from 'components/ui/Button';
import Table from 'components/table/Table';
import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';
import { api, axios } from 'helper/api/client';

import 'scss/component/overview-gas-bottles/overview-gas-bottles.scss';
import { Link } from 'react-router-dom';

const modifierClass = 'dashboard-gas-bottles';

const OverviewGasBottles = (props) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let source = axios.CancelToken.source();
        setLoading(true);
        api.get('overview/cylinder-orders', {
            cancelToken: source.token,
        }).then(response => {
            setOrders(response.data.response.items);
            setLoading(false)
            api.setCache(response);
        }).catch(error => {
            api.error(error);
        });
        return () => source.cancel();
    }, []);

    const theadConfig = {
        date: { className: 'date', content: 'Date' },
        reference: { className: 'label-left number', content: 'Order No.' },
        product: { className: 'label-left', content: 'Product' },
        status: { className: 'label-left', content: 'Status' },
        view: { className: 'button', content: '' }
    };

    const tableData = orders.map((orderItem) => {
        return {
            date: orderItem.date,
            reference: orderItem.reference,
            product: orderItem.product,
            status: orderItem.status,
            view: <Link to={ 'orders/previous/item/' + orderItem.id }>View</Link>
        }
    });

    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle className={ modifierClass }>
                    <h3>Recent Orders</h3>
                    <div className="overview-panel__buttons">
                        <Button to="/orders/place-order-gas-bottles" simple={ true }>Place an order</Button>
                        {/*<Button to="/orders/place-order-gas-bottles?repeat" simple={ true }>Repeat last order</Button>*/}
                    </div>
                </OverviewPanelTitle>
                <OverviewPanelBody className={ modifierClass }>
                    { !loading && tableData.length === 0 &&
                    <p>You have no recent orders</p> }
                    { (loading || tableData.length > 0) &&
                    <Table
                        head={ theadConfig }
                        body={ tableData }
                        striped={ false }
                        extraClass={ modifierClass }
                        loading={ loading }
                    /> }
                </OverviewPanelBody>
            </OverviewPanel>
        </>
    );
}
export default OverviewGasBottles;