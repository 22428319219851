import { getLastGaugeReadingDate } from 'helper/overview';

const OverviewTankRemaining = ({ data }) => {
    return (
        <>
            <h3 className="overview-panel-small__title">Current tank level</h3>
            <div className="overview-panel-small__body">
                <p className="overview-panel-small__body-percentage">{ data.remainingPercentage }%</p>
                { data.daysRemaining ? <p className="overview-panel-small__body-percentage-text">{ data.daysRemaining } day{ data.daysRemaining !== 1 ? 's' : '' } of gas remaining *</p> : ''}
            </div>
            <div className="overview-panel-small__footer">
                <p className="overview-panel-small__footer-small">* Based on your last reading { getLastGaugeReadingDate(data, true) }</p>
            </div>
        </>
    )
}
export default OverviewTankRemaining;