const tabPadding = 20;

export const getStyle = (tabsNavElement, tabClassName) => {
    return {
        left: getLeft(tabsNavElement, tabClassName),
        width: getActiveWidth(tabsNavElement, tabClassName),
    }
}

const getTabChildren = (tabsNavElement) => {
    if (tabsNavElement && tabsNavElement.current && tabsNavElement.current.children) {
        return tabsNavElement.current.children;
    }
    return false;
}
const getLeft = (tabsNavElement, tabClassName) => {
    let left = 0;
    const children = getTabChildren(tabsNavElement);
    if (children) {
        for (const tab of children) {
            if (tab.className === tabClassName + ' ' + tabClassName + '--active') {
                break;
            } else if (tab.className === tabClassName) {
                left += tab.offsetWidth;
            }
        }
    }
    return left + 'px';
}

const getActiveWidth = (tabsNavElement, tabClassName) => {
    const child = getActiveChild(tabsNavElement, tabClassName);
    const children = getTabChildren(tabsNavElement);
    if (children && child) {
        if (child === children[children.length - 1]) {
            // Last child has padding right 0
            return child.offsetWidth - tabPadding + 'px';
        } else {
            return child.offsetWidth - (tabPadding * 2) + 'px';
        }
    }
    return null;
}

const getActiveChild = (tabsNavElement, tabClassName) => {
    const children = getTabChildren(tabsNavElement);
    if (children) {
        for (const tab of children) {
            if (tab.className === tabClassName + ' ' + tabClassName + '--active') {
                return tab
            }
        }
    }
    return null;
}

export const getActiveTabByHash = (location, tabs) => {
    let active = '';
    if (location.hash.length > 1) {
        active = location.hash.substr(1);
    }
    if (active.length && typeof tabs[active] !== 'undefined') {
        return active;
    }
    return Object.keys(tabs)[0];
};

export const getActiveTabPath = (pathArray, tabs) => {
    let active = '';
    if (pathArray.length > 1) {
        if (pathArray[1] === 'page') {
            active = pathArray.slice(1, 3).join('/');
        } else {
            active = pathArray.slice(1, 2).join('/');
        }
    }
    if (active.length && typeof tabs[active] !== 'undefined') {
        return active;
    }
    return Object.keys(tabs)[0];
};