import FlogasForm from 'components/partial/FlogasForm';

const FormMeterEnquiry = (props) => {
    const formFields = {
        query: { label: 'Query', required: true, type: 'Textarea', rows: 10 },
    };
    return (
        <FlogasForm formFields={ formFields } form="meter-enquiry" eventTitle="Meter enquiry" title="Meter enquiry" />
    );
};

export default FormMeterEnquiry;