import React from 'react';
import Form from 'components/form/Form';
import { fromToValidationHandler } from 'helper/date';
import FieldSet from 'components/form/FieldSet';
import moment from 'moment';
import { setHistoryState } from 'helper/filter';

const UsageFilter = ({ data, filter, setFilter }) => {
    const formFields = {
        from: { label: 'From', type: 'Date', value: filter.from, placeholder: moment().subtract(1, 'month').format('DD/MM/YYYY') },
        to: { label: 'To', type: 'Date', value: filter.to, placeholder: moment().format('DD/MM/YYYY') }
    };
    const filterHandler = (form) => {
        const { fieldValues, enableForm } = form;
        const params = { ...fieldValues, page: 1 };
        setHistoryState(params);
        setFilter(params);
        enableForm();
    };
    return (
        <Form formFields={ formFields }
              eventTitle="Usage filter"
              filter={ true }
              showSubmit={ false }
              valid={ filterHandler }
              validation={ fromToValidationHandler }
              submitName="Filter">
            <FieldSet fields={ ['from', 'to', 'type', 'sortBy'] }/>
        </Form>
    );
};

export default UsageFilter;