import React, { useReducer } from 'react';

import Modal from 'portal/Modal';
import { modalInitialState, modalReducer } from 'helper/modalReducer';
import Button from 'components/ui/Button';
import content from 'helper/content';

import 'scss/component/need-help/need-help.scss';
import setting from 'helper/setting';

const NeedHelp = (props) => {
    const [modal, dispatchModal] = useReducer(modalReducer, { ...modalInitialState, open: false });
    const openHelp = (event) => {
        dispatchModal({ type: 'open', yesOption: null });
    }
    const telephone = setting.get('cecTelephone', '0800 574 574');
    const helpContent = () => {
        return <>
            <div className="need-help-intro">{ content.html('help.intro', '<p>If you’re still having trouble signing in, please get in touch.</p>') }</div>
            <div className="need-help">
                <div className="need-help__column"><h5>Call us free on:</h5><p className="need-help__tel"><a href={ 'tel:' + telephone.replace(/ /g, '') }>{ telephone }</a></p></div>
                <div className="need-help__column">{ content.html('help.openingHours', '<h5>We\'re open:</h5><p>Monday to Thursday: 8am-5pm<br>Friday: 8am-4.30pm<br>Saturday: 8am-1pm</p>') }</div>
            </div>
        </>
    }
    if (props.visible) {
        return (
            <>
                <Button onClick={ openHelp } simple size="small">Need help?</Button>
                <Modal { ...modal } dispatchModal={ dispatchModal } title={ 'Need help?' }>{ helpContent() }</Modal>
            </>
        );
    }
    return null;
}

export default NeedHelp;