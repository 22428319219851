import React, { useState } from 'react';

import RegisterStage1 from 'components/partial/auth/RegisterStage1';
import RegisterStageVerify from 'components/partial/auth/RegisterStageVerify';
import RegisterStageAlternative from 'components/partial/auth/RegisterStageAlternative';
import RegisterStagePassword from 'components/partial/auth/RegisterStagePassword';
import RegisterStageComplete from 'components/partial/auth/RegisterStageComplete';
import RegisterStageAdded from 'components/partial/auth/RegisterStageAdded';

import 'scss/component/register/register.scss';

const Register = (props) => {
    const initialFormData = {
        accountNumber: null,
        postcode: null,
        email: null,
        phoneNumber: null,
        invoiceNumber: '',
    }
    const [formStage, setFormStage] = useState('1');
    const [formData, setFormData] = useState(initialFormData);
    const [createdUser, setCreatedUser] = useState(null);
    const [verifyData, setVerifyData] = useState({ email: '', phoneNumber: '', phoneNumber2: '', mobileNumber: '' });

    const stepProps = {
        setFormStage,
        initialFormData,
        formData,
        setFormData,
        verifyData,
        setVerifyData,
        createdUser,
        setCreatedUser,
        addAccount: props.addAccount
    }
    return (
        <div className={ 'register' + (props.addAccount ? ' register--add-account' : '') }>
            { formStage === '1' && <RegisterStage1 { ...stepProps } /> }
            { formStage === 'verify' && <RegisterStageVerify { ...stepProps } /> }
            { formStage === 'alternative' && <RegisterStageAlternative { ...stepProps } /> }
            { formStage === 'password' && <RegisterStagePassword { ...stepProps } /> }
            { formStage === 'complete' && <RegisterStageComplete { ...stepProps } /> }
            { formStage === 'added' && <RegisterStageAdded { ...stepProps } /> }
        </div>
    );
};

Register.defaultProps = {
    addAccount: false
}

export default Register;