import RouteHeader from 'components/partial/RouteHeader';

import Box from 'components/ui/Box';

const NextDelivery = (props) => {
    return (
        <>
            <RouteHeader route={ props.route }/>
            <Box>
                <p>TODO</p>
            </Box>
        </>
    );
};

export default NextDelivery;