import Button from 'components/ui/Button';
import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';
import OverviewPanelFooter from '../structure/OverviewPanelFooter';
import OverviewTankUsage from 'components/partial/overview/panel/generic/OverviewTankUsage';
import OverviewLorry from 'components/partial/overview/panel/generic/OverviewLorry';
import content from 'helper/content';
import setting from 'helper/setting';
import permission from 'helper/permission';


import 'scss/component/overview-remaining-box/overview-remaining-box.scss';

const modifierClass = 'auto-ordering-telemetry';

const OverviewAutoOrderingTelemetry = (props) => {
    const { data } = props;
    const title = (data.hasOrder ? 'Your order' : 'Your usage');
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                { props.overview && <OverviewPanelTitle className={ modifierClass } title={ title }/> }
                <OverviewPanelBody className={ modifierClass }>
                    { data.hasOrder && <>
                            { content.html('overview.autoOrdering', '<p>You don\'t need to worry about ordering your own gas, we\'re monitoring your usage and gas levels to place your orders for you. If you\'re below 10%, please contact us for more information about your order.</p>') }
                            <OverviewLorry data={ data } />
                        </>
                    }
                    { (!data.hasOrder) && <OverviewTankUsage data={ data } /> }
                    { (!data.hasOrder) && permission.check('hasTelemetry') && data.daysRemaining && <p class="overview-panel__caveat">{ content.text('overview.daysUsageCaveat', '* This reading is based on the gas used in the last {days} days and will continue to update as your usage changes.', {days: setting.get('customer.telemetryOverviewDays', 30) }) }</p> }
                </OverviewPanelBody>
                { props.overview &&
                <OverviewPanelFooter>
                    { data.remainingPercentage >= data.warningThreshold &&
                    <div className="overview-panel__buttons">
                        {/*<Button simple to="/view-all-bulk-data">View all bulk data</Button>*/}
                        <Button simple to="/help-support/contact?step1=telemetryUsage">Report a telemetry fault</Button>
                        { data.hasOrder && <Button simple to="/telemetry">Review usage</Button> }
                    </div> }
                </OverviewPanelFooter> }
            </OverviewPanel>
        </>
    );
}


OverviewAutoOrderingTelemetry.defaultProps = {
    showTitle: true
}

export default OverviewAutoOrderingTelemetry;