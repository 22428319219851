import { api, axios } from 'helper/api/client';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import url from 'helper/url';

import 'scss/component/overview-promotion/overview-promotion.scss';

const OverviewPromotion = () => {
    const [loading, setLoading] = useState(true);
    const [promotion, setPromotion] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    useEffect(() => {
        let source = axios.CancelToken.source();
        api.get('overview/promotion', {}, { cancelToken: source.token })
            .then(response => {
                if (response.data.response.promotion) {
                    setPromotion(response.data.response.promotion);
                    setLoading(false);
                }
                api.setCache(response);

            }).catch(error => {
                api.error(error);
        });
        return () => source.cancel();
    }, []);
    useEffect(() => {
        const setBackgroundImageForSize = () => {
            if (promotion) {
                if (promotion.image && (window.innerWidth >= 767 || !promotion.imageMobile) && promotion.image !== backgroundImage) {
                    setBackgroundImage(promotion.image);
                } else if (promotion.imageMobile && (window.innerWidth < 767 || !promotion.image) && promotion.imageMobile !== backgroundImage) {
                    setBackgroundImage(promotion.imageMobile);
                }
            }
        }
        setBackgroundImageForSize();
        window.addEventListener('resize', setBackgroundImageForSize);
        return () => {
            window.removeEventListener('resize', setBackgroundImageForSize);
        };
    }, [promotion, backgroundImage]);
    if (loading) {
        return null;
    }
    return (
        <div className="overview-promotion" style={ (backgroundImage && { backgroundImage: 'url(\'' + backgroundImage + '\')' }) }>
            <p className="overview-promotion__title">{ promotion.title }</p>
            { url.isFull(promotion.linkTo) && <a className="overview-promotion__link" href={ promotion.linkTo } target="portalExternal">{ promotion.linkTitle }</a> }
            { !url.isFull(promotion.linkTo) && <Link className="overview-promotion__link" to={ promotion.linkTo }>{ promotion.linkTitle }</Link> }
        </div>
    );
}

export default OverviewPromotion;