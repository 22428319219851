export const getType = (type, colon = true) => {
    const typeMap = {
        cylinder: 'Cylinder',
        bulk: 'Bulk',
        metered: 'Metered',
    }
    return (typeof typeMap[type] !== 'undefined' ? typeMap[type] + (colon ? ': ' : '') : '');
}
export const getPostcode = (postcode) => {
    if (postcode && postcode.length) {
        return ' - ' + postcode;
    }
    return '';
}