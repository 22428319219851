import React, { useState, useEffect } from 'react';
import { getAllowedAttributes } from 'helper/form/fields';

const Text = (props) => {
    const [maxCharactersRemaining, setMaxCharactersRemaining] = useState(null);
    const allowedAttributes = [
        'name',
        'id',
        'type',
        'value',
        'autoComplete',
        'disabled',
        'placeholder',
        'minLength',
        'maxLength',
        ...props.allowedAttributes
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    const keyUp = (event) => {
        if (props.showCharactersRemaining && props.maxLength) {
            setMaxCharactersRemaining(parseInt(props.maxLength) - event.target.value.length);
        }
    }
    useEffect(() => {
        if (props.showCharactersRemaining && props.maxLength) {
            setMaxCharactersRemaining(parseInt(props.maxLength) - props.value.length);
        }
    }, [props.showCharactersRemaining, props.value, props.maxLength]);
    return (
        <>
            <input className={ 'form__field-control form__field-control--' + props.type.toLowerCase() } { ...attributes } onKeyUp={ keyUp } onChange={ props.inputChangeHandler } onFocus={ props.inputChangeHandler }/>
            { props.showCharactersRemaining && props.maxLength && <p className="form__field-characters-remaining">{ maxCharactersRemaining } character{ maxCharactersRemaining !== 1 ? 's' : '' } remaining</p> }
        </>
    );
};

export default Text;