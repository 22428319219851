import React, { useState, useEffect } from 'react';
import { api, axios } from 'helper/api/client';
import Table from 'components/table/Table';
import Box from 'components/ui/Box';
import content from 'helper/content';
import format from 'helper/format';
import Button from 'components/ui/Button';
import 'scss/component/make-payment/make-payment.scss';
import log from 'helper/log';
import { useHistory } from 'react-router-dom';
import NotificationMessage from 'components/ui/NotificationMessage';
import global from 'helper/global';
import BalancePendingMessage from 'components/ui/BalancePendingMessage';

const MakePaymentTransaction = (props) => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [transactionsSelected, setTransactionsSelected] = useState([]);
    const [totalSelected, setTotalSelected] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        let source = axios.CancelToken.source();
        setLoading(true);
        api.get('payment/outstanding', {}, {
            cancelToken: source.token,
        }).then(response => {
            setTransactions(response.data.response.items);
            setLoading(false)
            api.setCache(response);
        }).catch(error => {
            api.error(error);
        });
        return () => source.cancel();
    }, []);
    useEffect(() => {
        const selectedTransactions = transactions.filter(transaction => transactionsSelected.includes(transaction.reference));
        let total = 0;
        for (const transaction of selectedTransactions) {
            total += transaction.total;
        }
        setTotalSelected(Math.round(total * 100) / 100);
    }, [transactionsSelected, transactions]);
    const toggleTransactionRow = (event) => {
        global.setCanUpdateCustomer(false);
        toggleTransaction(event.currentTarget.dataset.id);
    }
    const toggleTransactionCheckbox = (event) => {
        global.setCanUpdateCustomer(false);
        toggleTransaction(event.currentTarget.value);
    }
    const toggleTransaction = (transactonId) => {
        if (transactionsSelected.includes(transactonId)) {
            setTransactionsSelected(transactionsSelected.filter(transaction => transaction !== transactonId));
        } else {
            setTransactionsSelected([ ...transactionsSelected, transactonId ]);
        }
    }
    const createSuccess = (response, apiData) => {
        log.action('Form submission successful', 'Pay outstanding transactions', 'form_submission_successful');
        if (totalSelected === 0) {
            history.push('/payments/make-payment/confirmation/' + response.data.response.id);
        } else {
            // Must do a refresh due to CSP headers
            window.location = '/payments/make-payment/pay/' + response.data.response.id;
        }
    }
    const createFailure = (response, apiData) => {
        setErrorMessage('Failed to pay outstanding transactions');
    }

    const theadConfig = {
        checkbox: { className: 'checkbox', content: '' },
        reference: { content: 'Ref' },
        description: { content: 'Description' },
        purchased: { className: 'date', content: 'Purchased' },
        due: { className: 'date', content: 'Payment due' },
        total: { className: 'amount', content: 'Total' },
    };
    const tableData = transactions.map((transactionItem) => {
        return {
            _rowAction: toggleTransactionRow,
            _rowId: transactionItem.reference,
            _rowClass: (transactionsSelected.includes(transactionItem.reference) ? 'table-row-selected' : ''),
            checkbox: <div className="form__field-checkbox"><input type="checkbox" name="transaction[]" value={ transactionItem.reference } checked={ transactionsSelected.includes(transactionItem.reference) } onChange={ toggleTransactionCheckbox } /><label htmlFor="confirm"></label></div>,
            reference: transactionItem.reference,
            description: transactionItem.description,
            purchased: transactionItem.purchased,
            due: (transactionItem.due ? transactionItem.due : '-'),
            total: format.currency(transactionItem.total),
        }
    });
    const tableFoot = [
        [
            { className: 'total label', value: 'Total', colspan: 5 },
            { className: 'total amount', value: format.currency(totalSelected) },
        ]
    ];


    return (
        <Box padding="large">
            <div className="make-payment">
                <h3>Pay an outstanding transaction</h3>
                <BalancePendingMessage itemType="outstanding transactions" />
                { (tableData.length || loading) && <>
                    { content.html('payment.selectTransactions', '<p>Select the transactions you want to pay today and if you would like to use your account credit towards your final payment.</p>') }
                    <Table
                        extraClass="transactions"
                        boxSize="large"
                        head={ theadConfig }
                        body={ tableData }
                        foot={ tableFoot }
                        numberPerPage={ 5 }
                        loading={ loading }
                    />
                    <div className="make-payment__footer">
                        { errorMessage && <NotificationMessage type="warning">{ errorMessage }</NotificationMessage> }
                        <Button
                            apiAction="payment/create"
                            apiVerb="post"
                            apiData={ { amount: totalSelected, type: 'outstanding_transaction', transactions: transactionsSelected } }
                            apiSuccess={ createSuccess }
                            apiFailure={ createFailure }
                            disabled={ transactionsSelected.length === 0 || totalSelected < 0 }
                        >{ (totalSelected === 0 ? 'Allocate' : 'Pay') } selected <span>transactions</span></Button>
                    </div>
                    </>
                }
                { (!tableData.length && !loading) && <p>There are no outstanding transactions</p> }
            </div>
        </Box>
    )
}

export default MakePaymentTransaction;