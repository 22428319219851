import fieldValidator from 'helper/form/validation/Phone';

const preferenceOptions = {
    T: 'Phone number',
    M: 'Mobile number'
}
export const contactFields = {
    title: { label: 'Title', required: true, type: 'Text', validator: 'CodasString', maxLength: 10 },
    firstName: { label: 'First name', required: true, type: 'Text', validator: 'CodasFirstName', maxLength: 20 },
    surname: { label: 'Surname', required: true, type: 'Text', validator: 'CodasString', maxLength: 30 },
    email: { label: 'Email', required: true, type: 'Email', maxLength: 60 },
    phoneNumber: { label: 'Phone number', type: 'Text', validator: 'Phone', maxLength: 20 },
    phoneNumber2: { label: 'Phone number 2', type: 'Text', validator: 'Phone', maxLength: 20 },
    mobileNumber: { label: 'Mobile number', type: 'Text', validator: 'Phone', maxLength: 20 },
    phonePreference: { label: 'Phone number preference', allTitle: 'Any', type: 'Dropdown', options: preferenceOptions },
}
export const getPhoneNumber = (contact) => {
    if (contact.phonePreference === 'T' && (contact.phoneNumber.length || contact.phoneNumber2.length)) {
        return (contact.phoneNumber.length ? contact.phoneNumber : contact.phoneNumber2);
    } else if (contact.phonePreference === 'M' && contact.mobileNumber.length) {
        return contact.mobileNumber;
    } else if (contact.phoneNumber.length) {
        return contact.phoneNumber;
    } else if (contact.phoneNumber2.length) {
        return contact.phoneNumber2;
    } else if (contact.mobileNumber.length) {
        return contact.mobileNumber;
    }
    return '';

}
export const contactValidation = (form) => {
    const { fieldValues, setErrorMessage, validFields } = form;
    // Reset validation
    validFields.phoneNumber.valid = true;
    validFields.phoneNumber2.valid = true;
    validFields.mobileNumber.valid = true;
    // Set validation based on data
    const phoneFields = {
        phoneNumber: fieldValidator(contactFields.phoneNumber, fieldValues.phoneNumber),
        phoneNumber2: fieldValidator(contactFields.phoneNumber2, fieldValues.phoneNumber2),
        mobileNumber: fieldValidator(contactFields.mobileNumber, fieldValues.mobileNumber),
    };
    const validPhoneFields = Object.keys(phoneFields).reduce((valid, phoneField) => (phoneFields[phoneField].valid ? valid + 1 : valid), 0);
    if (fieldValues.phoneNumber.length) {
        validFields.phoneNumber = phoneFields.phoneNumber;
    }
    if (fieldValues.phoneNumber2.length) {
        validFields.phoneNumber2 = phoneFields.phoneNumber2;
    }
    if (fieldValues.mobileNumber.length) {
        validFields.mobileNumber = phoneFields.mobileNumber;
    }

    if (validPhoneFields < 1) {
        setErrorMessage('At least 1 phone number must be entered');
        validFields.phoneNumber.valid = phoneFields.phoneNumber.valid;
        validFields.phoneNumber2.valid = phoneFields.phoneNumber2.valid;
        validFields.mobileNumber.valid = phoneFields.mobileNumber.valid;

    } else if (fieldValues.phonePreference.length) {
        if (fieldValues.phonePreference === 'T' && !phoneFields.phoneNumber.valid && !phoneFields.phoneNumber2.valid) { // phoneNumber or phoneNumber2 must be entered
            setErrorMessage('One of the 2 phone numbers must be entered');
            validFields.phoneNumber.valid = phoneFields.phoneNumber.valid;
            validFields.phoneNumber2.valid = phoneFields.phoneNumber2.valid;
        } else if (fieldValues.phonePreference === 'M' && !phoneFields.mobileNumber.valid) { // mobileNumber must be entered
            setErrorMessage('Mobile number must be entered');
            validFields.mobileNumber = phoneFields.mobileNumber;
        }
    }
    return validFields;

}