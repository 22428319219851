import RouteHeader from 'components/partial/RouteHeader';

import Box from 'components/ui/Box';

const ReturnCylinder = (props) => {
    return (
        <>
            <RouteHeader route={ props.route }/>
            <Box>
                <p>TODO Phase 5</p>
            </Box>
        </>
    );
};

export default ReturnCylinder;