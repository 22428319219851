import Button from 'components/ui/Button';
import content from 'helper/content';
import { useContext } from 'react';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import AccountsContext from 'context/accounts';
import { api } from 'helper/api/client';
import log from 'helper/log';

const RegisterStageAdded = (props) => {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const { accounts, setAccounts } = useContext(AccountsContext);
    const goBack = (event) => {
        event.preventDefault();
        props.setFormStage('1');
    }
    const getAccountId = () => {
        const foundAccounts = accounts.filter(account => account.accountNumber === props.formData.accountNumber);
        if (foundAccounts.length) {
            return foundAccounts[0].id;
        }
        return false;
    }
    const switchAccount = (event) => {
        const accountId = getAccountId();
        if (accountId) {
            userContext.setSwitching(true);
            api.post('customer/accounts/switch', { id: accountId })
                .then(response => {
                    if (api.success(response)) {
                        log.action('Customer account', 'Switched', 'customer_account');
                        authContext.setToken(response.data.response.token);
                        userContext.setUser(response.data.response.customer);
                        setAccounts(response.data.response.customer.accounts);
                        props.history.push('/overview');
                        userContext.setSwitching(false);
                    }
                }).catch(error => {
                userContext.setSwitching(false);
            });
        }
    }
    return (
        <>
            { content.html('addAccount.added', '<h3>Your account has been added</h3><p>You have successfully added account {accountNumber}.</p><p>You will be able to access this account anytime from the dropdown menu at the top of your My Account.</p>', { accountNumber: props.formData.accountNumber }) }
            <div className="box__buttons">
                <Button action={ switchAccount } noPadding>Switch to account</Button>
                <Button action={ goBack } noPadding outline>Add another account</Button>
            </div>
        </>
    );
};

export default RegisterStageAdded;