import React from 'react';
import moment from 'moment';
import LoadingPanel from 'components/ui/LoadingPanel';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const UsageGraph = (props) => {
    const { usage } = props;
    const data = {
        labels: usage.map(usageItem => moment(usageItem.date).format('Do MMM Y')),
        datasets: [
            {
                label: 'Tank level %',
                data: usage.map(usageItem => {
                    return usageItem.level
                }),
                borderColor: '#00B3CF',
                backgroundColor: '#00B3CF',
                borderWidth: 2,
                pointRadius: 1,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: false,
            tooltip: {
                enabled: window.innerWidth >= 1024
            }
        },
        legend: false,
        scales: {
            yAxes: {
                title: {
                    display: true,
                    font: { size: 14 },
                    text: 'Tank level %'
                },
                suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                suggestedMax: 100,    // minimum will be 0, unless there is a lower value.
            },
            xAxes: {
                adapters: {
                    //date: {locale: enGB},
                    type: 'time',
                }
            }
        }
    };
    return (
        <div className="usage-graph">
            { props.loadingUsage && <LoadingPanel /> }
            <Line options={ options} data={data} />
        </div>
    );
};

export default UsageGraph;