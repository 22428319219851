import React, { useRef, useState, useContext, useEffect } from 'react';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import PropTypes from 'prop-types';
import Box from 'components/ui/Box';
import 'scss/component/legacy-iframe/legacy-iframe.scss';
import global from 'helper/global';
import { api, axios } from 'helper/api/client';
import Loading from 'components/view/Loading';
import RouteHeader from 'components/partial/RouteHeader';
import { useHistory } from 'react-router-dom';
import permission from 'helper/permission';
import debug from 'helper/debug';
import Icon from 'components/ui/Icon';

const LegacyIframe = (props) => {
    const history = useHistory();
    const authContext = useContext(AuthContext)
    const { user } = useContext(UserContext)
    const iFrameRef = useRef(null);
    const src = process.env.REACT_APP_LEGACY_URL + props.route.legacyPath;
    const [loggedIn, setLoggedIn] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canView, setCanView] = useState(false);
    global.setCanUpdateCustomer(false);
    useEffect(() => {
        let source = axios.CancelToken.source();
        api.legacyPost('check', { accountNumber: user.accountNumber, path: props.route.legacyPath }, { cancelToken: source.token }).then(response => {
            if (response.data.success === false) {
                api.legacyPost('login', { token: authContext.token }).then(response => {
                    setLoggedIn(response.data.success);
                    setCanView(true);
                });
            } else {
                setLoggedIn(true);
                setCanView(response.data.can_view);
                if (!response.data.can_view) {
                    setLoading(false);
                }
            }
        }).catch(error => {
            setLoading(false);
        });
        return () => source.cancel();
    }, [authContext.token, user.accountNumber, props.route]);
    window.addEventListener('message', (event) => {
        if (event.origin.startsWith(process.env.REACT_APP_LEGACY_URL.slice(0, -1))) {
            if (event.data.height !== undefined && loading) {
                setLoading(false);
            }
            if (event.data.scrollTop !== undefined && event.data.scrollTop) {
                window.scrollTo(0, 0);
            }
            if (event.data.height !== undefined && iFrameRef.current) {
                iFrameRef.current.style.height = event.data.height + 'px';
            }
            if (event.data.url && event.data.url.replace(process.env.REACT_APP_LEGACY_URL, '') !== props.route.legacyPath) {
                if (event.data.url.substring(0, 25) === 'https://www.flogas.co.uk/') {
                    window.location = event.data.url;
                } else {
                    if (permission.validRoute(event.data.url.replace(process.env.REACT_APP_LEGACY_URL, '/'))) {
                        history.push(event.data.url.replace(process.env.REACT_APP_LEGACY_URL, '/'));
                    } else {
                        debug.error('Invalid URL for customer: ' + event.data.url);
                    }
                }
            }
        } else {
            debug.error('Invalid source origin ' + event.origin);
        }
    });

    return (
        <>
            <RouteHeader route={ props.route }/>
            <div className="legacy-iframe">
                <div className={ 'legacy-iframe-loader' + (loading ? ' legacy-iframe-loader--loading' : '') }><Loading /></div>
                <Box padding="large" className={ (loggedIn === false && canView === false) || (loggedIn === true && canView === false) ? '' : 'hide' }><div className="legacy-iframe-error">
                    { loggedIn === false && canView === false && <>
                        <h3><Icon icon="Warning" />Error</h3>
                        <p>Failed to load, please try again later</p>
                        </> }
                    { loggedIn === true && canView === false && <>
                        <h3>We're still setting up your account</h3>
                        <p>Please try again later.</p>
                    </> }
                 </div></Box>
                { canView !== false && <div className={ 'legacy-iframe' + (loading ? ' legacy-iframe--loading' : '') }>
                    <iframe id="legacy-iframe" ref={iFrameRef} className="legacy-iframe__iframe" src={ loggedIn && canView ? src : '' } title={props.route.title}></iframe>
                </div> }
            </div>
        </>
    )
}

LegacyIframe.propTypes = {
    route: PropTypes.object.isRequired,
}

export default LegacyIframe;