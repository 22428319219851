import Td from 'components/table/Td';
import MockData from 'components/ui/MockData';

const TbodyLoading = (props) => {
    const loadingRows = (typeof props.numberPerPage === 'undefined' ? 5 : props.numberPerPage);
    const rows = new Array(loadingRows);
    rows.fill(null);
    return (
        <tbody>
        { rows.map((value, index) => {
            return (
                <tr key={ index }>{
                    Object.entries(props.head).map(([headIndex, heading]) => {
                        return <Td key={ headIndex } className={ heading.className }><MockData/></Td>
                    })
                }
                </tr>)
        }) }
        </tbody>
    );
};

export default TbodyLoading;