import React, { useContext, useEffect, useRef } from 'react';
import UserContext from 'context/user';
import { api } from 'helper/api/client';
import content from 'helper/content';
import permission from 'helper/permission';
import global from 'helper/global';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import InfoBox from 'components/ui/InfoBox';
import { useLocation } from 'react-router-dom';
import fieldValidator from 'helper/form/validation/Phone';
import ContactLocked from 'components/ui/ContactLocked';

const MarketingPreferences = () => {
    const { user, setUser } = useContext(UserContext);
    const hashTarget = useRef(null);
    const location = useLocation();
    useEffect(() => {
        if (location.hash.length && location.hash.substr(1) === 'marketing-preferences') {
            hashTarget.current.scrollIntoView();
        }
    });
    const marketingPreferences = user.contact.marketingPreferences;
    let formFields = Object.keys(marketingPreferences)
        .reduce((obj, key) => {
            obj[key] = {
                label: marketingPreferences[key].title,
                type: 'Checkbox',
                value: marketingPreferences[key].preference ? '1' : '0'
            }
            return obj;
        }, {});
    formFields.mobileNumber = { label: 'Mobile number', type: 'Text', validator: 'Phone', required: true, value: user.contact.mobileNumber, visibleWhen: { sms: '1' } };
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess } = form;
        api.patch('contact/update/' + user.contact.id + '/marketing-preferences', fieldValues).then(response => {
            if (api.success(response, form)) {
                global.formSuccess = 'marketingPreferences';
                global.successMessage = 'Marketing preferences updated successfully';
                logActionSuccess();
                const now = new Date();
                const newMarketingPreferences = Object.keys(user.contact.marketingPreferences)
                    .reduce((obj, key) => {
                        obj[key] ={ ...marketingPreferences[key], preference: fieldValues[key] === '1', created: now.toISOString() }
                        return obj;
                    }, {});
                setUser({
                    ...user,
                    permissions: { ...user.permissions, actions: { ...user.permissions.actions, isCustomerLocked: response.data.isCustomerLocked } },
                    contact: { ...user.contact, mobileNumber: fieldValues.mobileNumber, marketingPreferences: newMarketingPreferences }
                });
            } else if (response.data.isCustomerLocked) {
                global.formFailure = 'marketingPreferences';
                setUser({
                    ...user,
                    permissions: { ...user.permissions, actions: { ...user.permissions.actions, isCustomerLocked: response.data.isCustomerLocked } },
                });
            }
        }).catch(error => {
            api.error(error, form, 'Failed to update marketing preferences');
        });
    };
    const validation = (form) => {
        const { fieldValues, validFields } = form;
        if (fieldValues.sms !== '1') {
            validFields.mobileNumber = fieldValidator(formFields.mobileNumber, fieldValues.mobileNumber)
            validFields.mobileNumber.valid = true;
        }
        return validFields;
    }

    return (
        <div ref={ hashTarget }>
            <Form formFields={ formFields }
                  className="marketing-preferences"
                  eventTitle="Marketing preferences"
                  validation={ validation }
                  valid={ validHandler }
                  loading={ permission.check('isCustomerLocked') }
                  globalIdentifier="marketingPreferences"
                  initalScrollTo={ global.initialScrollTo('marketingPreferences') }
                  submitName="Update preferences">
                <ContactLocked />
                <div className="form-info-box-row">
                    <div className="form-info-box-row__fields">
                        <h3 className="form__title">Marketing preferences</h3>
                        { content.html('marketingPreferences.intro', '<p>We’d love to send you exclusive offers and the latest information from Flogas. Please let us know how you would like us to contact you:</p>') }
                        <div className="form-row">
                            <FieldSet fields={ Object.keys(formFields).filter(field => field !== 'mobileNumber') }/>
                        </div>
                        <FieldSet fields={ ['mobileNumber'] }/>
                    </div>
                    <InfoBox className="text-small">
                        { content.html('marketingPreferences.info', '<p>Please check your email address, mobile number and postal address are correct in your account to receive your selected marketing preferences.</p>') }
                    </InfoBox>
                </div>
            </Form>
        </div>
    );
};

export default MarketingPreferences;