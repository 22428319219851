import Button from 'components/ui/Button';
import { useContext } from 'react';
import UserContext from 'context/user';

const OverviewHelpAndSupport = (props) => {
    const { user } = useContext(UserContext);
    return (
        <>
            <h3 className="overview-panel-small__title">Help and support</h3>
            <div className="overview-panel-small__middle">
                <p className="overview-panel-small__middle-text">Looking for help with { user.type === 'cylinder' && 'gas bottles or ' }your account? We have the answers.</p>
            </div>
            <div className="overview-panel-small__footer"><Button to="help-support" simple>Get help and support</Button></div>
        </>
    );
}

export default OverviewHelpAndSupport;