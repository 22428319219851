const fieldValidator = (field, fieldValue) => {
    let response = { valid: true, validationMessage: '' }
    if (!fieldValue.length) {
        response.valid = false;
        if (field.errorMessage) {
            response.validationMessage = field.errorMessage;
        } else {
            response.validationMessage = field.label + ' is required';
        }
    } else if (fieldValue.length < field.minLength) {
        response.valid = false;
        response.validationMessage = field.label + ' must be a minimum of ' + field.minLength + ' character' + (field.minLength !== 1 ? 's' : '');
    } else if (fieldValue.length > field.maxLength) {
        response.valid = false;
        response.validationMessage = field.label + ' must be a maximum of ' + field.maxLength + ' character' + (field.maxLength !== 1 ? 's' : '');
    }
    return response;
}

export default fieldValidator;