import PropTypes from 'prop-types';
import Form from 'components/form/Form';
import { api } from 'helper/api/client';

const FlogasForm = (props) => {
    const getFormFields = (fieldVisibility) => {
        return Object.keys(props.formFields).filter(fieldKey => {
            return fieldVisibility[fieldKey] && props.formFields[fieldKey].type !== 'AddressLookup';
        });
    };
    const getInitialHasChanged = () => {
        return Object.keys(props.formFields).filter(fieldKey => {
            return props.formFields[fieldKey].required && !props.formFields[fieldKey].value;
        }).length < 1;
    };
    const getFormData = (fieldValues, fieldVisibility) => {
        return getFormFields(fieldVisibility).reduce((object, key) => {
            let value = fieldValues[key]
            if (['Radios', 'Dropdown'].includes(props.formFields[key].type)) {
                value = (props.formFields[key].options[value] ? props.formFields[key].options[value] : value);
            }
            object[key] = {
                    label: props.formFields[key].label,
                    required: props.formFields[key].required,
                    type: props.formFields[key].type,
                    value: value
                };
            return object;
        }, {});
    };
    const validHandler = (form) => {
        const { fieldValues, fieldVisibility, setSuccessMessage, resetForm, logActionSuccess } = form;
        api.post('contact-us/submit', { form: props.form, data: getFormData(fieldValues, fieldVisibility), fields: getFormFields(fieldVisibility) })
            .then(response => {
                if (api.success(response, form)) {
                    setSuccessMessage(props.successMessage);
                    resetForm();
                    logActionSuccess();
                    if (props.successCallback) {
                        props.successCallback(response, form);
                    }
                }
            }).catch(error => {
            api.error(error, form);
        });
    };
    return (
        <Form formFields={ props.formFields }
              eventTitle={ props.eventTitle }
              title={ props.showTitle && props.title }
              hasChanged={ getInitialHasChanged() }
              valid={ validHandler }
              submitName={ props.submitName }>
            { props.children }
        </Form>
    );
}

FlogasForm.defaultProps = {
    submitName: 'Send enquiry',
    successMessage: 'Message sent successfully',
    successCallback: null
};

FlogasForm.propTypes = {
    formFields: PropTypes.object.isRequired, // array of string references to the form fields
    form: PropTypes.string.isRequired, // form reference
    eventTitle: PropTypes.string.isRequired, // for events
    submitName: PropTypes.string.isRequired, // submit title
    successMessage: PropTypes.string.isRequired, // success message
    successCallback: PropTypes.func
}

export default FlogasForm;