import React, { useEffect, useState } from 'react';
import Box from 'components/ui/Box';
import setting from 'helper/setting';
import Button from 'components/ui/Button';
import { api, axios } from 'helper/api/client';
import FaqItem from 'components/ui/FaqItem';

import 'scss/component/faqs/faqs.scss';

const FaqsBox = (props) => {
    const faqIds = setting.get('faqs.' + props.settingName, props.defaultFaqs);
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openId, setOpenId] = useState(null);
    let className = 'faqs-list';
    useEffect(() => {
        if (faqIds) {
            let source = axios.CancelToken.source();
            setLoading(true);
            api.get('faqs/list', { faqIds: faqIds }, {
                cancelToken: source.token,
            }).then(response => {
                setFaqs(response.data.response.items);
                setLoading(false)
                api.setCache(response);
            }).catch(error => {
                api.error(error);
            });
            return () => source.cancel();
        }
    }, [faqIds]);
    if (faqs && faqs.length > 0) {
        return (
            <Box padding="large" noPrint>
                <h4>Frequently asked questions</h4>
                <div className={ className }>
                    { !loading && faqs.length === 0 && <p>There are no FAQs</p> }
                    { faqs && faqs.length > 0 && faqs.map((faq, index) => {
                        return <FaqItem key={ index } faq={ faq } openId={ openId } setOpenId={ setOpenId } />
                    })
                    }
                </div>
                <Button noPadding to="/help-support/faqs" simple={ true }>View all FAQs</Button>
            </Box>
        );
    }
    return null;
}

export default FaqsBox;