const fieldValidator = (field, fieldValue) => {
    let response = { valid: true, validationMessage: '' }
    if (!fieldValue.length) {
        fieldValue = 0;
    }
    fieldValue = parseInt(fieldValue);
    if (fieldValue < 1000000) {
        response.valid = false;
        response.validationMessage = field.label + ' is not long enough';
    }
    return response;
}

export default fieldValidator;