import { ReactComponent as TankSvg } from 'images/overview-tank.svg';
import { useEffect, useState } from 'react';

const OverviewTankSvg = ({ remainingPercentage }) => {
    const [loaded, setLoaded] = useState();
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 500);
    })
    return (
        <div className={ 'overview-tank-usage-tank' + (remainingPercentage < 30 ? ' overview-tank-usage-tank--low' : '') }>
            <div className="overview-tank-usage-tank__bar" style={{ 'height': (100 - remainingPercentage) + '%' }}>
                <div className={ 'overview-tank-usage-tank__bar-inner' + (loaded ? ' overview-tank-usage-tank__bar-inner--loaded' : '') } />
            </div>
            <TankSvg />
        </div>
    );
};
export default OverviewTankSvg;