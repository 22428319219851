import RegisterLegacy from 'components/partial/auth/Register';
import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';

const AddAccount = (props) => {
    return (
        <>
            <RouteHeader route={ props.route } />
            <Box padding="large" >
                <RegisterLegacy addAccount={ true } />
            </Box>
        </>
    );
};

export default AddAccount;