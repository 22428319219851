import { ReactComponent as LorrySvg } from 'images/overview-lorry.svg';

import 'scss/component/overview-lorry/overview-lorry.scss';
import content from 'helper/content';

const OverviewLorry = ({ data }) => {
    return (
        <div className="overview-lorry">
            <LorrySvg />
            <div className="overview-lorry__border"></div>
            <div className="overview-lorry__notification">
                { data.nextDeliveryDate && <p className="overview-lorry__notification-text">{ content.text('overview.orderBy', 'Your order is being processed and is due for delivery by') }</p> }
                { !data.nextDeliveryDate && <p className="overview-lorry__notification-soon-text">{ content.text('overview.orderByNoDate', 'Your order is being processed. Check back soon for more details.') }</p> }
                { data.nextDeliveryDate && <p className="overview-lorry__notification-date">{ data.nextDeliveryDate }</p> }
            </div>
        </div>
    )
}
export default OverviewLorry;