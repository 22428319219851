import Button from 'components/ui/Button';
import OverviewTankSvg from 'components/partial/overview/panel/generic/OverviewTankSvg';
import Icon from 'components/ui/Icon';
import NotificationMessage from 'components/ui/NotificationMessage';
import 'scss/component/overview-tank-usage/overview-tank-usage.scss';
import 'scss/component/overview-remaining-box/overview-remaining-box.scss';
import content from 'helper/content';
import permission from 'helper/permission';
import { getLastGaugeReadingDate } from 'helper/overview';

const OverviewTankUsage = ({ data }) => {
    return (
        <>
            <div className="overview-tank-usage">
                <div className="overview-tank-usage__col">
                    <OverviewTankSvg remainingPercentage={ data.remainingPercentage ?? data.lastGaugeReading }/>
                </div>
                <div className="overview-tank-usage__col">
                    { !data.remainingPercentage && !data.lastGaugeReading && <>
                        { content.html('overview.autoOrderingNoData', '<p><strong>No usage data</strong></p><p>We don\'t currently have enough data to show your usage.</p>') }
                        { data.lastGaugeReading < data.warningThreshold && <Button to="/orders/place-order-bulk" type="button" outline={ false } color={ "blue" }>Place an order</Button> }
                    </> }
                    { ((data.remainingPercentage || data.lastGaugeReading) && (data.remainingPercentage >= data.warningThreshold || data.lastGaugeReading >= data.warningThreshold || !permission.check('isOnCall'))) &&
                    <>
                        <div className="remaining-box">
                            <p className="remaining-box__title">{ data.remainingPercentage ? 'Current' : 'Latest' } tank level</p>
                            <div className="remaining-box__row">
                                <p className="remaining-box__row-percentage">{ data.remainingPercentage ?? data.lastGaugeReading }%</p>
                                { data.daysRemaining !== null && <p className="remaining-box__row-text">{ data.daysRemaining } day{ data.daysRemaining !== 1 ? 's' : '' } of <br/> gas remaining*</p> }
                            </div>
                            <p className="remaining-box__based-on">Based on your last reading { getLastGaugeReadingDate(data, true) }</p>
                        </div>
                        { permission.check('hasTelemetry') && data.usageThisWeekPercentage !== null && data.usageLastWeekPercentage !== null && data.usageLastWeekPercentage !== data.usageThisWeekPercentage &&
                        <div className="remaining-box remaining-box--versus">
                            <Icon icon="ArrowLarge" className={ 'remaining-box__arrow remaining-box__arrow--' + (data.usageThisWeekPercentage > data.usageLastWeekPercentage ? 'up' : 'down') }/>
                            <span>{ data.usageThisWeekPercentage }% used this week vs { data.usageLastWeekPercentage }% last week</span>
                        </div> }
                    </>
                    }
                    { data.remainingPercentage && data.remainingPercentage < data.warningThreshold && permission.check('isOnCall') &&
                        <div className="text-purple">
                        { permission.check('hasTelemetry') && <>
                            { !permission.check('isOnStop') && content.html('overview.placeOrderTelemetry', '<p>Based on your telemetry reading, you now need to place an order.</p><p>Orders will be delivered within 10 working days</p>') }
                        </> }
                        { !permission.check('hasTelemetry') && <>
                            { !permission.check('isOnStop') && content.html('overview.placeOrder', '<p>Based on your gauge reading, you now need to place an order.</p><p>Orders will be delivered within 10 working days</p>') }
                        </> }
                            { permission.check('isOnStop') && content.html('overview.placedOrderOnStop', '<p>To discuss placing an order please call 0345 050 0056.</p>') }
                        <Button to="/orders/place-order-bulk" type="button" outline={ false } color={ "blue" }>Place an order</Button>
                    </div>
                    }

                </div>
            </div>
            { data.remainingPercentage >= data.warningThreshold &&
                <NotificationMessage>{ content.text('overview.tankFillWarning', 'Your tank will only ever be filled to 85% due to gas expansion within the tank, when the temperature changes.') }</NotificationMessage>
            }
        </>
    );
}
export default OverviewTankUsage;