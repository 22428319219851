import FlogasForm from 'components/partial/FlogasForm';

const FormHistoricalInvoices = (props) => {
    const formFields = {
        query: {
            label: 'Details of invoice or statement required',
            fieldBefore: <p>Please include specific details and date range for any invoices or statements required</p>,
            required: true,
            type: 'Textarea',
            rows: 10
        },
    };
    return (
        <FlogasForm
            formFields={ formFields }
            form="historical-invoices"
            eventTitle="Historical invoice enquiry"
            title="Historical invoice or statement enquiry"
            successMessage="Thank you. Your invoices or statements will be sent to your email address. Please allow 5-7 days to process your request."
        />
    );
};

export default FormHistoricalInvoices;