import React, { useState, useContext, useRef, useReducer, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import AuthContext from 'context/auth';
import MenuContext from 'context/menu';
import Icon from 'components/ui/Icon';
import { modalReducer, modalInitialState } from 'helper/modalReducer';
import Modal from 'portal/Modal';
import { pathMatches } from 'helper/path';
import HeaderAccount from 'components/ui/HeaderAccount';
import HeaderSearch from 'components/ui/HeaderSearch';
import HeaderBalance from 'components/ui/HeaderBalance';
import permission from 'helper/permission';

import { useDetectOutsideClick } from 'helper/useDetectOutsideClick';
import Button from 'components/ui/Button';

const Header = () => {
    const history = useHistory();
    const [fixedHeader, setFixedHeader] = useState(false);
    const authContext = useContext(AuthContext);
    const menuContext = useContext(MenuContext);
    const location = useLocation();
    const [modal, dispatchModal] = useReducer(modalReducer, modalInitialState);
    const accountMenuRef = useRef(null);
    const searchRef = useRef(null);
    const header = useRef(null);
    const headerMobile = useRef(null);
    const [accountMenuOpen, setAccountMenuOpen] = useDetectOutsideClick(accountMenuRef, false);
    const [searchOpen, setSearchOpen] = useDetectOutsideClick(searchRef, false);
    const setFixed = () => {
        if (header.current !== null) {
            setFixedHeader(window.scrollY >= (header.current.clientHeight + 30 - headerMobile.current.clientHeight));
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', setFixed);
        return () => {
            window.removeEventListener('scroll', setFixed);
        };
    });
    const logOut = (event) => {
        dispatchModal({
            type: 'open',
            buttonEvent: event,
            title: 'Are you sure you want to log out?',
            yesAction: authContext.doLogOut,
            yesOption: 'Yes',
            noOption: 'No'
        });
    }
    const toggleSearch = (event) => {
        setAccountMenuOpen(false);
        setSearchOpen(!searchOpen);
    }
    const accountLink = (event) => {
        menuContext.setMenuOpen(false);
        history.push('/my-account');
    }
    const toggleMenu = (event) => {
        menuContext.setMenuOpen(!menuContext.menuOpen);
    }
    const switchBack = (event) => {
        window.localStorage.setItem('token', window.localStorage.getItem('_adminOriginalToken'));
        window.localStorage.removeItem('_adminOriginalToken', null);
        window.localStorage.removeItem('_adminId', null);

        document.location = '/overview';
    }
    return (
        <header ref={ header } className={ 'header' + (fixedHeader ? ' header--has-fixed' : '') }>
            <HeaderSearch forwardRef={ searchRef } searchOpen={ searchOpen } setSearchOpen={ setSearchOpen }/>
            <div ref={ headerMobile } className={ 'header__mobile' + (fixedHeader ? ' header__mobile--fixed' : '') }>
                <button onClick={ accountLink } className={ 'header__mobile-link' + (pathMatches(location, 'my-account') ? ' header__mobile-link--active' : '') }><Icon icon="Account"/></button>
                <button className="header__mobile-link header__mobile-link--menu" onClick={ toggleMenu }><span/><span/><span/></button>
            </div>
            <HeaderAccount forwardRef={ accountMenuRef } accountMenuOpen={ accountMenuOpen } setAccountMenuOpen={ setAccountMenuOpen }/>
            <HeaderBalance />
            <div className="header__links">
                <button onClick={ toggleSearch } className={ 'header__links-link' + (searchOpen ? ' header__links-link--active' : '') }><Icon icon="Search"/></button>
                { permission.check('canAdminSwitch') && <Link to="/admin-switch" className={ 'header__links-link' + (pathMatches(location, 'admin-switch') ? ' header__links-link--active' : '') }><Icon icon="AdminSwitch"/></Link> }
                { window.localStorage.getItem('_adminId') && <Button onClick={ switchBack } fullClassName="header__links-link" title="Switch back to admin account"><Icon icon="AdminSwitchBack"/></Button> }
                <Link to="/my-account" className={ 'header__links-link' + (pathMatches(location, 'my-account') ? ' header__links-link--active' : '') }><Icon icon="Account"/></Link>
                <button onClick={ logOut } className="header__links-link"><Icon icon="SignOut"/></button>
            </div>
            <Modal { ...modal } dispatchModal={ dispatchModal }>{ modal.content }</Modal>
        </header>
    )
}

export default Header;