import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';

const PlaceOrderCylinder = (props) => {
    return (
        <>
            <RouteHeader route={ props.route }/>
            <Box>
                <p>TODO</p>
            </Box>
        </>
    );
};

export default PlaceOrderCylinder;