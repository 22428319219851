import NotificationMessage from 'components/ui/NotificationMessage';
import permission from 'helper/permission';
import content from 'helper/content';

const ContactLocked = () => {
    if (permission.check('isCustomerLocked')) {
        return <NotificationMessage type="warning">{ content.text('contact.locked', 'Your account is pending an update and you cannot make changes at the moment') }</NotificationMessage>
    }
    return null;
}

export default ContactLocked;