const fieldValidator = (field, fieldValue) => {
    let response = { valid: true, validationMessage: '' }
    if (!fieldValue.length) {
        response.valid = false;
        response.validationMessage = field.label + ' is required';
    } else {
        fieldValue = parseFloat(fieldValue);
        if (isNaN(fieldValue)) {
            response.valid = false;
            response.validationMessage = field.label + ' must be a number';
        } else if (fieldValue < parseFloat(field.min)) {
            response.valid = false;
            response.validationMessage = field.label + ' must be ' + field.min + ' or greater';
        } else if (fieldValue > parseFloat(field.max)) {
            response.valid = false;
            response.validationMessage = field.label + ' must be ' + field.max + ' or lower';
        }
    }
    return response;
}

export default fieldValidator;