import React, { useContext } from 'react';
import Form from 'components/form/Form';

import { optionalFields, validateFormFields } from 'helper/form/validateRegister';
import content from 'helper/content';
import FieldSet from 'components/form/FieldSet';
import NotificationMessage from 'components/ui/NotificationMessage';
import { getRecordFields } from 'helper/form/fields';
import { apiClient, api } from 'helper/api/client';
import AuthContext from 'context/auth';
import UserContext from 'context/user';

const Revalidate = (props) => {
    const { setToken, setAuthenticated } = useContext(AuthContext);
    const { setUser } = useContext(UserContext);
    const formFields = getRecordFields({ ...validateFormFields, id: { label: 'ID', type: 'Hidden' } }, props.customer);
    /*formFields.email.required = true;
    formFields.phoneNumber.required = true;
    formFields.postcode.required = true;*/
    formFields.accountNumber.type = 'Hidden';
    const validHandler = (form) => {
        const { fieldValues, setInvalidFields, logActionSuccess, setErrorMessage } = form;
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + props.token;
        api.post('customer/revalidate', fieldValues)
            .then(response => {
                if (api.success(response, form)) {
                    if (response.data.response.customer) {
                        logActionSuccess();
                        setToken(response.data.response.token);
                        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.response.token;
                        setUser(response.data.response.customer);
                        setAuthenticated(true);
                        window.localStorage.setItem('customerSector', (props.remember ? response.data.response.customer.sector : ''));
                    } else {
                        setErrorMessage('Invalid information submitted, please check and try again');
                    }
                }
            }).catch(error => {
                api.error(error, form, 'Invalid information submitted, please check and try again');
                let newFieldValidation = {};
                for (const field of optionalFields) {
                    if (fieldValues[field].length) {
                        newFieldValidation[field] = '';
                    }
                }
                setInvalidFields(newFieldValidation);
        });
    };
    return (
        <Form formFields={ formFields }
              className="register"
              eventTitle="Login re-authenticate"
              valid={ validHandler }
              submitColour="purple"
              submitAlign="right"
              submitOutline={ false }
              submitName="Verify details">
            <NotificationMessage type="info">{ content.text('login.reauthenticateTitle', 'Welcome to the new Flogas My Account portal, we need you to verify your details with the missing information below') }</NotificationMessage>
            { content.html('login.reauthenticate', '<p>Please enter the following pieces of information for you account number <strong>{accountNumber}</strong></p>', { accountNumber: props.customer.accountNumber }) }
            <FieldSet fields={ ['accountNumber', 'phoneNumber', 'email', 'postcode', 'invoiceNumber'] }/>
        </Form>
    );
}

export default Revalidate;