import React, { useContext } from 'react';
import UserContext from 'context/user';
import ContactAddEditForm from 'components/partial/my-account/ContactAddEditForm';
import { getRecordFields } from 'helper/form/fields';
import { contactFields, contactValidation } from 'helper/contact';

import { api } from 'helper/api/client';
import permission from 'helper/permission';
import global from 'helper/global';

const ContactAddEdit = (props) => {
    const { user, setUser } = useContext(UserContext);
    const getContactById = (id) => {
        return props.contacts.find((contact, index) => contact.id === id);
    }
    const contact = (props.id ? getContactById(props.id) : { title: '', firstName: '', surname: '', email: '', phoneNumber: '', phoneNumber2: '', mobileNumber: '', phonePreference: '' }); // , address1: '', address2: '', town: '', county: '', postcode: ''
    const formFields = getRecordFields(contactFields, contact);
    const submitName = (props.id ? 'Update' : 'Add') + ' contact';
    const apiVerb = (props.id ? 'patch' : 'post');
    const apiUrl = 'contact/' + (props.id ? 'update/' + props.id : 'add');

    const validHandler = (form) => {
        const { fieldValues, setSuccessMessage, resetForm, logActionSuccess } = form;
        api[apiVerb](apiUrl, fieldValues)
            .then(response => {
                if (api.success(response, form)) {
                    if (props.id) {
                        props.dispatchContacts({ type: 'update', id: props.id, record: fieldValues });
                        if (contact.isMainContact) {
                            global.formSuccess = 'contactEditMain';
                            global.successMessage = 'Contact details updated successfully';
                            setUser({
                                ...user,
                                permissions: { ...user.permissions, actions: { ...user.permissions.actions, isCustomerLocked: response.data.isCustomerLocked } },
                                contact: { ...user.contact, ...fieldValues }
                            });

                        } else {
                            setSuccessMessage('Contact updated successfully');
                        }
                    } else {
                        resetForm();
                        setSuccessMessage('Contact added successfully');
                        api.cacheClear('contact/list');
                        props.setFilter({ page: 1, after: null });
                    }
                    api.cacheClear('contact/list');
                    logActionSuccess();
                    props.dispatchModal({ type: 'closeIn', time: 5000 });
                } else if (contact.isMainContact && response.data.isCustomerLocked) {
                    global.formFailure = 'contactEditMain';
                    setUser({
                        ...user,
                        permissions: { ...user.permissions, actions: { ...user.permissions.actions, isCustomerLocked: response.data.isCustomerLocked } },
                    });
                }
            }).catch(error => {
            api.error(error, form, 'Failed to update contact');
        });
    };
    const id = (props.id ? props.id : 0);

    return (
        <>
            <ContactAddEditForm
                modal={ true }
                formFields={ formFields }
                id={ id }
                loading={ contact && contact.isMainContact && permission.check('isCustomerLocked') }
                dispatchModal={ props.dispatchModal }
                eventTitle={ 'Contact ' + (id > 0 ? 'edit' : 'add') }
                mainContact={ contact && contact.isMainContact }
                globalIdentifier="contactEditMain"
                validation={ contactValidation }
                valid={ validHandler }
                submitName={ submitName }
            />
        </>
    );
};

export default ContactAddEdit;