import React, { forwardRef } from 'react';
import Icon from 'components/ui/Icon';
import { getClassName } from 'helper/bem';

const InfoBox = forwardRef((props, ref) => {
    let className = 'info-box';
    return <div ref={ ref } className={ getClassName(className, props.className) }>
        <div className="info-box__inner">
            <Icon icon="Info" className="info-box__icon" />{ props.children }
        </div>
    </div>;
});

export default InfoBox;