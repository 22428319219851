import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import { AuthContextProvider } from 'context/auth';
import { UserContextProvider } from 'context/user';
import { AccountsContextProvider } from 'context/accounts';
import { ConfigContextProvider } from 'context/config';
import { NavigationContextProvider } from 'context/navigation';
import { MenuContextProvider } from 'context/menu';
import { TabsContextProvider } from 'context/tabs';

ReactDOM.render(
    <AccountsContextProvider>
        <UserContextProvider>
            <AuthContextProvider>
                <ConfigContextProvider>
                    <MenuContextProvider>
                        <NavigationContextProvider>
                            <TabsContextProvider>
                                <App/>
                            </TabsContextProvider>
                        </NavigationContextProvider>
                    </MenuContextProvider>
                </ConfigContextProvider>
            </AuthContextProvider>
        </UserContextProvider>
    </AccountsContextProvider>, document.getElementById('root'));