import Button from './Button';
import CollapseButton from 'components/ui/CollapseButton';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getClassName } from 'helper/bem';


const ListItem = (props) => {
    const [open, setOpen] = useState(false);
    const toggle = (event) => {
        event.preventDefault();
        setOpen(!open);
    }
    const buttonProps = { size: 'small', colour: 'blue', outline: true, simple: true };
    const getPassedButtonProps = (buttonProps) => {
        // Remove title from button props
        return Object.keys(buttonProps).reduce((object, key) => {
            if (key !== 'title') {
                object[key] = buttonProps[key]
            }
            return object
        }, {});
    }
    if (props.edit && !props.edit.title) {
        props.edit.title = 'Edit';
    }
    if (props.delete && !props.delete.title) {
        props.delete.title = <span className="list-item__actions-link-text">Delete</span>;
        props.delete.icon = 'Delete';
        props.delete.iconOnly = true;
    }
    return (
        <div className={ getClassName('list-item', (open ? ' open' : '') + (props.className ? ' ' + props.className : '')) }>
            <div className="list-item__inner">
                <div className="list-item__title">
                    <CollapseButton open={ open } toggle={ toggle }/>
                    { props.children }
                </div>
                <div className="list-item__actions">
                    <div className="list-item__actions-inner">
                        { props.view && <Button { ...buttonProps } { ...getPassedButtonProps(props.view) } fullClassName="list-item__actions-link list-item__actions-link--view">{ props.view.title }</Button> }
                        { props.edit && <Button { ...buttonProps } { ...getPassedButtonProps(props.edit) } fullClassName="list-item__actions-link list-item__actions-link--edit">{ props.edit.title }</Button> }
                        { props.delete && <Button { ...buttonProps } { ...getPassedButtonProps(props.delete) } fullClassName="list-item__actions-link list-item__actions-link--delete">{ props.delete.title }</Button> }
                    </div>
                </div>
            </div>
        </div>
    );
}

ListItem.defaultProps = {
    edit: {},
    delete: {},
};

ListItem.propTypes = {
    children: PropTypes.object.isRequired,
    edit: PropTypes.object,
    delete: PropTypes.object,
}

export default ListItem;