import React from 'react';
import Button from 'components/ui/Button';
import setting from 'helper/setting';
import content from 'helper/content';

const OverviewSubmittedGaugeReading = ({ data }) => {
    return (
        <>
            <div className="overview-panel-small__body overview-panel-small__body--no-title">
                <p className="overview-panel-small__body-large">{ content.text('overview.submittedReadingTitle', 'Please remember that we advise you to order at {bulkWarningThreshold}% to avoid running out.', { bulkWarningThreshold: setting.get('overview.bulkWarningThreshold', 40) }) }</p>
                <p className="overview-panel-small__body-small">{ content.text('overview.submittedReadingText', 'We recommend ordering when your tank is around {bulkWarningThreshold}% to allow time for your order to be processed. Orders will be delivered within 10 working days.', { bulkWarningThreshold: setting.get('overview.bulkWarningThreshold', 40) }) }</p>
            </div>
            {(data.lastGaugeReading >= data.warningThreshold) &&
                <div className="overview-panel-small__footer">
                    <Button to="/orders/place-order-bulk">Place an order</Button>
                </div>
            }
        </>
    )
}
export default OverviewSubmittedGaugeReading;