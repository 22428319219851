import React from 'react';
import format from 'helper/format';
import permission from 'helper/permission';
import Button from 'components/ui/Button';

const OverviewSupplyAgreement = ({ data }) => {
    return (
        <>
            <h3 className="overview-panel-small__title">Your supply agreement</h3>
            <div className="overview-panel-small__body">
                <p className="overview-panel-small__body-secondary">Your price: { format.number(data.price) } pence / { data.salesUnit }</p>
                <p className="overview-panel-small__body-secondary">Your Standing charge: { data.standingCharge } pence / day</p>
                <p className="overview-panel-small__body-secondary">Contract end date: { data.contractEndDate ?? 'N/A' }</p>
            </div>
            <div className="overview-panel-small__footer">
                { permission.check('isContractDueToExpire') && <Button href="https://www.flogas.co.uk/home/renew" simple>Discuss renewal options</Button> }
            </div>
        </>
    );
}

export default OverviewSupplyAgreement;