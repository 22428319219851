import OverviewPanel from 'components/partial/overview/panel/structure/OverviewPanel';
import OverviewPanelTitle from 'components/partial/overview/panel/structure/OverviewPanelTitle';
import OverviewPanelBody from 'components/partial/overview/panel/structure/OverviewPanelBody';
import OverviewPanelFooter from 'components/partial/overview/panel/structure/OverviewPanelFooter';
import content from 'helper/content';
import permission from 'helper/permission';
import Button from 'components/ui/Button';


const modifierClass = 'error';

const OverviewError = () => {
    const title = content.text('overview.errorTitle', 'Error loading overview');
    let errorContent = permission.check('isMetered') ? content.html('overview.errorTextMetered', null) : null;
    if (!errorContent) {
        errorContent = content.html('overview.errorText', '<p>Sorry - this is taking longer than expected! Please try again later.</p>');
    }
    return (
        <>
            <OverviewPanel className={ modifierClass }>
                <OverviewPanelTitle className={ modifierClass } title={ title } />
                <OverviewPanelBody className={ modifierClass }>{ errorContent }</OverviewPanelBody>
                <OverviewPanelFooter>
                    <div className="overview-panel__buttons">
                        <Button simple to="/help-support/contact">Contact us</Button>
                    </div>
                </OverviewPanelFooter>
            </OverviewPanel>
        </>
    );
}
export default OverviewError;