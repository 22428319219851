import React, { useContext, useEffect, useState } from 'react';

import { api } from 'helper/api/client';
import AccountsContext from 'context/accounts';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import Form from 'components/form/Form';
import FieldSet from 'components/form/FieldSet';
import Hero from 'components/partial/Hero';
import Button from 'components/ui/Button';
import InfoBox from 'components/ui/InfoBox';
import NotificationMessage from 'components/ui/NotificationMessage';
import content from 'helper/content';
import { registerValidation } from 'helper/form/changePassword';
import log from 'helper/log';
import 'url-search-params-polyfill';
import 'scss/component/register/register.scss';

const AutoRegister = (props) => {
    const { setToken, setAuthenticated } = useContext(AuthContext);
    const { setUser } = useContext(UserContext);
    const { setAccounts } = useContext(AccountsContext);
    const token = (new URLSearchParams(window.location.search)).get('token');
    const redirectSeconds = 3;
    const invalidTokenDefaultMessage = 'There was a problem activating your online account, please contact us on 0800 574 574.';
    const accountExistsDefaultMessage = 'You have already registered for My Account, please login using the details you previously provided.';

    let formFields = {
        password: { label: 'Password', required: true, type: 'PasswordStrength', value: process.env.REACT_APP_TEST_PASSWORD, emailField: 'accountEmail' },
        passwordConfirm: { label: 'Confirm password', required: true, type: 'PasswordStrength', value: process.env.REACT_APP_TEST_PASSWORD, passwordField: 'password', emailField: 'accountEmail' },
    }
    const [autoRegisterState, setAutoRegisterState] = useState('init');

    useEffect(() => {
        setAutoRegisterState('validatingToken');
        api.post('customer/auto-register/validate', { token: token })
            .then(response => {
                if (response.data.errors && response.data.errors.autoRegistrationCustomerAlreadyRegistered) {
                    setAutoRegisterState('accountExists');
                } else if (response.data.errors) {
                    throw new Error('Invalid token');
                } else {
                    setAutoRegisterState('ready');
                }
            }).catch(error => {
                setAutoRegisterState('invalidToken');
            });
    }, [token]);

    const registrationComplete = (response) => {
        if (response.data.response.customer) {
            setToken(response.data.response.token);
            log.setCustomer(response.data.response.customer);
            log.action('Form submission successful', 'Auto register complete', 'form_submission_successful');
            setUser({ ...response.data.response.customer });
            setAccounts(response.data.response.customer.accounts);
            setAuthenticated(true);
        } else if (response.data.response.legacyOnly) {
            api.legacyPost('login', { token: response.data.response.token }).then(response => {
                window.location = process.env.REACT_APP_LEGACY_URL;
            });
        }
    };

    const validHandler = (form) => {
        const { fieldValues, logActionSuccess, setErrorMessage, enableForm } = form;
        api.post('customer/auto-register/register', { token: token, password: fieldValues.password })
            .then(response => {
                if (response.data.errors && response.data.errors.failedValidateRegistrationDuplicate) {
                    setErrorMessage(content.text('autoRegister.accountExists', accountExistsDefaultMessage));
                    enableForm();
                } else if (api.success(response, form)) {
                    logActionSuccess();
                    setAutoRegisterState('success');

                    setTimeout(registrationComplete, (redirectSeconds * 1000), response);
                } else {
                    throw new Error();
                }
            }).catch(error => {
            api.error(error, form, 'Invalid information submitted, please try again');
        });
    };
    const getError = () => {
        return <>
            <NotificationMessage type="warning">{content.text('autoRegister.' + autoRegisterState, autoRegisterState === 'accountExists' ? accountExistsDefaultMessage : invalidTokenDefaultMessage)}</NotificationMessage>
            <Button href="/auth">Login</Button>
        </>

    };

    return (
        <>
            <Hero title={ props.route.title } class="hero--auto-register"/>
            {autoRegisterState === 'success' && content.html('autoRegister.complete', <p>Thank you for activating, you will be automatically taken to the overview screen in {redirectSeconds} seconds…</p>, { redirectSeconds })}
            {['invalidToken', 'accountExists'].includes(autoRegisterState) && getError()}
            {['init', 'validatingToken', 'ready'].includes(autoRegisterState)
                && <>
                    { content.html('autoRegister.intro', '<h2>Now let\'s finish activating your&nbsp;account!</h2> <p>Just create and confirm your password below and you’re ready to access My Account.</p>') }
                    <Form formFields={ formFields }
                      visible={ true }
                      loading={ autoRegisterState !== 'ready' }
                      eventTitle="Auto register complete"
                      logActionSuccess={ false }
                      valid={ validHandler }
                      validation={ registerValidation }
                      submitAlign="right"
                      submitOutline={ false }
                      submitColour="purple"
                      submitName="Activate account">

                    <div className="form__autocomplete">
                        <input name="password" type="password"  />
                    </div>
                    <InfoBox className="text-small">
                        { content.html('register.newPassword', '<p>Passwords must be at least 12 characters, contain at least one letter, one uppercase letter, one number and one symbol. Passwords are case sensitive.</p>') }
                    </InfoBox>
                    <FieldSet fields={ ['password', 'passwordConfirm'] }/>
                </Form>
            </>}
        </>
    );
};

export default AutoRegister;