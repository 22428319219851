import React, { useContext, useRef } from 'react';
import Form from 'components/form/Form';
import AccountsContext from 'context/accounts';

import content from 'helper/content';

import { api } from 'helper/api/client';
import FieldSet from 'components/form/FieldSet';

import InfoBox from 'components/ui/InfoBox';

import accountNumberImage from '../../../images/account-number.png';
import Icon from 'components/ui/Icon';

const RegisterStage1 = (props) => {
    const { accounts } = useContext(AccountsContext);
    const accountNumberInfoBox = useRef(null);
    const accountNumberIconClick = (event) => {
        event.preventDefault();
        if (accountNumberInfoBox.current) {
            if (accountNumberInfoBox.current.classList.contains('info-box--hidden')) {
                accountNumberInfoBox.current.classList.remove('info-box--hidden');
            } else {
                accountNumberInfoBox.current.classList.add('info-box--hidden');
            }
        }
    }
    const formFields = {
        accountNumber: { label: 'Account number', type: 'Text', required: true, validator: 'AccountNumber', placeholder: 'e.g. 1234567', fieldAfter: <button type="button" onClick={ accountNumberIconClick } className="form__info-icon" tabIndex={-1}><Icon icon="Info" /></button> },
        postcode: { label: 'Postcode', type: 'Text', required: true, validator: 'Postcode', placeholder: 'e.g. AB1 2CD' },
    };
    if (props.formData.accountNumber) {
        formFields.accountNumber.value = props.formData.accountNumber;
    } else if (process.env.REACT_APP_TEST_ACCOUNT_NUMBER) {
        formFields.accountNumber.value = process.env.REACT_APP_TEST_ACCOUNT_NUMBER;
    }
    if (props.formData.postcode) {
        formFields.postcode.value = props.formData.postcode;
    } else if (process.env.REACT_APP_TEST_POSTCODE) {
        formFields.postcode.value = process.env.REACT_APP_TEST_POSTCODE;
    }
    const validation = (form) => {
        const { fieldValues, validFields } = form;
        if (fieldValues.accountNumber.trim().length) {
            const foundAccounts = accounts.filter(account => account.accountNumber === fieldValues.accountNumber.trim());
            if (foundAccounts.length) {
                validFields.accountNumber.validationMessage = 'This account number is already linked with your account';
                validFields.accountNumber.valid = false;
            }
        }
        return validFields;
    }
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess, setInvalidFields, setErrorMessage } = form;

        api.post('customer/hint', fieldValues)
            .then(response => {
                if (api.success(response, form)) {
                    logActionSuccess();
                    props.setFormData({ ...props.formData, ...fieldValues });
                    const verification = response.data.response.verification;
                    props.setVerifyData(verification);
                    if (verification.email === '' && verification.phoneNumber === '' && verification.phoneNumber2 === '' && verification.mobileNumber === '') {
                        props.setFormStage('alternative');
                    } else {
                        props.setFormStage('verify');
                    }
                } else {
                    if (response.data.errors.registrationHintNoContactDetails) {
                        setErrorMessage('We don\'t appear to have a phone number or email address registered on your account for us to validate. Please call us on 0800 574 574 to update your details.');
                    } else {
                        setErrorMessage('Sorry, we could not validate your details, please check and try again');
                    }
                }
            }).catch(error => {
                api.error(error, form, 'Sorry, we could not validate your details, please check and try again');
                setInvalidFields({accountNumber: '', postcode: ''});
        });
    };
    return (
        <Form formFields={ formFields }
              visible={ props.visible }
              className="register"
              eventTitle="Register step 1"
              validation={ props.addAccount ? validation : null }
              valid={ validHandler }
              hasChanged={ (props.formData.accountNumber !== null || process.env.NODE_ENV === 'development') }
              submitAlign="right"
              submitOutline={ props.addAccount }
              submitColour={ props.addAccount ? 'blue' : 'purple' }
              submitName="Next step">
            { props.addAccount && content.html('addAccount.intro', '<p>Add your account online today.</p>') }
            { !props.addAccount && content.html('register.intro', '<p>Activate your account online today.</p>') }
            <FieldSet fields={ ['accountNumber'] }/>
            <InfoBox ref={ accountNumberInfoBox } className="hidden">
                { content.html('register.infobox', '<p>Your 7 digit account number can be found on your paper bill and other documents</p>') }
                <img src={ accountNumberImage } className="account-number" alt="Where to find your account number" />
            </InfoBox>
            <FieldSet fields={ ['postcode'] }/>
        </Form>
    );
};

export default RegisterStage1;