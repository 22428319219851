import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';
import Table from 'components/table/Table';
import { api, axios } from 'helper/api/client';
import MockDataElement from 'components/ui/MockDataElement';
import NotFound from 'components/view/NotFound';
import printHandler from 'helper/print';
import format from 'helper/format';
import Button from 'components/ui/Button';
import permission from 'helper/permission';
import FaqsBox from 'components/partial/FaqsBox';

const OrderItem = (props) => {
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    useEffect(() => {
        let source = axios.CancelToken.source();
        api.get('orders/item/' + id)
            .then(response => {
                setOrder(response.data.response);
                api.setCache(response);
            }).catch(error => {
            setOrder(false);
            api.error(error);
        });
        // Will cancel AJAX on unmount
        return () => source.cancel();
    }, [id]);
    const getAddress = () => {
        let addressLine = '';
        if (order && order.deliveryAddress) {
            let address = '';
            for (const addressField of ['address1', 'address2', 'town', 'county', 'postcode']) {
                addressLine = String(order.deliveryAddress[addressField]).trim();
                if (addressLine.length) {
                    address += addressLine + ', ';
                }
            }
            return address.slice(0, -2);
        }
        return null;
    }
    const theadConfig = {
        product: { className: '', content: 'Product' },
        productCode: { className: 'label-left', content: 'Code' },
        amount: { className: 'label-left', content: 'Amount' },
        returns: { className: 'label-left quantity', content: 'Returns' },
        unitPrice: { className: 'amount', content: 'Unit price' },
        //vatRate: { content: 'VAT rate' },
        vat: { className: 'amount', content: 'VAT' },
        total: { className: 'amount', content: 'Total' },
    };
    if (permission.check('isCylinder')) {
        theadConfig.amount.content = 'Quantity';
        theadConfig.amount.className += ' quantity';
    } else {
        delete theadConfig.returns;
    }
    let tableData = [];
    const getProduct = (orderLine) => {
        return (
            <>
                { permission.check('isCylinder') && <div className="table-image-wrapper"><span className="table-image-wrapper__image">{ orderLine.productImage && <img src={ orderLine.productImage } alt={ orderLine.product } /> }</span>{ orderLine.product }</div> }
                { !permission.check('isCylinder') && orderLine.product }
            </>
        )
    }

    if (order === false) {
        return (
            <>
                <RouteHeader route={ props.route }/>
                <NotFound/>
            </>
        );
    } else {
        if (order) {
            tableData = order.lines.map((orderLine) => {
                return {
                    product: getProduct(orderLine),
                    productCode: orderLine.productCode,
                    amount: orderLine.amount + (permission.check('isCylinder') ? '' : ' ' + orderLine.amountType),
                    returns: orderLine.returns,
                    unitPrice: permission.check('isCylinder') ? format.currency(orderLine.unitPrice) : (format.number(orderLine.unitPrice, 2) + 'p'),
                    //vatRate: format.percentage(orderLine.vatRate),
                    vat: format.currency(orderLine.vat),
                    total: format.currency(orderLine.total),
                }
            });
        }
        return (
            <>
                <RouteHeader route={ props.route }/>
                <p><Button simple to="/orders/previous" noPadding>Back to previous orders</Button></p>
                <Box padding="large">
                    <div className="box__header">
                        <h3>Reference { order ? order.reference : '' }</h3>
                    </div>
                    <div className="box__info">
                        <div className="box__info-item"><strong>Delivery { (order && (order.deliveryAddress && order.deliveryAddress.address1 ? 'address' : 'postcode')) }</strong> { (order ? getAddress() : <MockDataElement />) }</div>
                        <div className="box__info-item"><strong>Status</strong> <MockDataElement data={ order } item="status"/></div>
                        <div className="box__info-item"><strong>Date</strong> <MockDataElement data={ order } item="date"/></div>
                    </div>
                    { order && order.deliveryDate && <p><strong>Delivery date</strong> { order.deliveryDate }</p> }
                    <Table
                        extraClass="order-item"
                        boxSize="large"
                        head={ theadConfig }
                        body={ tableData }
                        loading={ !order }
                    />
                    <Button simple={ true } onClick={ printHandler } icon="Print">Print</Button>
                    {/* Proof of order awaiting API */}
                </Box>
                <FaqsBox settingName="orderPage" defaultFaqs={ [77003, 76999, 77022] } />
            </>
        )
    }
};

export default OrderItem;