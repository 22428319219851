import history from 'helper/history';

const queryString = require('query-string');

export const getFilter = (defaultFilter) => {
    const params = queryString.parse(window.location.search);
    if (params.page) {
        params.page = parseInt(params.page);
    }
    return { ...defaultFilter, ...params };
}
export const getFilterNoResultsString = (filter) => {
    let hasFilter = false;
    if (typeof filter === 'object') {
        Object.entries(filter).forEach(([key, value]) => {
            if (value && !['page', 'XDEBUG_SESSION_START', 'sortBy'].includes(key) && value.length > 0) {
                hasFilter = true;
            }
        });
    }
    return (hasFilter ? 'for your filter' : 'currently');
}
export const getFilterDefault = (parameter, defaultValue) => {
    if (window.location.search.length) {
        const params = queryString.parse(window.location.search);
        if (params[parameter]) {
            return params[parameter];
        }
    }
    return defaultValue;
}
export const setHistoryState = (params) => {
    params = Object.entries(params).reduce((a, [k, v]) => (v === '' ? a : (a[k] = v, a)), {})
    if (params.page && params.page === 1) {
        delete params.page;
    }
    delete params.XDEBUG_SESSION_START;
    history.push({
        pathname: window.location.pathname,
        search: queryString.stringify(params)
    });
}