//import React, {useState} from 'react';
//import { api } from 'helper/api/client';

import Box from 'components/ui/Box';

const Template = (props) => {
    return (
        <>
            <Box>
                <p>TEMPLATE, please set up a relevant component</p>
            </Box>
        </>
    );
};

export default Template;