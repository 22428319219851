const getPasswordValdation = (fieldLabel, fieldValue, minLength = 12, email = null) => {
    if (email) {
        email = email.split('@')[0].toLowerCase();
    }
    let response = { valid: true, message: '', colour: '' }
    fieldValue = String(fieldValue);
    if (fieldValue.length < minLength) {
        response.valid = false;
        response.colour = 'danger';
        response.message = fieldLabel + ' must be at least ' + minLength + ' characters';
    } else if (!fieldValue.match(/[A-z]/)) {
        response.valid = false;
        response.colour = 'warning';
        response.message = fieldLabel + ' must contain least one letter a-z';

    } else if (!fieldValue.match(/[A-Z]/)) {
        response.valid = false;
        response.colour = 'warning';
        response.message = fieldLabel + ' must contain at least one capital letter A-Z';

    } else if (!fieldValue.match(/\d/)) {
        response.valid = false;
        response.colour = 'warning';
        response.message = fieldLabel + ' must contain at least one number';

    } else if (!fieldValue.match(/[@$!%*#?&£-]/)) {
        response.valid = false;
        response.colour = 'warning';
        response.message = fieldLabel + ' must contain at least one symbol (@$!%*#?&£-)';
    } else if (email && fieldValue.includes(email)) {
        response.valid = false;
        response.colour = 'warning';
        response.message = fieldLabel + ' cannot contain the first part of your email address';
    }
    return response;
}

export default getPasswordValdation;