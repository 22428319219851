import FlogasForm from 'components/partial/FlogasForm';

const FormGeneralEnquiry = (props) => {
    const formFields = {
        subject: { label: 'Subject', required: true, type: 'Text'  },
        comments: { label: 'Comments', required: true, type: 'Textarea'  },
    };
    return (
        <FlogasForm formFields={ formFields } form="general-enquiry" eventTitle="General enquiry" title="General enquiry" />
    );
};

export default FormGeneralEnquiry;