import Td from 'components/table/Td';

const TableBody = (props) => {
    return (
        <tbody>
        { Object.entries(props.body).map(([index, data]) => {
            return (
                <tr key={ index } onClick={ (data._rowAction ? data._rowAction : null) } data-id={ (data._rowId ? data._rowId : null) } className={ (data._rowClass ? data._rowClass : null) }>
                    { Object.entries(props.head).map(([headIndex, heading]) => {
                        return <Td key={ headIndex } title={ heading.content } className={ heading.className }>{ data[headIndex] }</Td>
                    }) }
                </tr>
            )
        }) }
        </tbody>
    );
};

export default TableBody;