import { getLastGaugeReadingDate } from 'helper/overview';
import moment from 'moment';
import content from 'helper/content';
import React from 'react';
import setting from 'helper/setting';

const OverviewEstimatedRemaining = ({ data }) => {
    const dateFormat = 'DD/MM/YYYY';
    const lastGaugeReadingDate = getLastGaugeReadingDate(data, true);
    const readingIsToday = moment(lastGaugeReadingDate, dateFormat).isSame(new Date(), 'day');
    const replacements = { remainingPercentage: data.remainingPercentage, date: getLastGaugeReadingDate(data, true)};
    return (
        <>
            <h3 className="overview-panel-small__title">
                { content.text('overview.estimatedReadingTitle', 'Your last tank gauge reading was {remainingPercentage}% on {date}.', replacements) }
            </h3>
            <div className="overview-panel-small__body">
                <p className="overview-panel-small__body-large">
                    { !readingIsToday && content.text('overview.estimatedReadingRemember', 'Please remember that we advise you to order at {bulkWarningThreshold}% to avoid running out.', { bulkWarningThreshold: setting.get('overview.bulkWarningThreshold', 40) }) }
                </p>
            </div>
        </>
    )
}
export default OverviewEstimatedRemaining;