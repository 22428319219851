import React, { useContext } from 'react';
import Form from 'components/form/Form';
import { getRecordFields } from 'helper/form/fields';
import { api } from 'helper/api/client';
import AccountsContext from 'context/accounts';
import FieldSet from 'components/form/FieldSet';
import InfoBox from 'components/ui/InfoBox';
import content from 'helper/content';
import global from 'helper/global';

const AccountNameEdit = (props) => {
    const { accounts, setAccounts } = useContext(AccountsContext);
    const account = accounts.find(account => account.id === parseInt(props.id));
    const formFields = getRecordFields({ alias: { label: 'Nickname', showLabel: false, type: 'Text', minLength: 3, maxLength: 50 } }, account);

    const validHandler = (form) => {
        const { fieldValues, setSuccessMessage, logActionSuccess } = form;
        api.post('customer/accounts/update', { id: props.id, ...fieldValues })
            .then(response => {
                if (api.success(response, form)) {
                    setSuccessMessage('Account name updated successfully');
                    logActionSuccess();
                    global.formSuccess = 'accountNameEdit';
                    global.successMessage = 'Account name updated successfully';
                    setAccounts(accounts.map(account => {
                        if (account.id === props.id) {
                            account.alias = fieldValues.alias;
                        }
                        return account;
                    }));
                    props.dispatchModal({ type: 'closeIn', time: 5000 });
                }
            }).catch(error => {
            api.error(error, form, 'Failed to update account name');
        });
    };

    return (
        <>
            <Form modal={ true } formFields={ formFields } id={ props.id } dispatchModal={ props.dispatchModal }
                  eventTitle="Account name update"
                  className="account-name"
                  globalIdentifier="accountNameEdit"
                  valid={ validHandler }
                  submitName="Update">
                <div className="form-info-box-row">
                    <div className="form-info-box-row__fields">
                        <FieldSet fields={ ['alias'] }/>
                        <InfoBox className="text-small account-name-first">
                            { content.html('accountName.info', '<p>You can give your account a nickname, which will be displayed in the account dropdown and can give an easy reference if switching between multiple accounts. You will still need your account number if you contact us.</p>') }
                        </InfoBox>
                        <input type="submit" />
                    </div>
                    <InfoBox className="text-small account-name-second">
                        { content.html('accountName.info', '<p>You can give your account a nickname, which will be displayed in the account dropdown and can give an easy reference if switching between multiple accounts. You will still need your account number if you contact us.</p>') }
                    </InfoBox>
                </div>
            </Form>
        </>
    );
};

export default AccountNameEdit;