import React, { useContext } from 'react';
import Form from 'components/form/Form';
import UserContext from 'context/user';

import { api } from 'helper/api/client';
import log from 'helper/log';
import { getRecordFields } from 'helper/form/fields';
import global from 'helper/global';

const AddressAddEdit = (props) => {
    const { user, setUser } = useContext(UserContext);
    const addressFormFields = {
        address1: { label: 'Address line 1', required: true, type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 30 },
        address2: { label: 'Address line 2', type: 'Text', validator: 'CodasString', maxLength: 30 },
        town: { label: 'Town/city', required: true, type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 30 },
        county: { label: 'County', required: false,  type: 'Text', validator: 'CodasString', minLength: 2, maxLength: 30 },
        postcode: { label: 'Postcode', required: true, type: 'Text', validator: 'Postcode', maxLength: 30 },
    }
    if (props.type === 'invoice') {
        addressFormFields.address1.label = 'Invoice to Name';
        addressFormFields.address2.label = 'Address';
        addressFormFields.address2.required = true;
    }
    const formFields = getRecordFields(addressFormFields, props.address);
    const typeTitle = (props.type === 'invoice' ? 'Invoice' : 'Billing');
    const globalIdentifier = (props.type === 'invoice' ? 'invoiceUpdated' : 'billingUpdated');
    const submitName = 'Update address';
    const apiUrl = props.type + '-address/update';
    const validHandler = (form) => {
        const { fieldValues, logActionSuccess } = form;
        api.patch(apiUrl, { ...fieldValues, type: props.type })
            .then(response => {
                if (api.success(response, form)) {
                    log.action('Address management', 'Updated ' + props.type + ' address', 'address_management');

                    logActionSuccess();

                    global.formSuccess = globalIdentifier;
                    global.successMessage = typeTitle + ' updated successfully';
                    if (props.type === 'invoice') {
                        setUser({ ...user, invoiceAddress: fieldValues });
                    } else {
                        fieldValues.postCode = fieldValues.postcode;
                        delete fieldValues.postcode;
                        setUser({ ...user, contact: { ...user.contact, ...fieldValues } });
                    }
                }
            }).catch(error => {
            api.error(error, form, 'Failed to update address');
        });
    };
    return (
        <>
            <Form modal={ true } formFields={ formFields } id={ 0 }
                  eventTitle={ typeTitle + ' address edit' }
                  valid={ validHandler }
                  globalIdentifier={ globalIdentifier }
                  submitName={ submitName }/>
        </>
    );
};

export default AddressAddEdit;