const fieldValidator = (field, fieldValue) => {
    const validCharacters = /^(?=.*\d)[\d ]+$/;
    let response = { valid: true, validationMessage: '' }
    if (fieldValue.substr(0, 1) === '+') {
        fieldValue = fieldValue.substr(1);
    }
    if (!fieldValue.length) {
        response.valid = false;
        response.validationMessage = field.label + ' is too short, please enter a full telephone number';
    } else if (!validCharacters.test(fieldValue)) {
        response.valid = false;
        response.validationMessage = field.label + ' must contain numbers only';
    } else {
        let numbersOnly = fieldValue.replace(/\D/g, '');
        if (numbersOnly.length < 9) {
            response.valid = false;
            response.validationMessage = field.label + ' is too short, please enter a full telephone number';
        } else if (numbersOnly.length > 13) {
            response.valid = false;
            response.validationMessage = field.label + ' is too long, please enter a single telephone number';
        }
    }
    return response;
}

export default fieldValidator;