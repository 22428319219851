const getFieldValidator = (name) => {
    return require('helper/form/validation/' + name).default;
}
const getValidationFields = fields => {
    return fields.reduce((map, obj) => {
        map[obj.name] = {
            valid: true,
            validationMessage: ''
        };
        return map;
    }, {});
};
const getInitialValidFields = (formFields) => {
    let validFields = {};
    for (const fieldName of Object.keys(formFields)) {
        validFields[fieldName] = {
            valid: true,
            validationMessage: ''
        };
    }
    return validFields;
}
const getValidFields = (formFields, fieldValues, fieldVisibility) => {
    let validFields = {},
        valid = true,
        validationMessage = '';
    for (const [fieldName, field] of Object.entries(formFields)) {
        const fieldValue = (fieldValues[fieldName] ? fieldValues[fieldName].trim() : '');
        if (fieldVisibility[fieldName] && (field.required || fieldValue.length)) {
            if (!field.validator) {
                field.validator = field.type;
            }
            const customValidator = getFieldValidator(field.validator);
            ({ valid, validationMessage } = customValidator(field, fieldValue));
        } else {
            valid = true;
            validationMessage = '';
        }
        validFields[fieldName] = {
            valid: valid,
            validationMessage: validationMessage
        };
    }
    return validFields;
};
const isValid = (formFields) => {
    for (const field of Object.values(formFields)) {
        if (!field.valid) {
            return false;
        }
    }
    return true;
};

export { getValidationFields, getInitialValidFields, getValidFields, isValid };