import React, { useContext } from 'react';
import UserContext from 'context/user';
import { getBalance } from 'components/ui/Balance';
import Button from 'components/ui/Button';
import { Link } from 'react-router-dom';

const OverviewAccountBalance = ({ data }) => {
    const { user } = useContext(UserContext);
    const getSmallText = () => {
        if (user.balance > 0) {
            return <Link to="/help-support/faqs?faq=my-account-is-in-credit-how-do-i-get-the-money-back">Why is my account in credit?</Link>
        } else if (user.balance === 0) {
            return <>No payment due</>
        } else {
            if (data.directDebit && data.directDebit.dayOfMonth !== '0th') {
                return <>Payment due by { data.directDebit.paymentDue }</>
            } else if (data.paymentDue) {
                return <>Payment due by { data.paymentDue }</>
            }
            return null;
        }
    }
    const getMonthlyPayment = () => {
        if (data.directDebit.monthlyPayment === 0) {
            return <>variable</>;
        }
        return <>£{ data.directDebit.monthlyPayment }</>
    }
    const getDayOfMonth = () => {
        if (data.directDebit.dayOfMonth !== '0th') {
            return <> on the { data.directDebit.dayOfMonth } of each month</>;
        }
        return null;
    }
    const getFooter = () => {
        if (data.directDebit) {
            return <p>Your monthly payments are <strong>{ getMonthlyPayment() }</strong>{ getDayOfMonth() }</p>
        } else if (user.balance < 0) {
            return <Button to="/payments/make-payment" simple>Make a payment</Button>
        }
        return null;
    }
    return (
        <>
            <h3 className="overview-panel-small__title">Account balance</h3>
            <div className="overview-panel-small__body">
                <h2 className="overview-panel-small__body-primary">{ getBalance(user.balance) }</h2>
                <p className="overview-panel-small__body-secondary">{ getSmallText() }</p>
            </div>
            <div className="overview-panel-small__footer">
                { getFooter() }
            </div>
        </>
    );
}

export default OverviewAccountBalance;