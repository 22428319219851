const fieldValidator = (field, fieldValue) => {
    let response = { valid: true, validationMessage: '' }
    if (!fieldValue.length) {
        fieldValue = 0;
    }
    fieldValue = parseInt(fieldValue);
    const validCharacters = /[0-9]/;
    if (!validCharacters.test(fieldValue)) {
        response.valid = false;
        response.validationMessage = field.label + ' must contain numbers only';
    } else if (fieldValue < 1000000) {
        response.valid = false;
        response.validationMessage = field.label + ' is not long enough';
    } else if (fieldValue > 9999999) {
        response.valid = false;
        response.validationMessage = field.label + ' is too long';
    }
    if (typeof field.current !== 'undefined' && String(fieldValue) === field.current) {
        response.valid = false;
        response.validationMessage = field.label + ' cannot be the current account number';
    }
    return response;
}

export default fieldValidator;