import React, { useState, useEffect } from 'react';
import { api } from 'helper/api/client';
import RouteHeader from 'components/partial/RouteHeader';
import Box from 'components/ui/Box';
import Button from 'components/ui/Button';
import InfoBox from 'components/ui/InfoBox';
import MockParagraph from 'components/ui/MockParagraph';
import MockParagraphs from 'components/ui/MockParagraphs';
import 'scss/component/supply-agreement/supply-agreement.scss';
import permission from 'helper/permission';

const SupplyAgreement = (props) => {
    const [loading, setLoading] = useState(true);
    const [agreementData, setAgreementData] = useState({
        contractStartDate: undefined,
        contractEndDate: undefined,
        hasExpired: undefined,
        price: undefined,
        salesUnit: undefined,
        standingCharge: undefined
    });
    useEffect(() => {
        api.get('bulk/supply-agreement').then(response => {
            setAgreementData(response.data.response);
            setLoading(false);
            api.setCache(response, 1);
        }).catch(error => {
            api.error(error);
        });
    });
    return (
        <>
            <RouteHeader route={ props.route } />
            <Box classname="supply-agreement" padding="large">
                <div className="supply-agreement__inner">
                    <div className="supply-agreement__main">
                        <h3>Your supply agreement</h3>
                        { loading && <MockParagraphs rows={ 3 } items={ 5 }/> }
                        { !loading && <>
                            <div className="supply-agreement__dates">
                                <div>Start date: { agreementData.contractStartDate }</div>
                                <div>End date: { agreementData.contractEndDate }</div>
                            </div>
                            <div className="supply-agreement__prices">
                                <div className="supply-agreement__price">
                                    <h4>Your price:</h4>
                                    <div className="supply-agreement__price__value">{ agreementData.price }</div>
                                    <div className="supply-agreement__price__unit">pence / { agreementData.salesUnit }</div>
                                </div>
                                {agreementData.standingCharge > 0 &&
                                    <div className="supply-agreement__price">
                                        <h4>Your Standing charge:</h4>
                                        <div
                                            className="supply-agreement__price__value">{agreementData.standingCharge}</div>
                                        <div className="supply-agreement__price__unit">pence / day</div>
                                    </div>}
                            </div>
                        </>}
                    </div>
                    <div className="supply-agreement__side">
                        { loading && <MockParagraph items={ 20 }/> }
                        { !loading && !permission.check('isContractDueToExpire') && <div className="supply-agreement__side__help">
                            <h4>Help and support</h4>
                            <p>Looking for help with your agreement or your account? <br />We have the answers.</p>
                            <Button to="help-support" simple>Get help and support</Button>
                        </div> }
                        { !loading && permission.check('isContractDueToExpire') && <InfoBox>
                            <h4>Contact { agreementData.hasExpired ? 'ended:' : 'ending:' } { agreementData.contractEndDate ?? 'N/A' }</h4>
                            <p>Renew now to make sure you're getting the best deal.</p>
                            <div><Button href="https://www.flogas.co.uk/home/renew" simple>Discuss renewal options</Button></div>
                        </InfoBox> }
                    </div>
                </div>
            </Box>
        </>
    );
};

export default SupplyAgreement;